import Tile from "../../../widgets/Tile";
import {useContext, useRef} from "react";
import {TcmsContext} from "../../../contexts/TcmsContext";
import WidgetPopup from "../../../widgets/WidgetPopup";

const Tiles = () => {
  const context = useContext(TcmsContext)
  const ref = useRef()
  const onTileClicked = (slug) => {
    ref.current.open(slug)
  }

  return <>
    <WidgetPopup ref={ref}/>
    <div className="p-grid">
      {context.meta.widgets.map(el =>
        <div key={el.id} className="p-col p-xl-4">
          <Tile slug={el.name} onTileClicked={onTileClicked}/>
        </div>
      )}
    </div>
  </>
}
export default Tiles
