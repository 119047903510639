import {useEffect, useState} from "react";
import axios from "axios";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import Tcms from "../../tcms/Tcms";
import TcmsSpan from "../../tcms/Tags/TcmsSpan";

const BE = process.env.REACT_APP_MTG_BACKEND

const getUser = () =>{
  return  axios.get(BE + '/api/webshop/voucher')
}

const User = ({userData, onUserChanged}) => {

  const [user, setUser] = useState()

  const [errors, setErrors] = useState({})
  useEffect(() => {
    setUser(userData)
  }, [userData])


  const save = () => {
    const data = {
      firstname: user.firstname,
      lastname: user.lastname,
      private_email: user.private_email
    }
    axios.post(BE + '/api/webshop/voucher', data).then(res => {
      setErrors({})
      onUserChanged(res.data)
    }).catch(err => {
      setErrors(err.response.data.errors)
    })
  }

  // wait for data
  if (!user) return null

  return <>

    <TcmsSpan>Vul je naam en e-mailadres in en plaats je bericht.</TcmsSpan>

    <div className="p-formgroup-inline">
      <div className="p-field">
        <InputText style={{width: '193px'}} placeholder="Voornaam" value={user.firstname}
                   onChange={(e) => {
                     setUser({...user, firstname: e.target.value})
                   }}
        />
        {errors.firstname && <><br/><small style={{color:'red'}}><Tcms>{errors.firstname}</Tcms></small></>}
      </div>
      <div className="p-field">
        <InputText style={{width: '193px'}} placeholder="Achternaam" value={user.lastname}
                   onChange={(e) => setUser({...user, lastname: e.target.value})}
        />
        {errors.lastname && <><br/><small style={{color:'red'}}><Tcms>{errors.lastname}</Tcms></small></>}
      </div>

    </div>
    <div className="p-formgroup-inline">
      <div className="p-field">
        <InputText style={{width: '400px'}} placeholder="Email" value={user.private_email}
                   onChange={(e) => setUser({...user, private_email: e.target.value})}
        />
        {errors.private_email && <><br/><small style={{color:'red'}}><Tcms>{errors.private_email}</Tcms></small></>}
      </div>
    </div>
    <Button type="button" label="Submit" onClick={() => {
      save()
    }}/>
  </>

}
export  {User, getUser}
