import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Player from '@vimeo/player';
import "./VideoPlaceholder.scss";

const VideoPlaceholder = ({ setIsMusicMuted }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentGame = searchParams.get('game');

  const startGameUrl = `${location.pathname}?game=${currentGame}&start=true`;
  const videoUrl = 'https://player.vimeo.com/video/76979871';

  const [isVideoEnded, setIsVideoEnded] = useState(false);
  const [isSkipped, setIsSkipped] = useState(false);

  useEffect(() => {
    const iframe = document.querySelector('iframe');
    const player = new Player(iframe);

    player.on('play', () => {
      if (typeof setIsMusicMuted === 'function') {
        setIsMusicMuted(true);
      }
    });

    player.on('ended', () => {
      setIsVideoEnded(true);
      if (typeof setIsMusicMuted === 'function') {
        setIsMusicMuted(false);
      }
    });

    return () => {
      player.off('play');
      player.off('ended');
      if (typeof setIsMusicMuted === 'function') {
        setIsMusicMuted(false);
      }
    };
  }, [setIsMusicMuted]);

  const handleSkipVideo = () => {
    setIsSkipped(true);
    setIsVideoEnded(true);
    if (typeof setIsMusicMuted === 'function') {
      setIsMusicMuted(false);
    }
  };

  return (
    <>
      {(isVideoEnded || isSkipped) && (
        <a href={startGameUrl} className={`start-game-button ${isVideoEnded || isSkipped ? 'highlight' : ''}`}>
          Start het spel
        </a>
      )}
      <div className={`video-placeholder ${isVideoEnded ? 'blurred' : ''}`}>
        <div className="video-container">
          <iframe
            src={videoUrl}
            width="100%"
            height="100%"
            frameBorder="0"
            allow="autoplay; fullscreen; encrypted-media"
            allowFullScreen
            title="Game Introduction Video"
          ></iframe>
          {(isVideoEnded || isSkipped) && <div className="video-overlay"></div>}
        </div>
        {!isVideoEnded && !isSkipped && (
          <button className="skip-video-button" onClick={handleSkipVideo}>
            Overslaan
          </button>
        )}
      </div>
    </>
  );
};

export default VideoPlaceholder;