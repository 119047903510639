import React, { useState, useEffect } from "react"
import { useHistory } from 'react-router-dom';
import confettiGif from "../assets/confetti.gif";
import "./GameResult.scss";

const GameResult = ({ score, totalLetters, revealedWord, isFifthGame = false, isFirstGame = false, isSecondGame = false, isThirdGame = false, isFourthGame = false, isSixthGame = false, onNextGame }) => {

  const [showConfetti, setShowConfetti] = useState(score > 5);
  const [fadeOut, setFadeOut] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const fadeTimer = setTimeout(() => {
      setFadeOut(true);
    }, 4000);

    const hideTimer = setTimeout(() => {
      setShowConfetti(false);
    }, 5000);

    return () => {
      clearTimeout(fadeTimer);
      clearTimeout(hideTimer);
    };
  }, []);

  const goToFinalResult = () => {
    history.push('/final-result');
  };

  function getResultText() {
    if (score < 3) {
      return (
        <div className="result-text">
          <p>Jammer, je hebt slechts {score} punten verzameld. Blijf oefenen en probeer het opnieuw voor een betere score!</p>
        </div>
      );
    } else if (score >= 3 && score <= 6) {
      return (
        <div className="result-text">
          <p>Goed geprobeerd! Je hebt {score} punten verzameld. Een prima resultaat, maar er is nog ruimte voor verbetering.</p>
        </div>
      );
    }
    if (isFirstGame) {
      return (
        <div className="result-text-container">
          <p>Goed gedaan! <span className="emoji"></span></p>
          <p>Na deze heerlijke pauze op de <strong>eerste rustplaats</strong> heb je <strong className="highlight">{score} punten</strong> verzameld in je rugzak. <span className="emoji">🏆</span></p>
          <p>Wat een <strong>fantastisch geheugen</strong> heb je laten zien tijdens deze eerste opdracht van je wandeltocht! <span className="emoji">🧠</span></p>
          <p>Vond je de koptelefoon fijn? <span className="emoji">🎧</span></p>
          <p>Ontdek de <a href="/webshop" className="link">duurzame House of Marley koptelefoon</a> en nog veel meer mooie producten in onze <strong>webshop</strong>!</p>
          <p>Je bent goed op weg! <span className="emoji"></span> Bereid je voor op de volgende stap van de wandeling. Pak je <strong>rugzak</strong> weer op en zet koers naar het volgende spel. <span className="emoji"></span> Veel succes!</p>
        </div>
      );
    }

    if (isSecondGame) {
      return (
        <div className="result-text">
          <p>Goed gedaan!</p>
          <p>
            Na een spannende ronde op deze rustplaats heb je <strong>{score} punten</strong> verzameld in je rugzak, begint de tas al zwaar te worden? Je hebt in ieder geval uitstekende kennis over vlaggen van landen en de Olympische sporten!
          </p>
          <p>
            Na zo'n ronde zul je wel dorst hebben, gelukkig kun je in de <a href="/webshop" className="link">webshop</a> kiezen voor de duurzame gerecyclede plastic fles, perfect om je hydratatie op peil te houden terwijl je verder wandelt.
          </p>
          <p>
            Je hebt weer een belangrijke stap gezet in je wandeling, dus neem even de tijd om uit te rusten en je voor te bereiden op de volgende uitdaging. Pak daarna je rugzak weer op en zet koers naar het volgende spel. Veel succes met de volgende etappe van je reis!
          </p>
        </div>
      );
    }

    if (isFifthGame) {
      return (
        <div className="result-text">
          <h2>Goed gedaan! 🌞</h2>
          <p>
            Je hebt de kennisuitdaging met veel enthousiasme voltooid en <strong>{score}</strong> punten verzameld in je rugzak 🎒. Wat een geweldige prestatie zeg!
          </p>
          <p>
            Terwijl je de rustplaats verlaat, zie je de zon nog stralen boven je en herinner je je de handige Xtorm 20W solarcharger die je hebt gebruikt. Gelukkig kun je deze powerbank nu aanschaffen in de webshop!
          </p>
          <p>
            Met deze krachtige charger ben je niet alleen voorbereid op het opladen van je apparaten, maar draag je ook bij aan een duurzamere toekomst, een Win-Win situatie.
          </p>
          <p>
            Neem even de tijd om te ontspannen en te genieten van de omgeving voordat je verdergaat. Pak je rugzak weer op en maak je klaar voor de volgende spannende etappe van je avontuur. Veel succes!
          </p>
        </div>
      );
    }
    if (isSixthGame) {
      if (score === totalLetters) {
        return (
          <div>
            <p>Uitstekend gedaan! Je hebt de kruiswoordpuzzel met succes opgelost en {score} punten verzameld in je rugzak! 🎒</p>
            <p>Wat een geweldige prestatie om zoveel kennis over inclusiviteit, diversiteit en gelijkheid te tonen.</p>
            <p>Terwijl je je Multipillow van gerecycled PET weer opbergt 🛋️, kun je met trots terugkijken op deze uitdaging.</p>
            <p>Maar je avontuur stopt hier niet! Bezoek de <a href="/webshop" className="link">webshop</a> om meer duurzame producten te ontdekken, zoals jouw Multipillow, en nog veel meer artikelen die bijdragen aan een duurzamere wereld ♻️.</p>
          </div>
        );
      } else if (score > 0 && score < totalLetters) {
        return (
          <div>
            <p>Goed geprobeerd! Je hebt {score} van de {totalLetters} letters correct ingevuld. Het geheime woord was "{revealedWord}".</p>
            <p>Hoewel je niet alle letters hebt geraden, heb je toch waardevolle kennis laten zien over inclusiviteit en gelijkheid. 🎓</p>
            <p>Bezoek de <a href="/webshop" className="link">webshop</a> en ontdek duurzame producten om je avontuur voort te zetten! 🌿</p>
          </div>
        );
      } else {
        return (
          <div>
            <p>Helaas, je hebt geen enkele letter correct geraden. Probeer het opnieuw om het geheime woord "{revealedWord}" te ontdekken en je kennis over inclusiviteit, diversiteit en gelijkheid verder te testen! 📚</p>
            <p>Vergeet niet een kijkje te nemen in de <a href="/webshop" className="link">webshop</a> voor duurzame producten die je kunnen helpen bij je volgende uitdaging! 🌍</p>
          </div>
        );
      }
    }

    if (isFourthGame) {
      return (
        <div className="result-text">
          <h2>Goed gedaan! 🐝</h2>
          <p>
            Je hebt de bijen-uitdaging met succes voltooid en <strong>{score}</strong> punten verzameld in je rugzak 🎒. Wat een geweldige prestatie om al die vragen over bijen, biodiversiteit en atletiek te beantwoorden!
          </p>
          <p>
            Nu de rust is weergekeerd, zie je hoe de bijen rustig terugkeren naar hun bijenhotel. Je kunt zelf ook zo'n prachtig bijenhotel aanschaffen in de <a href="/webshop" className="link">webshop</a> om een bijdrage te leveren aan deze belangrijke bestuivers.
          </p>
          <p>
            Neem even de tijd om uit te rusten en te genieten van de omgeving voordat je verdergaat. Pak je rugzak weer op en zet koers naar de volgende spannende etappe van je avontuur. Veel succes! 🐝
          </p>
        </div>
      );
    }

    if (isThirdGame) {
      return (
        <div className="result-text">
          <h2>Fantastisch gedaan! </h2>
          <p>
            Je hebt het anagrammenavontuur succesvol voltooid en <strong>{score}</strong> punten verzameld in je rugzak 🎒. Wat een geweldige prestatie om al die gehusselde woorden te ontcijferen!
          </p>
          <p>
            Terwijl je even op deze rustplaats uitrust, besef je dat deze Erlenmeyer eigenlijk wel heel mooi is. Gelukkig kun je deze gewoon bestellen in de webshop. Zo kan je ook thuis genieten van een prachtige groene omgeving.
          </p>
          <p>
            Bereid je nu voor op de volgende etappe van je avontuur. Pak je rugzak weer op en ga verder naar het volgende spel. Veel succes!
          </p>
        </div>
      );
    }
  }

  function getUnlockedText() {
    return `Je hebt ${score} van de ${totalLetters} letters ontgrendeld!`;
  }

  function getNextGameLabel() {
    if (isFirstGame) return "Start spel 2";
    if (isSecondGame) return "Start spel 3";
    if (isThirdGame) return "Start spel 4";
    if (isFourthGame) return "Start spel 5";
    if (isFifthGame) return "Start spel 6";
    return "Volgende spel";
  }

  return (
    <div className="game-result-container">
      {showConfetti && (
        <div className={`confetti-overlay ${fadeOut ? "fade-out" : ""}`}>
          <img src={confettiGif} alt="Confetti" className="confetti-gif" />
        </div>
      )}
      <div className="game-result-content">
        <h1 className="result-title">Je resultaat</h1>

        <div className="result-summary">
          <p className="result-text">{getResultText()}</p>

          {isSixthGame && (
            <div className="unlocked-letters">
              <span role="img" aria-label="lock-icon" className="lock-icon">🗝️</span>
              <p className="unlocked-text">{getUnlockedText()}</p>
            </div>
          )}
        </div>
        <button className="webshop-btn">Naar de webshop</button>
        {isSixthGame && (
          <button className="final-result-btn" onClick={goToFinalResult}>
            Eindresultaat
          </button>
        )}
        {!isSixthGame && (
          <button className="restart-game-btn" onClick={onNextGame}>
            {getNextGameLabel()}
          </button>
        )}
      </div>
    </div>
  );
};

export default GameResult;
