import Menu from '../../../components/webshop/menu/Menu'
import Favorites from '../../../components/webshop/Favorites'
import Background from '../../../components/webshop/background/Background';
import Footer from '../../../components/webshop/footer/Footer';
export default function Info(props) {


  return (
    <>
      <div className='page-wrapper'>
        <Menu />
        <Background />
        <div className="page-favorites">
          <div className="container__large content-page favorites-container">
            <Favorites />
          </div>
        </div>
      </div>
      <Footer />
    </>
  )

}



