import Menu from '../../../components/webshop/menu/Menu'
import Pdp from "../../../components/webshop/pdp/Pdp";
import Breadcrumbs from "../../../components/webshop/breadcrumbs/Breadcrumbs";
import TcmsSpan from "../../../tcms/Tags/TcmsSpan";
import {useHistory} from "react-router-dom";
import Background from '../../../components/webshop/background/Background';
import Footer from '../../../components/webshop/footer/Footer';
import { useContext } from 'react';
import { TcmsContext } from '../../../contexts/TcmsContext';
import { useState } from 'react';
import { useEffect } from 'react';
import slug from '../../../services/slug';

export default function Info({rootComponent}) {

  const product_id = parseInt(rootComponent.props.match.params.product_id)
  const [sortedProducts, setSortedProducts] = useState([]);
  const [currentProduct, setCurrentProduct] = useState(false);
  const [prevProduct, setPrevProduct] = useState(false);
  const [nextProduct, setNextProduct] = useState(false);
  const [clicked, setClicked] = useState(false);
  const context = useContext(TcmsContext)
  const history = useHistory()

  

  const productSort = (products, sort = 'custom') => {
    switch (sort) {
      case 'asc':
        products.sort((a, b) => a.price_in_eco - b.price_in_eco)
        break;
      case 'custom':
        products.sort((a, b) => {
          return a.display_name > b.display_name ? 1 : -1
        })
        break;
      case undefined:
      case 'desc':
        products.sort((a, b) => b.price_in_eco - a.price_in_eco)
        break;
      default:
    }

    if (sort == 'custom') {
      products.sort((a,b) => (a.local_order < b.local_order) ? 1 : (b.local_order < a.local_order ? -1 : 0))
    }

    // This originalIndex is there for the 'animation' part when clicking an element
    let originalIndex = 0
    products.forEach(e => e.originalIndex = originalIndex++)
    return [...products]

  }

  const findPrevious = () => {
    // let prevSortedProducts = sortedProducts;
    // prevSortedProducts.reverse();
    const prev = sortedProducts.reverse().find(p => p.originalIndex < currentProduct.originalIndex && p.product_type === currentProduct.product_type);
    return prev;
  }

  const findNext = () => {
    const next = sortedProducts.reverse().find(p => p.originalIndex > currentProduct.originalIndex && p.product_type === currentProduct.product_type);
    return next;
  }

  useEffect(() => {
    setSortedProducts(productSort(context.products.products));
    setCurrentProduct(context.products.products.find(subject => subject.id === product_id));
  }, [prevProduct,nextProduct]);

  useEffect(() => {
    setPrevProduct(findPrevious);
    setNextProduct(findNext);
  }, [prevProduct,nextProduct, currentProduct]);

  return (
    <>
      <Menu/>
      <div className='page-wrapper'>
        <Background/>
        <div className="page-pdp main-view">
          <div className="container__large breadcrumbs__container">
            <div className="breadcrumbs__top">
              <div className="br_back">
                <button className="breadcrumbs__back" onClick={() => {
                    history.goBack()
                  }}>
                  <span className="pi pi-chevron-left"></span>
                  <TcmsSpan>Terug</TcmsSpan>
                </button>
              </div>
              {/* <Breadcrumbs product_id={product_id}/> */}
              <div className='br_products'>
                {prevProduct &&
                  <button className="breadcrumbs__back" onClick={() => {
                      setCurrentProduct(prevProduct);
                      history.push(`/${context.webshopSlug}/product/${prevProduct.id}/${slug(prevProduct.display_name)}`)
                    }}>
                    <span className="pi pi-chevron-left"></span>
                    <TcmsSpan>Vorige geschenk</TcmsSpan>
                  </button>
                }

                {nextProduct &&
                  <button className="breadcrumbs__back" onClick={() => {
                      setCurrentProduct(nextProduct);
                      history.push(`/${context.webshopSlug}/product/${nextProduct.id}/${slug(nextProduct.display_name)}`)
                    }}>
                    <TcmsSpan>Volgende geschenk</TcmsSpan>
                    <span className="pi pi-chevron-right"></span>
                  </button>
                }
              </div>
            </div>
          </div>

          <div>
            <Pdp product_id={product_id}/>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  )

}



