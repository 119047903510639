const doubleSnailMailedVoucherCodes = [
    '5D487P',
    '65H5BP',
    'BE4PQT',
    'CLG8T6',
    'JJXM7B',
    'JNGJJP',
    'K8ADDS',
    'QGXN3A',
    'XKQ2MZ',
    'XY2P4N',
    'Y4ZEY9',
    'Y65MCV',
    'YJ2694',
    'Z39MAV'
]

export default doubleSnailMailedVoucherCodes
