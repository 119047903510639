import React, { useState, useEffect, useContext } from "react";
import GameIntro from '../game-intro/GameIntro';
import GameResult from '../game-result/GameResult';
import "./GameScreenFive.scss";
import VideoPlaceholder from "../video-placeholder/VideoPlaceholder";
import { useHistory } from 'react-router-dom';
import { canAccessGame, saveGameProgress, getGameAvailabilityById } from "../services/gameProgressService";
import { TcmsContext } from '../../../../contexts/TcmsContext';
import jwtDecode from 'jwt-decode';
import { toast } from 'react-hot-toast';
import InfoBox from '../game-infobox/InfoBox';
import gamesTransitionVideo from '../assets/games_transition.mp4';
import pingSound from '../../../../sounds/ping.mp3';
import buzzSound from '../../../../sounds/softbuzz.mp3';


const GameScreenFive = ({ startGame, setStartGame, setIsMusicMuted, setIsIntro }) => {
  const [userId, setUserId] = useState('');
  const { setJwtForGames } = useContext(TcmsContext);

  useEffect(() => {
    const storedJwtToken = sessionStorage.getItem('jwt_token');
    if (storedJwtToken) {
      setJwtForGames(storedJwtToken);
      const tokenData = jwtDecode(storedJwtToken);
      console.log('tokenData', tokenData);
      setUserId(tokenData?.usr?.uid);
    }
  }, [setJwtForGames]);

  const gameId = 5;

  const gameIntroText = {
    title: 'Welkom bij Spel 5: Wat weet je van...?',
    description: `Na een stevige wandeling kom je aan bij de vijfde rustplaats, waar je je Xtorm 20W solarcharger erbij pakt om je apparaten op te laden☀️.
      Hier wacht een snelle uitdaging: je krijgt tien onderwerpen en moet zo veel mogelijk bijpassende kernwoorden raden.
      Je hebt 45 seconden per onderwerp om zoveel mogelijk trefwoorden te raden.
      Net als Nyck de Vries op het circuit moet jij hier scherp en snel zijn om punten te verzamelen voor je rugzak🎒.
      Succes!🌿`,
    instruction: `Bekijk de video van een bekende Nederlander over de zon en beantwoord vervolgens drie vragen. Scoor voldoende punten om een speciaal item voor je rugzak te verdienen: de Xtorm 20W Fuel Series Solar Charger 10.000mAh!`,
    showMedals: false
  };

  const infoBoxContent = {
    title: "OVER SPEL 5",
    goal: "Zoveel mogelijk kernwoorden raden met betrekking tot het onderwerp binnen 45 seconde.",
    topics: "Zonne-energie",
    inspiration: "Kies de Xtorm 20W Solar Charge in de webshop!"
  };

  const videoUrl = 'https://player.vimeo.com/video/76979871';

  const [questions, setQuestions] = useState([
    {
      question: "Wat weet je van Tesla?",
      correctAnswers: [
        "Laadpunten",
        "Innovatie",
        "Autopilot",
        "Zelfrijdend",
        "Elon Musk",
        "Model S"
      ]
    },
    {
      question: "Wat weet je van de Formule 1?",
      correctAnswers: [
        "Pitstop",
        "Zandvoort",
        "Circuits",
        "Max Verstappen",
        "Teams"
      ]
    },
    {
      question: "Wat weet je van Monaco?",
      correctAnswers: [
        "Belastingvoordeel",
        "Frankrijk",
        "Luxe",
        "Jachthavens",
        "Dwergstaat"
      ]
    },
    {
      question: "Wat weet je van de Zon?",
      correctAnswers: [
        "Zonne-energie",
        "Warmte",
        "Zonlicht",
        "Ster",
        "Middelpunt"
      ]
    },
    {
      question: "Wat weet je over de Meeuw?",
      correctAnswers: [
        "Afval",
        "Strand",
        "Vogel",
        "Kolonie",
        "Schreeuw"
      ]
    },
    {
      question: "Wat weet je over Martin Garrix?",
      correctAnswers: [
        "DJ",
        "Nederland",
        "Animals",
        "EDM",
        "dance",
        "Wereldberoemd"
      ]
    },
    {
      question: "Wat weet je van de Quote500?",
      correctAnswers: [
        "Rijkste Nederlanders",
        "Jort Kelder",
        "Tijdschrift",
        "Ondernemerschap",
        "Charlene de Carvalho-Heineken"
      ]
    },
    {
      question: "Wat weet je van Bier?",
      correctAnswers: [
        "0.0",
        "Alcohol",
        "Mout",
        "Hop",
        "Brouwerij"
      ]
    }
  ]);

  const [inputValue, setInputValue] = useState('');
  const [allGuesses, setAllGuesses] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [timeLeftPerQuestion, setTimeLeftPerQuestion] = useState();
  const [gameFinished, setGameFinished] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [showCorrectAnswers, setShowCorrectAnswers] = useState(false);
  const [showAllAnswersGiven, setShowAllAnswersGiven] = useState(false);
  const [showInfoBox, setShowInfoBox] = useState(true);

  // Check if the user can access the game and if game is available to play
  useEffect(() => {
    const checkGameAccess = async () => {
      try {
        // Fetch game availability (is game globally enabled?)
        const availabilityData = await getGameAvailabilityById(gameId);
        const isGameEnabled = availabilityData.enabled;
        // if (!isGameEnabled) {
        //   alert('Game is disabled');
        // }

        // Fetch user progress (has the user completed this game?)
        const userProgressData = await canAccessGame(userId, gameId);
        // Determine if the game can be played
        // if (isGameEnabled && !isGameCompleted) {
        //   setGameEnabled(true);
        // } else {
        //   setGameEnabled(false);
        // }
      } catch (error) {
        console.error('Error checking game access:', error.message);
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      checkGameAccess();
    }
  }, [userId]);

  // Handle saving progress if the user tries to leave the page
  useEffect(() => {
    if (gameStarted) {
      const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
        toast('Je kunt het spel niet opnieuw spelen als je de pagina verlaat!', {
          position: 'bottom-center',
          duration: 5000,
          style: {
            background: '#facc15',
            color: '#000',
          },
        });
        const saveProgress = async () => {
          try {
            const correctGuessesCount = allGuesses
              .flat()
              .filter(guessObj => guessObj && guessObj.isCorrect === true)
              .length;
            const response = await saveGameProgress(userId, gameId, correctGuessesCount);
          } catch (error) {
            console.error('Error saving progress on exit:', error.message);
          }
        };
        saveProgress();
      };

      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, [gameStarted, allGuesses]);

  useEffect(() => {
    if (gameFinished) {
      const saveProgress = async () => {
        try {
          const correctGuessesCount = allGuesses.flat().filter(guessObj => guessObj && guessObj.isCorrect === true).length;
          const response = await saveGameProgress(userId, gameId, correctGuessesCount);
        } catch (error) {
          console.error('Error saving progress:', error.message);
        }
      };
      saveProgress();
    }
  }, [gameFinished]);


  const beginGame = () => {
    setGameStarted(true);
    setTimeLeftPerQuestion(45);
    setIsIntro(false);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = () => {
    if (inputValue.trim() && !gameFinished) {
      const updatedGuesses = [...allGuesses];
      const currentGuesses = updatedGuesses[currentQuestionIndex] || [];
      const updatedQuestions = [...questions];
      const currentQuestion = updatedQuestions[currentQuestionIndex];

      const SIMILARITY_THRESHOLD = 2;
      const isRepeated = currentGuesses.some(
        guessObj => guessObj.guess.toLowerCase() === inputValue.toLowerCase()
      );

      const matchedAnswer = currentQuestion.correctAnswers.find(answer => {
        const distance = calculateLevenshteinDistance(inputValue.toLowerCase(), answer.toLowerCase());
        return distance <= SIMILARITY_THRESHOLD;
      });

      const isCorrect = matchedAnswer && !isRepeated;

      if (isRepeated) {
        currentGuesses.unshift({
          guess: inputValue,
          isCorrect: false,
          correctAnswer: '',
          correctDisplayAnswer: matchedAnswer,
          isRepeated: true
        });
      } else if (isCorrect) {
        currentGuesses.unshift({
          guess: inputValue,
          isCorrect: true,
          correctAnswer: inputValue,
          correctDisplayAnswer: matchedAnswer,
          isRepeated: false
        });

        currentQuestion.correctAnswers = currentQuestion.correctAnswers.filter(
          answer => answer !== matchedAnswer
        );

        if (currentQuestion.correctAnswers.length === 0) {
          setInputValue('');
          setShowAllAnswersGiven(true);
          setTimeout(() => {
            setShowAllAnswersGiven(false);
            handleNextQuestion();
          }, 3000);
          return;
        }
        new Audio(pingSound).play();
      } else {
        currentGuesses.unshift({
          guess: inputValue,
          isCorrect: false,
          correctAnswer: '',
          correctDisplayAnswer: matchedAnswer,
          isRepeated: false
        });
        new Audio(buzzSound).play();
      }

      updatedGuesses[currentQuestionIndex] = currentGuesses;
      updatedQuestions[currentQuestionIndex] = currentQuestion;

      setAllGuesses(updatedGuesses);
      setQuestions(updatedQuestions);
      setInputValue('');
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex + 1 < questions.length) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setTimeLeftPerQuestion(45);
      setShowCorrectAnswers(false);
    } else {
      setGameFinished(true);
    }
  };

  const calculateLevenshteinDistance = (a, b) => {
    const matrix = Array.from({ length: a.length + 1 }, () => Array(b.length + 1).fill(0));

    for (let i = 0; i <= a.length; i++) matrix[i][0] = i;
    for (let j = 0; j <= b.length; j++) matrix[0][j] = j;

    for (let i = 1; i <= a.length; i++) {
      for (let j = 1; j <= b.length; j++) {
        const cost = a[i - 1] === b[j - 1] ? 0 : 1;
        matrix[i][j] = Math.min(
          matrix[i - 1][j] + 1,
          matrix[i][j - 1] + 1,
          matrix[i - 1][j - 1] + cost
        );
      }
    }

    return matrix[a.length][b.length];
  };

  const handleFinishGame = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setStartGame(false);
      history.push('/games?game=6');
    }, 7000);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }
  };

  const handleCloseInfoBox = () => {
    setShowInfoBox(false);
  };

  useEffect(() => {
    if (timeLeftPerQuestion > 0 && !gameFinished) {
      const timerId = setTimeout(() => setTimeLeftPerQuestion(timeLeftPerQuestion - 1), 1000);
      return () => clearTimeout(timerId);
    } else if (timeLeftPerQuestion === 0) {
      setShowAllAnswersGiven(false);
      setShowCorrectAnswers(true);
      const correctAnswersDisplayTimeout = setTimeout(() => {
        setShowCorrectAnswers(false);
        handleNextQuestion();
      }, 3000);
      return () => clearTimeout(correctAnswersDisplayTimeout);
    }
  }, [timeLeftPerQuestion, gameFinished]);

  if (!gameStarted && startGame) {
    return (
      <>
        <GameIntro {...gameIntroText} onStart={beginGame} setIsMusicMuted={setIsMusicMuted} />
        {showInfoBox && (
          <InfoBox
            {...infoBoxContent}
            onClose={handleCloseInfoBox}
          />
        )}
      </>
    );
  }

  if (loading) {
    return (
      <div className="game-loader">
        <video
          src={gamesTransitionVideo}
          autoPlay
          muted
          onEnded={() => setLoading(false)}
          className="transition-video"
        />
      </div>
    );
  }

  if (gameFinished) {
    const correctGuessesCount = allGuesses
      .flat()
      .filter(guessObj => guessObj && guessObj.isCorrect === true)
      .length;
    return (
      <GameResult
        score={correctGuessesCount}
        totalQuestions={questions.length * 4}
        onNextGame={handleFinishGame}
        isFifthGame={true}
      />
    );
  }

  return (
    <div className={`game-screen ${!startGame ? 'game-screen-video' : ''}`}>
      <div className='highlight-current-game'> 5 </div>
      {!startGame ? (
        <VideoPlaceholder setIsMusicMuted={setIsMusicMuted} />
      ) : (
        <div className="game-two-content">
          <div className="game-two-section game-five-section">
            <h2>Open deur</h2>
            <div className="game-two-text">
              <p>{questions[currentQuestionIndex]?.question}</p>
            </div>

            <div className="game-guesses">
              {showCorrectAnswers || showAllAnswersGiven ? (
                <ul className="correct-answers">
                  {showAllAnswersGiven ? (
                    <p style={{ color: 'green' }}>Alle antwoorden gegeven!</p>
                  ) : (
                    <p style={{ color: 'red' }}>Hier zijn de overgebleven antwoorden:</p>
                  )}
                  {questions[currentQuestionIndex].correctAnswers.map((answer, index) => (
                    <li key={index} style={{ color: 'green' }}>
                      ✔️ {answer}
                    </li>
                  ))}
                </ul>
              ) : (
                <ul className="game-five-ul">
                  {(allGuesses[currentQuestionIndex] || []).map((guessObj, index, arr) => (
                    <li className="list-guesses" key={index}>
                      {arr.length - index} - {guessObj.guess || ''}
                      {guessObj.isCorrect === true && (
                        <span style={{ color: 'green' }}> ✔️ {guessObj.correctDisplayAnswer} </span>
                      )}
                      {guessObj.isCorrect === false && guessObj.isRepeated && (
                        <span style={{ color: 'red' }}> ❌ - Raad eens herhaald </span>
                      )}
                      {guessObj.isCorrect === false && !guessObj.isRepeated && (
                        <span style={{ color: 'red' }}> ❌ </span>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>

          <div className="game-two-image-section game-five-image-section">
            <iframe
              src={videoUrl}
              width="100%"
              height="350px"
              frameBorder="0"
              allow="autoplay; fullscreen; encrypted-media"
              allowFullScreen
              title="Game Introduction Video"
            ></iframe>
            <div className="timer-progress-bar">
              <div className="progress" style={{ width: `${(timeLeftPerQuestion / 45) * 100}%` }}></div>
            </div>
            <div className="input-container">
              <input
                type="text"
                className="input-field"
                placeholder="Typ je antwoord..."
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                disabled={gameFinished}
              />
              <button className="submit-btn" onClick={handleSubmit} disabled={gameFinished}>
                &#9654;
              </button>
            </div>
          </div>
        </div>
      )
      }
    </div >
  );
};

export default GameScreenFive;
