import Tcms from "../Tcms";

export default function TcmsH1(props) {

  let newProps
  if(Object.keys(props).length === 1){
    newProps = {...props,'data-tcms':true}
  }

  return (
    // eslint-disable-next-line
    <h1 {...Tcms(newProps || props)}/>
  )

}
