import React, {useRef, useContext} from "react";

import TcmsDialogPickImage from "./TcmsDialogPickImage";

import {TcmsContext} from "../contexts/TcmsContext";
import TcmsWysiwygEditor from "./TcmsWysiwygEditor";

export default function TcmsContentChanger(props) {
  let refTcmsDialogPickImage = useRef()
  let refTcmsWysiwygEditor = useRef()
  let context = useContext(TcmsContext)

  // Get the content out of the props.data
  let value = props.data[props.lang][props.type]

  // Now when image make the BG image for the textarea
  let backgroundImage = ''
  let color = ''
  if (props.isMedia) {
    if (value) {
      // When we have a value use that image
      backgroundImage = `url('${value}')`
    } else {
      // No value use default
      backgroundImage = "url('/media/icons/image.svg')"
    }
    color = 'rgba(0, 0, 0, 0)'
  }
  if (props.isWYSIWYG) {
    if (value) {

    } else {
      backgroundImage = "url('/media/icons/text-editor.png')"
      color = 'rgba(0, 0, 0, 0)'
    }
  }

  return (
    <React.Fragment>
      <TcmsDialogPickImage ref={refTcmsDialogPickImage} webshop_id={context.webshopId}/>
      <TcmsWysiwygEditor ref={refTcmsWysiwygEditor}/>
      {props.isWYSIWYG

        ? <div className="is-media"
             style={{backgroundImage, color}}
             dangerouslySetInnerHTML={{__html:value}}
             onClick={() => {
               refTcmsWysiwygEditor.current.open(props.data.key, value, (newHtml) => {
                 props.onChange({props, newHtml})
               })
             }}/>

        : <textarea
          className={props.isMedia ? 'is-media' : ''}
          readOnly={props.isMedia}
          style={{
            backgroundImage,
            color
          }}
          data-lang={props.lang}
          data-type={props.type}
          value={value}
          onClick={() => {
            if (props.isMedia) {
              refTcmsDialogPickImage.current.open((url) => {
                props.onChange({props, url})
              })
            }
          }}
          onChange={props.onChange}/>}
    </React.Fragment>
  )

}
