import TcmsImg from "../../../tcms/Tags/TcmsImg";
import React, {useContext} from "react";
import axios from 'axios';
import {TcmsContext} from "../../../contexts/TcmsContext";

const LangSwitch = () => {
  const context = useContext(TcmsContext)

  const switchLanguage = () => {
    axios.get(process.env.REACT_APP_MTG_BACKEND + '/api/webshop/switch-language').then((res) => {
      context.renewJwt(res.data.jwt_token)
    })
  }

  return <>

    <div className="lang">
      <a href="/" onClick={(e) => {
        e.preventDefault()
        switchLanguage()
      }}>
        <TcmsImg className="nav-top__langswitch-icon"
                alt="Naar Engels"
                src={`/media/icons/${context.languageCode === 'nl_nl' ? 'uk' : 'nl'}.svg`}/>
      </a>
    </div>

  </>
}
export default LangSwitch
