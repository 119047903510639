import React from "react";
import Menu from "../../../components/webshop/menu/Menu";
import Background from "../../../components/webshop/background/Background";
import TcmsWYSIWYG from "../../../tcms/Tags/TcmsWYSIWYG";
import { useContext } from 'react';
import { TcmsContext } from '../../../contexts/TcmsContext';
import { useHistory } from 'react-router-dom';
import Tcms from "../../../tcms/Tcms";


export default function GiveGift(props) {

  const pathname = 'custom_page_'+window.location.pathname.split("/").pop()
  const history = useHistory();

  const context = useContext(TcmsContext)
  const giftFrom = sessionStorage.getItem("gift_from");
  const giftFromText = sessionStorage.getItem("gift_from_text");

  return (

    <React.Fragment>

      <Menu/>
      <div className='page-custom'>
        <Background/> 
        <div className="container__large content-page main-view ">
          <div className="wrapper">
            <TcmsWYSIWYG>{pathname}</TcmsWYSIWYG>
            <div className="p-grid">
              <div className="p-sm-6">
                  <img style={{width: '100%'}} src="https://backend.nationalewaarderingsbon.nl/storage/img/tinycms/774/A6CRpFTaCposBuakbdrBLd8O5TWvPwQiLNi9E6Lb.png"/>
              </div>
              <div className="p-sm-6">
                  <div className="form-wrapper">
                    <div className="p-mt-4">
                        <label><Tcms>Naam</Tcms></label>
                        <div className="p-inputgroup">
                          <input placeholder="Typ hier je eigen naam" onChange={(e) => { sessionStorage.setItem("gift_from", e.target.value); }} className="p-inputtext p-component"/>
                        </div>
                    </div>
                    <div className="p-mt-4">
                        <label><Tcms>Bericht</Tcms></label>
                        <div className="p-inputgroup">
                          <textarea className="p-inputtext p-component" maxLength="200" rows="5" onChange={(e) => { sessionStorage.setItem("gift_from_text", e.target.value); }} ></textarea>
                        </div>
                        <p style={{marginTop: 0}}><Tcms>Maximaal 200 karakters</Tcms></p>
                    </div>
                  </div>
              </div>
            </div>
            <div className="p-flex p-justify-center">
              {context.canOrder() &&
                <button
                  title={'Verstuur je geschenk'}
                  className="p-button p-component"
                  onClick={()=>{ history.push(`/${context.meta.slug}/gegevens`)}}>
                  <Tcms>Verstuur je geschenk</Tcms>
                </button>
              }
              </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )

}



