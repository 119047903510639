import React, {useEffect, useState} from "react";
import axios from "axios";
import {User, getUser} from '../../components/User'
import {Card} from "primereact/card";
import {InputTextarea} from "primereact/inputtextarea";
import {InputText} from "primereact/inputtext";
import TcmsH1 from "../../../tcms/Tags/TcmsH1";
import TcmsButton from "../../../tcms/Tags/TcmsButton";
import TcmsLabel from "../../../tcms/Tags/TcmsLabel";
import TcmsP from "../../../tcms/Tags/TcmsP";

const BE = process.env.REACT_APP_MTG_BACKEND

const Berichten = () => {

  const [user, setUser] = useState() // The real user (JWT)
  const [userEdit, setUserEdit] = useState() // edit JWT user

  const [messages, setMessages] = useState([]) // All messages
  const [user_message, setUserMessage] = useState([]) // The JWT user message

  const [value, setValue] = useState('')  // Textarea value
  const [name, setName] = useState('')    // Free name
  const [editMessage, setEditMessage] = useState(false) // Edit the message


  // Init
  useEffect(() => {
    getUser().then(user => {
      setUser(user.data)
      axios.get(BE + '/api/webshop/widgets/messages').then(res => {
        setUserMessage(res.data.user_message)
        setValue(res.data.user_message?.message || '')

        if (res.data.user_message) {
          setName(res.data.user_message.name)
        } else {
          setName(user.data.firstname + ' ' + user.data.lastname)
        }
        setMessages(res.data.messages)

      })
    });
  }, [])

  // postMessage
  const postMessage = () => {
    axios.post(BE + '/api/webshop/widgets/messages', {
      message: value,
      name
    }).then(res => {
      setUserMessage(res.data.user_message)
      setValue(res.data.user_message.message)
      setName(res.data.user_message.name)
      setMessages(res.data.messages)
      setEditMessage(false)
    })
  }

  // Dit zijn de berichten
  const berichtenHtml = () => {
    return <Card>
      {messages.map(m => {
        return <div>
          <span className="name">{m.name}</span>
          <p>
            {m.message}
          </p>
        </div>
      })}
    </Card>

  }

  // Het Vote deel
  const voteHtml = () => {
    if (!editMessage) {
      return <Card>
        <TcmsButton label={!user_message ? 'Plaats je bericht' : 'Bewerk je geplaatste bericht'}
                    onClick={() => setEditMessage(true)}/>

      </Card>
    }
    return <>

      <Card>
        <div className="p-fluid">
          <div className="p-field">
            <TcmsLabel>Jouw naam bij het bericht</TcmsLabel>
            <InputText value={name} maxlength={255} onChange={e => setName(e.target.value)}/>
          </div>
          <div className="p-field">
            <TcmsLabel htmlFor="lastname1">Jouw bericht</TcmsLabel>
            <InputTextarea rows={5}
                           cols={70}
                           maxlength={1024}
                           value={value}
                           onChange={e => setValue(e.target.value)}
                           autoResize/>
          </div>
          <div className="p-field">
            <TcmsButton disabled={value.length < 3 || name.length < 2}
                        label="Verstuur"
                        onClick={() => postMessage()}
            />
          </div>
        </div>
      </Card>


    </>
  }

  // Wait for the user
  if (!user) return null

  const completeUser = !(user.firstname && user.lastname && user.private_email && !userEdit)


  /**
   * RETURN
   */
  return <div className="widget-berichten">
    <TcmsH1 className="title_message">Het kerstprikbord</TcmsH1>
    <div className="intro">
    <TcmsP>Mooie doelen gehaald, hard gewerkt?</TcmsP>
    <TcmsP>Laat een kerstwens of andere boodschap achter voor je collega’s op het prikbord.</TcmsP>
    </div>
    {completeUser
      // When not enough user data available ask user to complete
      ? <Card>
        <User
          userData={user}
          onUserChanged={usr => {
            setUser(usr)
            setName(usr.firstname + ' ' + usr.lastname)
            setUserEdit(false)
          }}/>
      </Card>
      // Below the voting or result part
      :
      <>
        {voteHtml()}

      </>
    }
    {berichtenHtml()}
  </div>
}

export default Berichten
