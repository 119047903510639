import React, {useContext} from 'react'
import {InputText} from "primereact/inputtext";
import {TcmsContext} from "../../contexts/TcmsContext";
import Tcms from "../Tcms";
import classNames from "classnames";


export default function TcmsFloatingLabel(props) {

  const context = useContext(TcmsContext)

  // And spread (optional) use the tanslatedProps!
  let {disabled, name, placeholder, dataChanged, data, onClick, errors, className, onBlur, autoComplete, maxlength} = Tcms(props)


  if (disabled === undefined)
    disabled = false

  return (
    // Tcms Dialog.. when edit mode. !! Use the original props here for the dialog !!
    <React.Fragment>
      <div className="p-float-label" onClick={onClick}>
        <InputText id={name}
                   disabled={disabled}
                   autoComplete={autoComplete}
                   style={{width: '100%'}}
                   maxLength={maxlength}
                   className={classNames(context.editMode ? 'tcms-edit' : '', className)}
                   value={data[name]}
                   onBlur={(e) => {
                     if (onBlur) {
                       onBlur(e, name)
                     }
                   }}
                   onChange={(e) => dataChanged(e, name)
                   }/>
        <label htmlFor={name}>{placeholder}</label>
      </div>

      {/*Translate them errors too*/}
      {errors && errors[name]
        ? <small className="p-error"><Tcms>{errors[name]}</Tcms></small>
        : <small className="p-error">&nbsp;</small>
      }

    </React.Fragment>

  )
}
