import React, {forwardRef, useImperativeHandle, useState} from "react";
import {Dialog} from "primereact/dialog";
import Tcms from "../../tcms/Tcms";
import TcmsWYSIWYG from "../../tcms/Tags/TcmsWYSIWYG";



const DoubleVoucherPopup = forwardRef((props, ref) => {

  const [visible, setVisible] = useState()

  useImperativeHandle(ref, () => ({
    open() {
        setVisible(true)
    }
  }));

  return <>
    <Dialog header={<Tcms>Excuus voor het ongemak</Tcms>}
            className="vm-dialog-info"
            maximizable={false}
            maximized={false}
            style={{width: '800px', height: '40%'}}
            visible={visible}
            modal={true}
            onHide={() => setVisible(false)}>
      <div>
        <div className="p-grid page-info">
          <div className="p-col-12">
            <TcmsWYSIWYG>_voucher_needs_to_be_activated_</TcmsWYSIWYG>
          </div>
        </div>
      </div>
    </Dialog>
  </>

})

export default DoubleVoucherPopup
