import React, { useState, useEffect, useContext } from "react";
import data from './guessGameData.json';
import GameIntro from '../game-intro/GameIntro';
import GameResult from '../game-result/GameResult';
import "./GameScreenTwo.scss";
import VideoPlaceholder from "../video-placeholder/VideoPlaceholder";
import { canAccessGame, saveGameProgress, getGameAvailabilityById } from "../services/gameProgressService";
import { useHistory } from 'react-router-dom';
import { TcmsContext } from '../../../../contexts/TcmsContext';
import jwtDecode from 'jwt-decode';
import { toast } from 'react-hot-toast';
import InfoBox from '../game-infobox/InfoBox';
import gamesTransitionVideo from '../assets/games_transition.mp4';
import pingSound from '../../../../sounds/ping.mp3';
import buzzSound from '../../../../sounds/softbuzz.mp3';

const GameScreenTwo = ({ startGame, setStartGame, setIsMusicMuted, setIsIntro}) => {
  const { setJwtForGames } = useContext(TcmsContext);
  const gameIntroText = {
    title: 'Rustplaats 2: Olympische Uitdaging: Milieu en Sport!',
    description: `Na een mooie wandeling bereik je de tweede rustplaats en neem je even een slokje uit je nieuwe gerecycled fles op een bankje. 
    Hier wacht een uitdaging: herken de vlaggen van Europa’s meest vervuilende landen en toon je sportkennis met cartoonfoto’s van Olympische sporten. 
    Net zoals Kjeld Nuis scherp moet zijn op het ijs, moet jij scherp blijven om punten te scoren. Vul je rugzak en kom dichter bij die unieke overnachting in de natuur! 
    Veel succes!
`,
    instruction: 'Zet je schrap en laat je kennis stralen!',
    showMedals: true
  };

  const infoBoxContent = {
    title: "OVER SPEL 2",
    goal: "Doel: Beantwoord zoveel mogelijk vragen goed.",
    topics: "Plastic, meest vervuilende landen Europa & Olympische sport.",
    inspiration: "Kies de Gerecyclede fles in de webshop!"
  };

  const initialGuesses = Array(10).fill({ guess: '', isCorrect: null, correctAnswer: '' });
  const [inputValue, setInputValue] = useState('');
  const [guessList, setGuessList] = useState(initialGuesses);
  const [guessIndex, setGuessIndex] = useState(0);
  const [timeLeft, setTimeLeft] = useState(20);
  const [gameFinished, setGameFinished] = useState(false);
  const [correctAnswers, setCorrectAnswers] = useState([]);
  const [levelImages, setLevelImages] = useState([]);
  const [gameStarted, setGameStarted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [phase, setPhase] = useState(1);
  const [showInfoBox, setShowInfoBox] = useState(true);
  const history = useHistory();
  const [TotalcorrectGuessesCount, setTotalcorrectGuessesCount] = useState(0);
  const beginGame = () => {
    setGameStarted(true);
    setIsIntro(false);
  };

  const gameId = 2;
  const [userId, setUserId] = useState('');

  useEffect(() => {
    const storedJwtToken = sessionStorage.getItem('jwt_token');
    if (storedJwtToken) {
      setJwtForGames(storedJwtToken);
      const tokenData = jwtDecode(storedJwtToken);
      setUserId(tokenData?.usr?.uid);
    }
  }, [setJwtForGames]);

  const handleCloseInfoBox = () => {
    setShowInfoBox(false);
  };

  useEffect(() => {
    const checkGameAccess = async () => {
      try {
        const availabilityData = await getGameAvailabilityById(gameId);
        const isGameEnabled = availabilityData.enabled;
        const userProgressData = await canAccessGame(userId, gameId);
      } catch (error) {
        console.error('Error checking game access:', error.message);
      } finally {
        setLoading(false);
      }
    };
    if (userId) {
      checkGameAccess();
    }
  }, [userId]);

  // Save game progress when quiz is finished
  useEffect(() => {
    if (gameFinished) {
      const saveProgress = async () => {
        try {
          const response = await saveGameProgress(userId, gameId, TotalcorrectGuessesCount);
        } catch (error) {
          console.error('Error saving progress:', error.message);
        }
      };
      saveProgress();
    }
  }, [gameFinished]);

  useEffect(() => {
    if (gameStarted) {
      const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
        toast('Je kunt het spel niet opnieuw spelen als je de pagina verlaat!', {
          position: 'bottom-center',
          duration: 5000,
          style: {
            background: '#facc15',
            color: '#000',
          },
        });
        const currentGuessList = guessList;
        const saveProgress = async () => {
          try {
            const correctGuessesCount = currentGuessList.filter(guessObj => guessObj.isCorrect === true).length;
            const response = await saveGameProgress(userId, gameId, correctGuessesCount);
          } catch (error) {
            console.error('Error saving progress on exit:', error.message);
          }
        };
        saveProgress();
      };

      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, [gameStarted, guessList]);

  const initializeGameData = () => {
    const filteredData = phase === 1
      ? data.filter(item => item.pictureUrl.includes('flags'))
      : data.filter(item => item.pictureUrl.includes('sports'));

    const shuffledData = shuffleArray(filteredData);
    const shuffledAnswers = shuffledData.map(item => item.name);
    const shuffledImages = shuffledData.map(item => item.pictureUrl);
    setCorrectAnswers(shuffledAnswers);
    setLevelImages(shuffledImages);
  };

  useEffect(() => {
    initializeGameData();
  }, [phase]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array.slice(0, 10);
  }
  function levenshteinDistance(a, b, penaltyWeight = 0.7) {
    const matrix = [];
    const vowels = new Set(['a', 'e', 'i', 'o', 'u']);
    const commonSubstitutions = new Map([
      ['c', 'k'],
      ['s', 'z'],
      ['q', 'k'],
      ['p', 'b'],
      ['d', 't']
    ]);

    for (let i = 0; i <= b.length; i++) {
      matrix[i] = [i];
    }

    for (let j = 0; j <= a.length; j++) {
      matrix[0][j] = j;
    }

    for (let i = 1; i <= b.length; i++) {
      for (let j = 1; j <= a.length; j++) {
        const charA = a.charAt(j - 1);
        const charB = b.charAt(i - 1);

        if (charA === charB) {
          matrix[i][j] = matrix[i - 1][j - 1];
        } else {
          let substitutionCost = 1;

          if (vowels.has(charA) && vowels.has(charB)) {
            substitutionCost = 0.3;
          }
          else if (commonSubstitutions.get(charA) === charB || commonSubstitutions.get(charB) === charA) {
            substitutionCost = 0.5;
          }

          substitutionCost *= penaltyWeight;

          matrix[i][j] = Math.min(
            matrix[i - 1][j - 1] + substitutionCost,
            matrix[i][j - 1] + 1 * penaltyWeight,
            matrix[i - 1][j] + 1 * penaltyWeight
          );
        }
      }
    }

    return matrix[b.length][a.length];
  }

  const handleSubmit = () => {
    if (inputValue.trim() && !gameFinished) {
      const updatedGuesses = [...guessList];
      const nextEmptyIndex = updatedGuesses.findIndex(guessObj => guessObj.guess === '');

      if (nextEmptyIndex !== -1) {
        const isCorrect = levenshteinDistance(inputValue.toLowerCase(), correctAnswers[nextEmptyIndex].toLowerCase()) <= 2;
        updatedGuesses[nextEmptyIndex] = {
          guess: inputValue,
          isCorrect,
          correctAnswer: correctAnswers[nextEmptyIndex]
        };
        setGuessList(updatedGuesses);
        setGuessIndex(guessIndex + 1);
        setInputValue('');
        setTimeLeft(20);
        if (isCorrect) {
          setTotalcorrectGuessesCount(prevCount => prevCount + 1);
          new Audio(pingSound).play();
        }
        else{
          new Audio(buzzSound).play();
        }
        if (guessIndex + 1 === correctAnswers.length) {
          setGuessIndex(correctAnswers.length - 1);
          setTimeout(() => {
            if (phase === 1) {
              setPhase(2);
              setGuessList(Array(10).fill({ guess: '', isCorrect: null, correctAnswer: '' }));
              setGuessIndex(0);
              setTimeLeft(20);
            } else {
              setGameFinished(true);
            }
          }, 1000);
        }
      }
    }
  };

  const handleFinishGame = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setStartGame(false);
      history.push('/games?game=3');
    }, 7000);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }
  };

  useEffect(() => {
    if (gameStarted && !gameFinished && timeLeft > 0) {
      const timerId = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timerId);
    } else if (timeLeft === 0 && gameStarted && !gameFinished) {
      const updatedGuesses = [...guessList];
      if (guessIndex < correctAnswers.length) {
        updatedGuesses[guessIndex] = {
          guess: 'Timed out',
          isCorrect: false,
          correctAnswer: correctAnswers[guessIndex]
        };
        setGuessList(updatedGuesses);
        setGuessIndex(guessIndex + 1);
        setTimeLeft(20);
        if (guessIndex + 1 === correctAnswers.length) {
          setGuessIndex(correctAnswers.length - 1);
          setTimeout(() => {
            if (phase === 1) {
              setPhase(2);
              setGuessList(Array(10).fill({ guess: '', isCorrect: null, correctAnswer: '' }));
              setGuessIndex(0);
              setTimeLeft(20);
            } else {
              setGameFinished(true);
            }
          }, 500);
        }
      }
    }
  }, [timeLeft, guessIndex, guessList, gameFinished, gameStarted, phase]);

  if (!gameStarted && startGame) {
    return (
      <>
        <GameIntro {...gameIntroText} onStart={beginGame} setIsMusicMuted={setIsMusicMuted} />
        {showInfoBox && (
          <InfoBox
            {...infoBoxContent}
            onClose={handleCloseInfoBox}
          />
        )}
      </>
    );
  }

  if (loading) {
    return (
      <div className="game-loader">
        <video
          src={gamesTransitionVideo}
          autoPlay
          muted
          onEnded={() => setLoading(false)}
          className="transition-video"
        />
      </div>
    );
  }

  if (gameFinished) {
    return (
      <GameResult
        score={TotalcorrectGuessesCount}
        totalQuestions={20}
        isSecondGame={true}
        onNextGame={handleFinishGame}
      />
    );
  }

  return (
    <div className={`game-screen ${!startGame ? 'game-screen-video' : ''}`}>
      <div className='highlight-current-game'> 2 </div>
      {!startGame ? (
        <VideoPlaceholder setIsMusicMuted={setIsMusicMuted}/>
      ) : (
        <div className="game-two-content">
          <div className="game-two-section">
            <h2>{phase === 1 ? 'Vlaggen Herkenning' : 'Olympische Sporten Herkenning'}</h2>
            <div className="game-two-text">
              <p>{phase === 1 ? 'De vraag boven elke afbeelding is: Welk landen zijn dit? (20 seconde per vlag)' : 'Welke Olympische sport is dit? (20 seconde per sport)'}</p>
            </div>
            <div className="game-guesses">
              <ol>
                {guessList.map((guessObj, index) => (
                  <li
                    className={`list-guesses ${guessObj.isCorrect ? 'correct' : guessObj.isCorrect === false ? 'incorrect' : ''}`}
                    key={index}
                  >
                    {guessObj.guess || ''}
                    {guessObj.isCorrect === true && (
                      <span style={{ color: 'green' }}> ✔️ {guessObj.correctAnswer}</span>
                    )}
                    {guessObj.isCorrect === false && (
                      <span style={{ color: 'red' }}> ❌ {guessObj.correctAnswer}</span>
                    )}
                  </li>
                ))}
              </ol>
            </div>
          </div>

          <div className="game-two-image-section">
            <img
              className="game-image game-image-two"
              src={levelImages[guessIndex]}
              alt="Game Image"
              style={{
                width: '100%',
                height: '350px',
                objectFit: 'contain'
              }}
            />
            <div className="timer-progress-bar">
              <div className="progress" style={{ width: `${(timeLeft / 20) * 100}%` }}></div>
            </div>
            <div className="input-container">
              <input
                type="text"
                className="input-field"
                placeholder="Typ je antwoord..."
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                disabled={gameFinished}
              />
              <button className="submit-btn" onClick={handleSubmit} disabled={gameFinished}>
                &#9654;
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GameScreenTwo;
