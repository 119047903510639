import Tcms from "../../../tcms/Tcms"
import TcmsDiv from "../../../tcms/Tags/TcmsDiv"
import React, {useContext} from "react";
import { TcmsContext } from "../../../contexts/TcmsContext"

export default function Background() {

  const context = useContext(TcmsContext);
  let defaultBgImage = context.mobile ? `/media/main.svg` : `/media/main.svg`

  return (
    <div className="main-bg-wrapper">
      <div style={{
        backgroundImage: `url('${Tcms(defaultBgImage)}')`,
      }} className="main-bg"></div>

      {context.editMode &&
        <TcmsDiv style={{
          backgroundImage: `url('${defaultBgImage}')`,
        }} className="tcms-bg-image-picker"/>}

    </div>
  )
}



