import React, { useState, useEffect, useContext } from 'react';
import Menu from '../../../components/webshop/menu/Menu';
import axios from "axios";
import TcmsWYSIWYG from "../../../tcms/Tags/TcmsWYSIWYG";
import TcmsImg from "../../../tcms/Tags/TcmsImg";
import Tcms from '../../../tcms/Tcms';
import { TcmsContext } from "../../../contexts/TcmsContext";
import { RadioButton } from "primereact/radiobutton";
import TcmsSpan from "../../../tcms/Tags/TcmsSpan";
import Background from '../../../components/webshop/background/Background';
import Footer from '../../../components/webshop/footer/Footer';

const BE = process.env.REACT_APP_MTG_BACKEND


const Email = (props) => {
  const context = useContext(TcmsContext)
  const [mailType, setMailType] = useState('product')
  const [parcel, setParcel] = useState('home')
  const [mailContent, setMailContent] = useState({
    keys: [],
    raw: ''
  })

  useEffect(() => {

    const splitMail = (raw) => {
      // Get the keys like [[__this_is_a_key__]]
      let keys = raw.match(/\[\[(.*?)]]/g) // Works in Safari! But have to trim the [[...]] later

      if (keys) {
        // trim the [[...]] and make unique
        keys = keys.map(k => k.substr(2, k.length - 4)).filter((e, i, a) => i === a.indexOf(e))
        return {
          raw,
          keys
        }
      } else {
        return null
      }
    }

    const endpoint = '/api/webshop/mail/get-order-mail' +
      (context.isAdmin ? '?type=' + mailType : '') +
      (context.isAdmin ? '&parcel=' + parcel : '') +
      (context.isAdmin ? '&wso_id=' + context.jwt.usr.wid : '') +
      (context.editMode ? '&edit=1' : '')

    axios.get(BE + endpoint).then(res => {
      const splitted = splitMail(res.data)
      if (splitted) {
        setMailContent(splitted);
      } else {
        setMailContent({ raw: res.data, keys: [] })
      }

    })
    // When edit mode we're admin.. get a mail
  }, [context.editMode, context.languageCode, mailType, parcel, context.isAdmin, context.jwt.usr.wid])

  const editableFields = () => {
    return <div style={{ width: '850px', margin: 'auto', border: 'solid 1px aquamarine', marginTop: '5px' }}>
      {mailContent.keys.map(key => {
        let tcms_type
        if (key.indexOf('/') === 0) {
          tcms_type = 'img'
        } else if (key === "_span_bedankt_") {
          tcms_type = 'tcms';
        } else {
          tcms_type = key.split('_')[1]
        }
        // This is the EDIT part
        switch (tcms_type) {
          case 'img':
            return <div key={key} style={{ display: 'flex', border: 'solid 0px gainsboro' }}>
              {/*Do not show entire key het.. dunno but I guess OK */}
              <div style={{ width: '35%' }}>{key.substr(BE.length)}</div>
              <div style={{ padding: '3px' }}>
                <TcmsImg id={key} style={{ maxWidth: '650px' }} src={BE + key} />
              </div>
            </div>

          case 'wysiwyg':
            return <div key={key} style={{ display: 'flex', border: 'solid 0px gainsboro' }}>
              <div style={{ width: '35%' }}>{key}</div>
              <div style={{ padding: '3px' }}><TcmsWYSIWYG id={key}>{('' + key)}</TcmsWYSIWYG></div>
            </div>
          case 'span':
            return <div key={key} style={{ display: 'flex', border: 'solid 0px gainsboro' }}>
              <div style={{ width: '35%' }}>{key}</div>
              <div style={{ padding: '3px' }}><TcmsSpan id={key}>{('' + key)}</TcmsSpan></div>
            </div>
          case 'tcms':
            return <div key={key} style={{ display: 'flex', border: 'solid 0px gainsboro' }}>
              <div style={{ width: '35%' }}>{key}</div>
              <div style={{ padding: '3px' }}><Tcms>{key}</Tcms></div>
            </div>
          default:
            return null
        }
      })}

    </div>
  }

  /**
   * Do not delete below for now
   */
  // eslint-disable-next-line
  const iframeLoaded = () => {

    const iframe = document.getElementById('mtg-email-iframe')
    const body = iframe.contentWindow.document.getElementsByTagName('body')[0]

    if (context.editMode && body.hasChildNodes()) {
      const t = '_span_bedankt_';
      var elms = body.getElementsByTagName("*")

      for (let i = 0; i < elms.length; i++) {
        let myChildren = elms[i].childNodes;

        for (let j = 0; j < myChildren.length; j++) {
          if (myChildren[j].nodeType === 3) {
            if (myChildren[j].nodeValue.indexOf('[[' + t + ']]') !== -1) {
              let parentNode = myChildren[j].parentNode
              // I guess my thing should always be in the midlle of vals below
              let vals = myChildren[j].nodeValue.split('[[' + t + ']]')  // The 2 texts
              let tcms = document.getElementById(t)                   // The TCMS thing
              parentNode.textContent = ''
              parentNode.appendChild(document.createTextNode(vals[0]))
              parentNode.appendChild(tcms)
              parentNode.appendChild(document.createTextNode(vals[1]))
            }
          }
        }
      }
    }
  }

  return (
    <>
      <div className='page-wrapper'>
        <Background />
        <div className="page-email">
          <Menu />
          <div className="container__large content-page">
            {context.isAdmin && <>
              <div style={{ width: '850px', margin: 'auto', border: 'solid 1px aquamarine', marginTop: '5px' }}>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '49.95%', display: 'flex', padding: '10px 0 0 10px' }}>
                    <div style={{ width: '33.3%' }} className="p-field-radiobutton">
                      <RadioButton value="product" inputId="product" onChange={(e) => setMailType(e.value)}
                        checked={mailType === 'product'} />
                      <label htmlFor="product">Product</label>
                    </div>
                    <div style={{ width: '33.3%' }} className="p-field-radiobutton">
                      <RadioButton value="consu" inputId="consu" onChange={(e) => setMailType(e.value)}
                        checked={mailType === 'consu'} />
                      <label htmlFor="consu">Giftcard</label>
                    </div>
                    <div style={{ width: '33.3%' }} className="p-field-radiobutton">
                      <RadioButton value="service" inputId="service" onChange={(e) => setMailType(e.value)}
                        checked={mailType === 'service'} />
                      <label htmlFor="service">Goed doel</label>
                    </div>
                  </div>
                  <div style={{ width: '0.1%', backgroundColor: 'aquamarine' }} />

                  <div style={{ width: '49.95%', display: 'flex', padding: '10px 0 0 10px' }}>
                    <div style={{ width: '33.3%' }} className="p-field-radiobutton">
                      <RadioButton value="home" inputId="home" onChange={(e) => setParcel(e.value)}
                        checked={parcel === 'home'} />
                      <label htmlFor="home">Huisadres</label>
                    </div>
                    <div style={{ width: '33.3%' }} className="p-field-radiobutton">
                      <RadioButton value="parcel" inputId="parcel" onChange={(e) => setParcel(e.value)}
                        checked={parcel === 'parcel'} />
                      <label htmlFor="parcel">Afhaalpunt</label>
                    </div>
                    <div style={{ width: '33.3%' }} className="p-field-radiobutton">
                      <RadioButton value="company" inputId="company" onChange={(e) => setParcel(e.value)}
                        checked={parcel === 'company'} />
                      <label htmlFor="company">Bedrijfslokatie</label>
                    </div>

                  </div>
                </div>
              </div>
            </>}
            {/*Keys*/}
            {context.editMode &&
              editableFields()
            }

          </div>
          <div style={{ width: '660px', margin: 'auto', backgroundColor: '#ffffff' }}>
            <iframe
              id="mtg-email-iframe"
              title="Email"
              srcDoc={mailContent.raw}
              width="100%"
              height="2000px"
              frameBorder="0"
              allowFullScreen=""
              onLoad={() => iframeLoaded()}
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            ></iframe>
          </div>

        </div>
      </div>
      <Footer />
    </>
  )
}

export default Email



