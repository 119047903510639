import axios from 'axios';

// Local URL for development
//const BASE_URL = 'http://localhost:3000';

// Deployed URL
const BASE_URL = 'https://belastingdiest-server.adaptable.app';

// Get user game progress
export const getUserProgress = async (userId) => {
  try {
    const response = await axios.get(`${BASE_URL}/user-progress/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching user progress:', error);
    throw error;
  }
};

// Save game progress (mark as completed and save score)
export const saveGameProgress = async (userId, gameNumber, score) => {
  try {
    const response = await axios.post(`${BASE_URL}/save-progress/${userId}/${gameNumber}`, { score });
    return response.data;
  } catch (error) {
    console.error('Error saving game progress:', error);
    throw error;
  }
};

// Check if the user can access a specific game (Game Guard)
export const canAccessGame = async (userId, gameNumber) => {
  try {
    const response = await axios.get(`${BASE_URL}/can-access-game/${userId}/${gameNumber}`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 403) {
      throw new Error(error.response.data.error);
    } else {
      console.error('Error checking game access:', error);
      throw error;
    }
  }
};

// Get game availability (whether games are enabled/disabled globally)
export const getGameAvailability = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/game-availability`);
    return response.data;
  } catch (error) {
    console.error('Error fetching game availability:', error);
    throw error;
  }
};

// Get availability for a specific game by gameID
export const getGameAvailabilityById = async (gameID) => {
  try {
    const response = await axios.get(`${BASE_URL}/game-availability/${gameID}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching availability for game ${gameID}:`, error);
    throw error;
  }
};

export const getLeaderboardData = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/leaderboard`);
    return response.data;
  } catch (error) {
    console.error('Error fetching leaderboard data:', error);
    throw error;
  }
};

export const getGameScores = async (gameId) => {
  try {
    const response = await axios.get(`${BASE_URL}/game-scores/${gameId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching scores for game ${gameId}:`, error);
    throw error;
  }
};

// Get user final results
export const getUserFinalResults = async (userId) => {
  try {
    const response = await axios.get(`${BASE_URL}/game-results/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching user results:', error);
    throw error;
  }
};