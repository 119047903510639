import React, { useState, useEffect, useContext } from "react";
import VideoPlaceholder from "../video-placeholder/VideoPlaceholder";
import GameIntro from '../game-intro/GameIntro';
import GameResult from '../game-result/GameResult';
import { useHistory } from 'react-router-dom';
import "./GameScreenSix.scss";
import WordSearchGame from "./WordSearchGame.js";
import { canAccessGame, saveGameProgress, getGameAvailabilityById } from "../services/gameProgressService";
import { TcmsContext } from '../../../../contexts/TcmsContext';
import jwtDecode from 'jwt-decode';
import { toast } from 'react-hot-toast';
import InfoBox from '../game-infobox/InfoBox';
import gamesTransitionVideo from '../assets/games_transition.mp4';
import pingSound from '../../../../sounds/ping.mp3';

const GameScreenSix = ({ startGame, setIsMusicMuted, setIsIntro }) => {
  const { setJwtForGames } = useContext(TcmsContext);

  const gameIntroText = {
    title: 'Welkom bij Spel 6: De Kruiswoordpuzzel 🧩',
    description: `Na een lange tocht kom je aan bij de zesde rustplaats. Zittend op een bankje met je duurzame Multipillow van gerecycled materiaal♻️, vind je rust voor de laatste uitdaging: een woordzoeker vol woorden over inclusiviteit. Los de puzzel zo snel mogelijk op, Veel succes!`,
    instruction: 'Vraag iemand om je te helpen! Het is niet alleen handig, maar ook leuk! Scoor meer dan het bepaalde aantal punten om het item "sleutel" voor in je rugzak te verdienen 🗝️. Deze drempelwaarde wordt bepaald door de spelbegeleiders tijdens het spelen met het testpanel.',
    showMedals: false
  };

  const infoBoxContent = {
    title: "OVER SPEL 6",
    goal: "Zoek 5 woorden in de woordzoeker.",
    topics: "Inclusiviteit",
    inspiration: "Kies de Multipillow in de webshop!"
  };

  const [correctLetters, setCorrectLetters] = useState(0);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(180);
  const [timeLeft, setTimeLeft] = useState(100);
  const [gameStarted, setGameStarted] = useState(false);
  const [gameFinished, setGameFinished] = useState(false);
  const [hiddenWord, setHiddenWord] = useState("_____");
  const [revealedLetters, setRevealedLetters] = useState(0);
  const [shouldShowEndScreen, setShouldShowEndScreen] = useState(false);
  const [showInfoBox, setShowInfoBox] = useState(true);
  const targetWord = "APPLE";
  const history = useHistory();

  const gameId = 6;
  const [userId, setUserId] = useState('');

  useEffect(() => {
    const storedJwtToken = sessionStorage.getItem('jwt_token');
    if (storedJwtToken) {
      setJwtForGames(storedJwtToken);
      const tokenData = jwtDecode(storedJwtToken);
      console.log('tokenData', tokenData);
      setUserId(tokenData?.usr?.uid);
    }
  }, [setJwtForGames]);

  // Check if the user can access the game and if game is available to play
  useEffect(() => {
    const checkGameAccess = async () => {
      try {
        // Fetch game availability (is game globally enabled?)
        const availabilityData = await getGameAvailabilityById(gameId);
        const isGameEnabled = availabilityData.enabled;
        // if (!isGameEnabled) {
        //   alert('Game is disabled');
        // }

        // Fetch user progress (has the user completed this game?)
        const userProgressData = await canAccessGame(userId, gameId);
        console.log('userProgressData', userProgressData);
        // Determine if the game can be played
        // if (isGameEnabled && !isGameCompleted) {
        //   setGameEnabled(true);
        // } else {
        //   setGameEnabled(false);
        // }
      } catch (error) {
        console.error('Error checking game access:', error.message);
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      checkGameAccess();
    }
  }, [userId]);


  useEffect(() => {
    if (gameStarted && !gameFinished) {
      const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
        toast('Je kunt het spel niet opnieuw spelen als je de pagina verlaat!', {
          position: 'bottom-center',
          duration: 5000,
          style: {
            background: '#facc15',
            color: '#000',
          },
        });
        const saveProgress = async () => {
          try {
            const response = await saveGameProgress(userId, gameId, correctLetters);
          } catch (error) {
            console.error('Error saving progress on exit:', error.message);
          }
        };
        saveProgress();
      };
      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, [gameStarted, gameFinished, correctLetters, userId, gameId]);

  const beginGame = () => {
    setGameStarted(true);
    setTimeLeft(180);
    setProgress(100);
    setIsIntro(false);
  };

  useEffect(() => {
    if (gameStarted && timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft(prevTime => prevTime - 1);
        setProgress((prevProgress) => (prevProgress - 100 / 180));
      }, 1000);

      return () => clearInterval(timer);
    } else if (timeLeft <= 0) {
      setGameFinished(true);
      setTimeout(() => {
        setShouldShowEndScreen(true);
      }, 1000);
    }
  }, [timeLeft, gameStarted]);

  const handleFinishGame = () => {
    // setLoading(true);
    // setTimeout(() => {
    //   setLoading(false);
    //   history.push('/games?game=7');
    // }, 3000);
  };

  const handleGameEvent = () => {
    if (revealedLetters < targetWord.length) {
      new Audio(pingSound).play();
      setRevealedLetters(prev => prev + 1);
      setCorrectLetters(prev => prev + 1);
      const newHiddenWord = revealNextLetter(targetWord, revealedLetters + 1);
      setHiddenWord(newHiddenWord);

      if (revealedLetters + 1 === targetWord.length) {
        setGameFinished(true);
        setTimeout(() => {
          setShouldShowEndScreen(true);
        }, 3000);
      } 
    }
  };

  const revealNextLetter = (word, numRevealed) => {
    const revealedWord = word
      .split('')
      .map((char, idx) => (idx < numRevealed ? char : "_"))
      .join('');
    return revealedWord;
  };

  useEffect(() => {
    if (!gameStarted && startGame) {
      setTimeout(() => {
        setGameStarted('intro');
      },);
    }
  }, [startGame, gameStarted]);

  useEffect(() => {
    if (shouldShowEndScreen) {
      const saveProgress = async () => {
        try {
          const response = await saveGameProgress(userId, gameId, correctLetters);
        } catch (error) {
          console.error('Error saving progress:', error.message);
        }
      };
      saveProgress();
    }
  }, [gameFinished]);

  const handleCloseInfoBox = () => {
    setShowInfoBox(false);
  };

  if (gameStarted === 'intro') {
    return (
      <>
        <GameIntro {...gameIntroText} onStart={beginGame} setIsMusicMuted={setIsMusicMuted} />
        {showInfoBox && (
          <InfoBox
            {...infoBoxContent}
            onClose={handleCloseInfoBox}
          />
        )}
      </>
    );
  }

  if (loading) {
    return (
      <div className="game-loader">
        <video
          src={gamesTransitionVideo}
          autoPlay
          muted
          onEnded={() => setLoading(false)}
          className="transition-video"
        />
      </div>
    );
  }

  if (shouldShowEndScreen) {
    return (
      <GameResult
        score={correctLetters}
        totalLetters={targetWord.length}
        revealedWord={targetWord}
        isSixthGame={true}
        onNextGame={handleFinishGame}
      />

    );
  }

  return (
    <div className={`game-screen ${!startGame ? 'game-screen-video' : ''}`}>
      <div className='highlight-current-game'> 6 </div>
      {!startGame ? (
        <VideoPlaceholder setIsMusicMuted={setIsMusicMuted} />
      ) : (
        <div className="kruiswoordpuzzel-content">
          <div className="kruiswoordpuzzel-description">
            <h2>Woordzoeker</h2>
            <p>
              Vind alle woorden over inclusiviteit in de woordzoeker. De letters kunnen horizontaal, verticaal en diagonaal staan.
            </p>
            <p>
              Tip: <br />
              Vraag je collega, partner, vriend/vriendin, kennis of familie om te helpen.
            </p>
            <p>
              Bij meer dan x aantal gescoorde punten krijgt de medewerker item 6 voor in de rugtas: de sleutel.
            </p>

            <p className="hidden-word">
              Geheime woord: <span className="hidden-word-display">{hiddenWord}</span>
            </p>

            <div className="progress-bar">
              <div className="progress" style={{ width: `${progress}%` }}></div>
            </div>
            <p className="resterende">Resterende tijd: {timeLeft} seconden</p>
          </div>

          <div className="kruiswoordpuzzel-game">
            <WordSearchGame onGameEvent={handleGameEvent} />
          </div>
        </div>
      )
      }
    </div>
  );
};


export default GameScreenSix;
