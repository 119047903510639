import {Dropdown} from "primereact/dropdown";
import Tcms from "../Tcms";

export default function TcmsDropdown(props) {

  return (
    <Dropdown {...Tcms(props)}/>
  )

}
