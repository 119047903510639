import React, {useContext} from 'react';
import Menu from '../../../components/webshop/menu/Menu';
import {TcmsContext} from '../../../contexts/TcmsContext';
import Background from '../../../components/webshop/background/Background';
import { useHistory } from 'react-router-dom';
import TcmsH1 from '../../../tcms/Tags/TcmsH1';
import TcmsImg from '../../../tcms/Tags/TcmsImg';
import TcmsH3 from '../../../tcms/Tags/TcmsH3';
import TcmsP from '../../../tcms/Tags/TcmsP';
import TcmsButton from '../../../tcms/Tags/TcmsButton';
import TcmsSpan from '../../../tcms/Tags/TcmsSpan';
import Tcms from '../../../tcms/Tcms';
import TcmsWYSIWYG from '../../../tcms/Tags/TcmsWYSIWYG';
import Footer from '../../../components/webshop/footer/Footer';


const Welcome = () => {

  const context = useContext(TcmsContext)

  const pathname = window.location.pathname

  const history = useHistory();

  return (
    <>
    <div className='page-wrapper'>
      <Background/>
      {context.welcomeScreenIntro 
      ?
        <div className="page-welcome main-view page-welcome-intro">
          <Menu/>
          <div className='page-wrapper'>
            <div className='hidden-xs'>
              <div className="embed-container">
                {pathname == '/'+context.webshopSlug+'/plein' && context.meta.plein_iframe?
                  <iframe frameborder="0" scrolling="no" src={context.meta.plein_iframe}></iframe>
                :
                  <iframe frameborder="0" scrolling="no" src={context.meta.welcome_screen_intro}></iframe>
                }
              </div>
            </div>
            <div className='visible-xs'>
              {pathname == '/'+context.webshopSlug+'/plein' && context.meta.plein_iframe ?
                <iframe frameborder="0" scrolling="no" src={context.meta.plein_iframe + '/mobile'}></iframe>
              :
                <iframe frameborder="0" scrolling="no" src={context.meta.welcome_screen_intro + '/mobile'}></iframe>
              }
            </div>
          </div>
        </div>
      :
        <div className="page-welcome main-view">
          <Menu/>

          <div className="container__large content-page infopage">
            <div className='welcome-title'>
              <TcmsH1>welcome_title</TcmsH1>
            </div>
            <div className="p-grid options">
                <div className="p-lg-6 p-sm-6 px-col">
                  <div className='option'>
                    <div className='image'>
                      <TcmsImg src="/media/images/placeholders/555x555.png"></TcmsImg>
                    </div>
                    <div className='text'>
                      <TcmsH3>title_option_1</TcmsH3>
                      <TcmsP>intro_option_1</TcmsP>
                      <div className='p-grid'>
                        <div className='p-sm-6'>
                          <TcmsWYSIWYG>
                            option_1_usps_1
                          </TcmsWYSIWYG>
                        </div>
                        <div className='p-sm-6'>
                          <TcmsWYSIWYG>
                            option_1_usps_2
                          </TcmsWYSIWYG>
                        </div>
                        <div className='p-sm-12 text-right'>
                          <div className='button'>
                          </div>
                        </div>
                      </div>
                    </div>
                    <TcmsButton className="welcome-btn" label="Beleef nu" onClick={() => {history.push(`/${context.webshopSlug}/virtueel`)}}></TcmsButton>
                  </div>
                </div>
                <div className="p-lg-6 p-sm-6 px-col">
                  <div className='option'>
                    <div className='image'>
                      <TcmsImg src="/media/images/placeholders/250x250.png"></TcmsImg>
                    </div>
                    <div className='text'>
                      <TcmsH3>title_option_2</TcmsH3>
                      <TcmsP>intro_option_2</TcmsP>
                      <div className='p-grid'>
                        <div className='p-sm-12'>
                          <TcmsWYSIWYG>
                            option_2_usps_1
                          </TcmsWYSIWYG>
                        </div>
                      </div>
                    </div>
                    <TcmsButton className="welcome-btn" label="Shop nu" onClick={() => {history.push(`/${context.webshopSlug}/webshop/`)}}></TcmsButton>
                  </div>
                </div>
            </div>
          </div>
        </div>
      }
    </div>
    <Footer/>

    </>
  )
}

export default Welcome



