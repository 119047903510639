import React, { createRef, useContext } from 'react';
import Menu from '../../../components/webshop/menu/Menu';
import { TcmsContext } from '../../../contexts/TcmsContext';
import CompTiles from '../../../components/webshop/portal/Tiles';
import Banner from "../../../components/webshop/banner/Banner";
import TcmsWYSIWYG from "../../../tcms/Tags/TcmsWYSIWYG";
import VirtualDialogPortal from "../../../components/virtual/dialogs/VirtualDialogPortal";
import TcmsButton from "../../../tcms/Tags/TcmsButton";
import Background from '../../../components/webshop/background/Background';
import { useHistory } from 'react-router-dom';
import Footer from '../../../components/webshop/footer/Footer';

const Portal = () => {
  const context = useContext(TcmsContext);
  const refVirtualDialogPortal = createRef();
  const history = useHistory();

  const additionalStyles = {
    pageWrapper: {
      borderRadius: "18px",
    },
    mainView: {
      borderRadius: "18px",
    },
    containerLarge: {
      padding: "25px",
      borderRadius: "25px",
      marginTop: "30px",
    },
    contentContainer: {
      padding: '25px'
    }
  };

  return (
    <>
      <VirtualDialogPortal ref={refVirtualDialogPortal} />
      <div className='page-wrapper' style={additionalStyles.pageWrapper}>
        <Background />
        <div className="page-portal main-view" style={additionalStyles.mainView}>
          <Menu />
          <Banner />
          <div className="container__large content-page infopage" style={additionalStyles.containerLarge}>
            {context.meta.widgets.length ? (
              <>
                {/* when we have tiles we show them */}
                <TcmsButton
                  className='go-to-webshop-btn'
                  label="Voorwoord"
                  onClick={() => refVirtualDialogPortal.current.open()}
                >
                </TcmsButton>
                <CompTiles />
              </>
            ) : (
              <div className='page-wrapper page_wraper_custom' style={additionalStyles.pageWrapper}>
                <div style={additionalStyles.contentContainer}>
                  <TcmsWYSIWYG className='generated_content'>_mtg_voorwoord_</TcmsWYSIWYG>
                </div>
                {context.virtualMarketSlug !== '' && context.loadedComponent === 'Webshop' ? (
                  <TcmsButton
                    className='go-to-webshop-btn'
                    label="Ga naar webshop"
                    onClick={() => {
                      history.push(`/${context.webshopSlug}/virtueel`);
                    }}
                  >
                  </TcmsButton>
                ) : (
                  <TcmsButton
                    className='go-to-webshop-btn'
                    label="Ga naar webshop"
                    onClick={() => {
                      history.push(`/${context.webshopSlug}/webshop/`);
                    }}
                  >
                  </TcmsButton>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default Portal;
