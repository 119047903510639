import React, { useEffect, useRef } from "react";
import "./GameIntro.scss";
import natureSound from '../../../../sounds/nature.mp3';

const gameImages = {
  1: "url('https://th.bing.com/th/id/R.fff1cb20acbc28ba2023f7257ba06ce8?rik=b4pIBZBvvlsCaw&pid=ImgRaw&r=0')",
  2: "url('https://w7.pngwing.com/pngs/566/173/png-transparent-national-flags-themed-planet-illustration-globe-flags-of-the-world-world-flag-flag-flag-countries-flags-spherical-miscellaneous-flag-symmetry.png')",
  3: "url('https://play-lh.googleusercontent.com/Iu9G897Sm7qYZdQNlF8Sfo2w555o2Ekr9MwpIfj7iG_0zG5CF9HHAnfRKBK0Lx9rECE')",
  4: "url('https://yt3.googleusercontent.com/8Luo4GTb5O9sLKrF0mye-mFKdrEYS-6JnT-HH1Q3EblCuRPRFi0Dq6vNBTJ6ksto7Ji0Jt45Ig=s900-c-k-c0x00ffffff-no-rj')",
  5: "url('https://w7.pngwing.com/pngs/471/813/png-transparent-quiz-exam-icon-button-test-examination-logo-education-college-knowledge.png')",
  6: "url('https://img.tapimg.net/market/images/6b0d79dfdb57c9ce0220b43e3a1a02aa.png')"
};

const GameIntro = ({ title, description, instruction, showMedals = false, onStart, setIsMusicMuted }) => {
  const audioRef = useRef(null);
  useEffect(() => {
    const audio = new Audio(natureSound);
    audio.loop = true;
    audio.volume = 0.3;
    audioRef.current = audio;
    audio.play();
    setIsMusicMuted(true);

    return () => {
      audio.pause();
      setIsMusicMuted(false);
    };
  }, [setIsMusicMuted]); 

  const urlParams = new URLSearchParams(window.location.search);
  const gameNumber = parseInt(urlParams.get("game"), 10);
  const backgroundImage = gameImages[gameNumber];


  return (
    <div className="game-intro-container">
      <div className="game-intro-content">
        <div className="intro-text-container">
          <h1 className="intro-title">{title}</h1>

          {showMedals && (
            <div className="medal-icons">
              <span role="img" aria-label="gold-medal" className="medal gold-medal">🥇</span>
              <span role="img" aria-label="silver-medal" className="medal silver-medal">🥈</span>
              <span role="img" aria-label="bronze-medal" className="medal bronze-medal">🥉</span>
            </div>
          )}

          <p className="intro-description">{description}</p>
          <p className="intro-instruction">{instruction}</p>
          <button className="start-game-btn" onClick={onStart}>
            Start de uitdaging
          </button>
        </div>

        {/* Image Placeholder with Dynamic Background */}
        <div className="intro-image-placeholder">
          <div className="image-circle" style={{ backgroundImage }}></div>
        </div>
      </div>
    </div>
  );
};

export default GameIntro;