import { Card } from 'primereact/card';
import React, { useEffect, useState } from 'react'
import axios from "axios";
import { Button } from 'primereact/button';
import {TcmsContext} from "../../contexts/TcmsContext";
import {useHistory} from "react-router-dom";
import { useContext } from 'react';
import TcmsH1 from '../../tcms/Tags/TcmsH1';
import TcmsP from '../../tcms/Tags/TcmsP';
import TcmsWYSIWYG from '../../tcms/Tags/TcmsWYSIWYG';
import Tcms from '../../tcms/Tcms';
import TcmsImg from '../../tcms/Tags/TcmsImg';
import TcmsButton from '../../tcms/Tags/TcmsButton';

function ThankYou(props) {
    const [userInfo, setUserInfo] = useState([])
    const context = useContext(TcmsContext)
    const history = useHistory()
    const BE = process.env.REACT_APP_MTG_BACKEND
  
    useEffect(() => {
      axios.get(BE + '/api/webshop/voucher').then(usr => {
        setUserInfo(usr.data);
      })
    }, [])

  return <>
        
        <Card className='p-mt-6'>
            <div className='banner-content'>
                <div className='banner'>
                    <TcmsImg src="/media/images/bedankt.png" alt="" className='p-bg-contain'></TcmsImg>
                </div>
            </div>
            <div className='p-px-6'>
                <div>
                    <TcmsH1 className='p-text-center'>ty_title</TcmsH1>
                    <p><Tcms>ty_beste</Tcms> {userInfo.firstname},</p>
                    <TcmsP>ty_thankyou</TcmsP>
                    <p>
                        <Tcms>ty_email</Tcms> {userInfo.private_email}.
                        <br/>
                        <Tcms>ty_noemail</Tcms>
                    </p>

                    <TcmsWYSIWYG>
                        ty_text
                    </TcmsWYSIWYG>
                </div>
                <div className='p-flex p-justify-content-between'>
                    {/* <Button label='Helpdesk' className='p-flex p-mr-2'/> */}
                    <TcmsButton onClick={() => history.push(`/${context.webshopSlug}/bestellingen`)} label='Mijn bestellingen'  className='p-flex'/>
                </div>
            </div>
        </Card>
      </>

}

export default ThankYou
