import Tcms from "../Tcms";

export default function TcmsP(props) {

    let newProps
    if(Object.keys(props).length === 1){
        newProps = {...props,'data-tcms':true}
    }

    return (
      <p {...Tcms(newProps || props)}/>
    )
}
