import React, { useEffect, useState, useContext } from 'react';
import { getUserFinalResults } from '../services/gameProgressService';
import './FinalResult.scss';
import { TcmsContext } from '../../../../contexts/TcmsContext';
import jwtDecode from 'jwt-decode';

const FinalResult = () => {
  const [progress, setProgress] = useState(null);
  const { setJwtForGames } = useContext(TcmsContext);
  const [userId, setUserId] = useState(''); // Keep the userId here

  useEffect(() => {
    const storedJwtToken = sessionStorage.getItem('jwt_token');
    if (storedJwtToken) {
      setJwtForGames(storedJwtToken);
      const tokenData = jwtDecode(storedJwtToken);
      setUserId(tokenData?.usr?.uid);
    }
  }, [setJwtForGames]);

  useEffect(() => {
    const fetchProgress = async () => {
      try {
        const userProgress = await getUserFinalResults(userId);
        console.log('User progress:', userProgress);
        setProgress(userProgress);
      } catch (error) {
        console.error('Failed to fetch user progress', error);
      }
    };

    if (userId) {
      fetchProgress();
    }
  }, [userId]);

  return (
    <div className="final-result-background">
      <div className="final-result-container">
        <div className="centered-box">
          <h1 className="final-result-title">Geweldig gedaan, avonturier!</h1>
          <p className="final-result-text">
            Je hebt je tocht door de natuur volbracht en je rugzak gevuld met <strong>{progress?.totalScore} punten</strong>! Wat een indrukwekkende prestatie. We hopen dat je hebt genoten van de rustplaatsen en de uitdagingen die je onderweg bent tegengekomen. Geniet nu van de rust in de Cabin op deze prachtige omgeving!
            <br></br><br></br>
            Nu je aan het eind van de reis bent, is het tijd om te kijken wat je hebt bereikt. <strong>Tien gelukkige deelnemers</strong> maken kans op een <strong>gratis overnachting in een Cabin midden in de natuur</strong>, maar dat is nog niet alles. Vergeet niet dat <strong>iedere deelnemer</strong> sowieso <strong>10% korting krijgt op een overnachting in deze unieke Cabins</strong>. Het avontuur houdt hier dus nog niet op!
            <br></br><br></br>
            Daarnaast nodigen we je uit om de <strong>webshop</strong> te bezoeken. Daar vind je niet alleen duurzame producten zoals de <strong>Xtorm 20W solar charger</strong> en het <strong>bijenhotel</strong>, maar nog veel meer! Ga snel kijken en ontdek wat er allemaal te verkrijgen is.
            <br></br><br></br>
            We willen je hartelijk bedanken voor het spelen en geniet van de prachtige producten!
            <br></br><br></br>
            JOUW KORTINGSCODE VOOR 10% korting bij Cabiner:
            <br></br>
            <strong>GAMExNATUURxCABINER10</strong>
            <br></br>
            <button className="webshop-btn">Naar de webshop</button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default FinalResult;
