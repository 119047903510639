import React, {Component} from 'react'
import axios from 'axios'
import {TcmsContext} from '../../contexts/TcmsContext'

import PageLoading from "../../components/webshop/PageLoading";

const API = process.env.REACT_APP_MTG_BACKEND + '/api'

class MtgWinkelModus extends Component {

  static contextType = TcmsContext

  render() {
    return <PageLoading/>
  }

  constructor(props) {
    super(props);
    // We can have an URL like so /mtg-login/#/slug/hash
    const hash = document.location.hash.split('/');

    if (hash.length > 1) {
      const id = hash[1];
      const token = hash[2];

      // Now check if the token is valid
      axios.post(API + '/tcms/get-shop-modus-token', {id}, {headers:{
          Authorization: 'Bearer ' + token
        }}).then(res => {
        // Set the ShopModus token (not the oAuth token! that one is not saved in shop modus)
        localStorage.setItem('shopModusToken',res.data.token);
        // Set the context
        this.context.setShopModus(true);
        // Jump customer to login page (should show .. winkel modus) ..  Must be hard load
        document.location.href = '/'
      }).catch((e)=>{
        alert('Unauthorized')
      })
    }
  }
}

export default MtgWinkelModus
