import React, {useEffect, useState} from 'react';
import moment from 'moment';

export default function Countdown({timeTillDate, timeFormat, afterCountdown}) {
    const then = moment(timeTillDate, timeFormat);
    // const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    const SVGCircle = ({ radius }) => (
        <svg className="countdown-svg">
            <path
                fill="none"
                stroke="#333"
                strokeWidth="4"
                d={describeArc(50, 50, 48, 0, radius)}
            />
        </svg>
    );

    useEffect(() => {
        const timer = setInterval(() => {
            let now = moment()
            let diff = then.diff(now, 'seconds')
            if(diff < 0) {
                afterCountdown()
                clearInterval(timer)
            }

            now = moment().add(1, 'hour')
            let countdown = moment(then - now)
            // setDays(countdown.format('D'))
            setHours(countdown.format('HH'))
            setMinutes(countdown.format('mm'))
            setSeconds(countdown.format('ss'))


        }, 1000);
        // clearing interval
        return () => clearInterval(timer);
    },[afterCountdown, then]);

    // From StackOverflow: https://stackoverflow.com/questions/5736398/how-to-calculate-the-svg-path-for-an-arc-of-a-circle
    function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
        var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

        return {
            x: centerX + radius * Math.cos(angleInRadians),
            y: centerY + radius * Math.sin(angleInRadians)
        };
    }

    function describeArc(x, y, radius, startAngle, endAngle) {
        var start = polarToCartesian(x, y, radius, endAngle);
        var end = polarToCartesian(x, y, radius, startAngle);

        var largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

        var d = [
            'M',
            start.x,
            start.y,
            'A',
            radius,
            radius,
            0,
            largeArcFlag,
            0,
            end.x,
            end.y
        ].join(' ');

        return d;
    }

    // From StackOverflow: https://stackoverflow.com/questions/10756313/javascript-jquery-map-a-range-of-numbers-to-another-range-of-numbers
    function mapNumber(number, in_min, in_max, out_min, out_max) {
        return (
            ((number - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
        );
    }

    return (
        <>
            <div className="countdown-wrapper">
                {/*    {days && (*/}
                {/*        <div className="countdown-item">*/}
                {/*            <SVGCircle radius={mapNumber(days, 30, 0, 0, 360)} />*/}
                {/*            {days}*/}
                {/*            <span>dag</span>*/}
                {/*        </div>*/}
                {/*    )}*/}
                {hours && (
                    <div className="countdown-item">
                        <SVGCircle radius={mapNumber(hours, 24, 0, 0, 360)} />
                        {hours}
                        <span>uren</span>
                    </div>
                )}
                {minutes && (
                    <div className="countdown-item">
                        <SVGCircle radius={mapNumber(minutes, 60, 0, 0, 360)} />
                        {minutes}
                        <span>minuten</span>
                    </div>
                )}
                {seconds && (
                    <div className="countdown-item">
                        <SVGCircle radius={mapNumber(seconds, 60, 0, 0, 360)} />
                        {seconds}
                        <span>seconden</span>
                    </div>
                )}
            </div>
        </>
    );
}
