import React, { useContext, useState } from 'react';
import Menu from "../../../components/webshop/menu/Menu";
import Banner from "../../../components/webshop/banner/Banner";
import { TcmsContext } from '../../../contexts/TcmsContext';
import Tcms from '../../../tcms/Tcms';
import TcmsH1 from "../../../tcms/Tags/TcmsH1";
import TcmsWYSIWYG from "../../../tcms/Tags/TcmsWYSIWYG";
import Background from '../../../components/webshop/background/Background';
import { Accordion, AccordionTab } from 'primereact/accordion';
import Footer from '../../../components/webshop/footer/Footer';

export default function Info() {
  const [activeIndex, setActiveIndex] = useState(false);
  const context = useContext(TcmsContext)

  const [catActiveIndex, setCatActiveIndex] = useState({0:false,1:false,2:false,3:false,4:false});

  const faq_cats = ['fq_cat_1','fq_cat_2','fq_cat_3','fq_cat_4','fq_cat_5'];
  const tabs = ['header_1','header_2','header_3','header_4','header_5','header_6','header_7','header_8','header_9', 'header_10'];

  const checkDisabled = (value) => {
    if (value.includes("header_")) {
      return false;
    } else {
      return true;
    }
  }

  const checkCategoryVisibility = (value) => {
    if (value.includes("fq_cat")) {
      return false;
    } else {
      return true;
    }
  }

  return (
    <React.Fragment>
      <Menu/>
      <Banner/>
      <div className='page-wrapper'>
        <Background/>
        <div className="faq_container container__large content-page page-info main-view">
          <div className="p-grid">
            <div className="p-col-12 text-center">
              <TcmsH1>Extra_info</TcmsH1>
            </div>
            <div className="p-col-12">
              {context.editMode &&
                  <div className="p-grid">
                    {faq_cats.map((cat, cat_index) => (
                      <div className="p-col-6">
                        <div className="tcms-edit-wrapper">
                          <div className="faq-tab" key={cat_index}>
                            <div className="label">
                              Category: <Tcms>{cat}</Tcms>
                            </div>
                            <div className="value">
                              <div className="faq-cat-headers">
                                {tabs.map((tab, i) => (
                                  <div className="tab-header" key={i}>
                                    <Tcms>{'cat_'+cat_index+'_'+tab}</Tcms>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
              }
              
              {faq_cats.map((cat, cat_index) => {
                 if (checkCategoryVisibility(Tcms(cat))) {
                    return (
                      <div className="faq_category" key={cat_index}>
                        <h4><Tcms>{cat}</Tcms></h4>
                        <Accordion activeIndex={catActiveIndex[cat_index]} data-index={activeIndex} expandIcon="pi pi-plus" collapseIcon="pi pi-minus" onTabChange={(e) => setCatActiveIndex({[cat_index]: e.index})}>
                          {tabs.map((tab, i) => {
                            if (checkDisabled(Tcms('cat_'+cat_index+'_'+tab))) {
                              return (
                                <AccordionTab key={i} header={Tcms('cat_'+cat_index+'_'+tab)}>
                                  <TcmsWYSIWYG>{`tab_content_cat${cat_index}_${i + 1}`}</TcmsWYSIWYG> 
                                </AccordionTab>
                              );
                            }
                          })}
                        </Accordion>
                      </div>
                    )
                 }
              })}
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </React.Fragment>
  )
}
