import React, {Component} from 'react'
import {Button} from 'primereact/button';
import {Dialog} from "primereact/dialog";

import axios from "axios";

let imgStyle = {
  width: '35px',
  height: '35px',
  margin: '0px 6px 0px 6px',
  border: 'solid 2px',
  borderRadius: '5px',
  objectFit: 'cover',
  cursor: 'pointer'
}
let activeImgStyle = {
  display: 'inline-block',
  width: '250px',
  height: '250px',
  margin: '5px',
  border: '1px solid #a6a6a6',
  borderRadius: '25px',
  objectFit: 'scale-down',
}


let API = process.env.REACT_APP_MTG_BACKEND + '/api/be'
let STORAGE = process.env.REACT_APP_MTG_BACKEND + '/storage'

class TcmsDialogPickImage extends Component {

  render() {
    return <Dialog
      header={this.state.header}
      visible={this.state.visible}
      style={{width: '440px'}}
      position={this.state.position}
      modal={true}
      onHide={() => {
        if (this.state.visible) {
          this.close()
        }
      }}
    >
      <div className="p-grid">

        <div className="p-col-12">
          {/*          */}
          {/*ALL IMAGES*/}
          {/*          */}
          {this.state.partner_images.map((imageName) => {
            switch (imageName.split('.').pop()) {
              case 'mp4':
                return <video
                  className="dialog_pick_image_thumbnails"
                  key={imageName}
                  onClick={e => {
                    this.imgClicked(e)
                  }}
                  style={{...imgStyle, borderColor: '#ff6b30'}}>
                  <source
                    src={STORAGE + '/' + imageName}
                    type="video/mp4"/>
                </video>
              default:
                return <img key={imageName}
                            className="dialog_pick_image_thumbnails"
                            alt=""
                            style={imgStyle}
                            src={STORAGE + '/' + imageName}
                            onClick={e => {
                              this.imgClicked(e)
                            }}/>
            }
          })}
        </div>
        <div className="p-col-8">
          {/*     */}
          {/* IMG */}
          {/*     */}
          {this.state.active_type === 'IMG' &&
          <img style={activeImgStyle}
               alt=""
               src={this.state.active_image}/>}

          {/* VIDEO */}
          {this.state.active_type === 'VIDEO' &&
          <video style={activeImgStyle} controls>
            <source src={this.state.active_image} type="video/mp4"/>
          </video>}
        </div>
        <div className="p-col-4">
          <div>Width: <span style={{float: 'right'}}>{this.state.active_width} px</span></div>
          <div>Height: <span style={{float: 'right'}}>{this.state.active_height} px</span></div>
        </div>
        <div className="p-col-4">
          <Button style={{width: '6em'}} label="Use" onClick={() => {
            this.useImage()
          }}/>
        </div>
        <div className="p-col-4">
          <Button style={{width: '6em', float: 'right'}} className="p-button-secondary" label="Cancel" onClick={() => {
            this.cancel()
          }}/>
        </div>
        <div className="p-col-4">
          <Button style={{width: '6em', float: 'right'}} className="p-button-secondary" label="Clear" onClick={() => {
            this.clear()
          }}/>
        </div>

      </div>
    </Dialog>

  }

  state = {
    visible: false,
    header: '',
    position: 'top',
    partner_images: [],
    active_image: '',
    active_type: 'IMG',
    active_height: '',
    active_width: ''
  }

  callback = null

  cancel() {

    this.setState({visible: false})
    if (typeof this.callback === 'function') {
      this.callback(false)
    }

  }
  clear(){
    this.callback('')
    this.close()

  }
  useImage() {
    // Fanyc code to ge the last two entries of the path (https://a/b/c/d/e.jpg => d/e.jpg)
    let file = this.state.active_image//.split('/').slice(-4).join('/')
    this.callback(file)
    this.close()

  }

  imgClicked(e) {

    let src, height, width
    switch (e.target.tagName) {
      case 'VIDEO':
        src = e.target.firstChild.src
        height = e.target.videoHeight
        width = e.target.videoWidth
        break;
      case 'IMG':

        src = e.target.src
        height = e.target.naturalHeight
        width = e.target.naturalWidth
        break;
      default: //
    }

    this.setState({
      active_type: e.target.tagName,
      active_image: src,
      active_height: height,
      active_width: width
    })


  }

  close() {
    this.setState({visible: false})
  }

  open(callback) {

    this.callback = callback

    this.getPartnerImages(() => {
      if (this.state.partner_images.length) {
        setTimeout(
          () => { // Need new thread
            document.getElementsByClassName('dialog_pick_image_thumbnails')[0].click()
          }, 0);
      }
    })
    this.setState({
      visible: true,
    })
  }

  getPartnerImages(allLoaded) {
    axios.get(API + '/tinycms-get-partner-images', {params: {webshop_id: this.props.webshop_id}}).then(res => {


      let files = res.data.filter(el => {
        if (this.props.type === 'VIDEO') {
          if (el.split('.').pop() === 'mp4')
            return true
        } else {
          if (el.split('.').pop() !== 'mp4')
            return true
        }
        return false
      })

      this.setState({
        partner_images: files
      }, () => allLoaded())
    })
  }

}

export default TcmsDialogPickImage
