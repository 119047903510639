import React, {forwardRef, useImperativeHandle, useState} from "react";
import {Dialog} from "primereact/dialog";


import Tcms from "../../../tcms/Tcms";
import TcmsImg from "../../../tcms/Tags/TcmsImg";
import MyOrders from "../../webshop/MyOrders";


const VirtualDialogOrder = forwardRef((props, ref) => {

  const [visible, setVisible] = useState()

  useImperativeHandle(ref, () => ({
    open() {
      setVisible(true)
    }
  }));

  return <>
    <Dialog  header={
      <div>
        <TcmsImg className="dialog-header-img img-left" src="/media/images/christmas_branch_l.png"></TcmsImg> 
        <Tcms>Uw Order</Tcms>
        <TcmsImg className="dialog-header-img img-right" src="/media/images/christmas_branch_r.png"></TcmsImg> 
      </div>
    }
            className="vm-dialog-order"
            maximizable={false}
            maximized={false}
            style={{width: '800px', height: '80%'}}
            visible={visible}
            modal={true}
            onHide={() => setVisible(false)}>
      <div>
        <div className="p-grid">
          <div className="p-col">
            <MyOrders/>
          </div>
        </div>

      </div>
    </Dialog>
  </>

})

export default VirtualDialogOrder
