import TcmsImg from "../../../tcms/Tags/TcmsImg";
import {Badge} from "primereact/badge";
import React, {useContext, useRef} from "react";
import SidebarCart from "./SidebarCart";
import {TcmsContext} from "../../../contexts/TcmsContext";
import TcmsSpan from "../../../tcms/Tags/TcmsSpan";

const Basket = () => {
  const context = useContext(TcmsContext)
  const refSidebarCart = useRef()

  return <>

    <SidebarCart ref={refSidebarCart} />

    {context.customHeader ?
        <a className="cart-button" href="/" onClick={(e) => {
          e.preventDefault()
          refSidebarCart.current.open()
        }}>
          <TcmsImg className="basket-img"
                  src="/media/icons/shopping-bag.svg"
                  alt="Mijn winkelmand"
          />
          <Badge className="nav-top__counter" value={context.getTotalCartItems()} severity="success"/>
          <TcmsSpan className="text">Winkelmand</TcmsSpan>
        </a>
      :
        <a href="/" onClick={(e) => {
          e.preventDefault()
          refSidebarCart.current.open()
        }}>
          <TcmsImg className="basket"
                  src="/media/icons/shopping-bag.svg"
                  alt="Mijn winkelmand"
          />
          <Badge className="nav-top__counter" value={context.getTotalCartItems()} severity="success"/>
          <TcmsSpan className="text">Winkelmand</TcmsSpan>
        </a>
    }


  </>
}
export default Basket
