import React, {useEffect, useState} from "react";
import Countdown from "./countdown/Countdown";
import {Dialog} from "primereact/dialog";
import moment from "moment";
import Tcms from "../../tcms/Tcms";


const VirtualMarketPubquizCountdown = ({pubquiz}) => {
    const [visible, setVisible] = useState(true)
    const [state, setState] = useState(pubquiz?.state)
    const timeTillDate = pubquiz?.data.start_datetime
    const timeFormat = "YYYY-MM-DD hh:mm:ss"
    const then = moment(timeTillDate, timeFormat);
    const now = moment();
    const countdown = moment(then - now);
    const days = countdown.format('D');
    const hours = countdown.format('HH');


    useEffect(() => {
        // only show 12 hours ahead...
        if(days > 1 || hours > 12) {
            setState('')
        }

    },[days, hours])
    const afterCountdown = () => {
        setState('active')
    }

    // return false is we do not have an pubquiz
    if(state === '' || state === 'no_pubquiz')
        return false

    return (
        <Dialog
            header={<Tcms>Pubquiz</Tcms>}
            visible={visible}
            modal={false}
            style={{ width: '50vw' }}
            onHide={() => {
                setVisible(false)
            }}>
            <Tcms>Intro</Tcms>
            { state === 'coming' ?
                <Countdown
                    timeTillDate={timeTillDate}
                    timeFormat={timeFormat}
                    afterCountdown={() => afterCountdown()} /> :
                <> <Tcms>pubquiz_begonnen</Tcms></> }
        </Dialog>
    )
}

export default VirtualMarketPubquizCountdown
