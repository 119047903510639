import {User, getUser} from '../../components/User'
import TcmsH1 from "../../../tcms/Tags/TcmsH1";

import React, {useEffect, useState} from "react";
import axios from "axios";
import {RadioButton} from 'primereact/radiobutton';
import TcmsWYSIWYG from "../../../tcms/Tags/TcmsWYSIWYG";
import {Card} from "primereact/card";
import TcmsButton from "../../../tcms/Tags/TcmsButton";
import Tcms from "../../../tcms/Tcms";

const BE = process.env.REACT_APP_MTG_BACKEND
const StemmenOpMuziek = () => {
  const [user, setUser] = useState()
  const [edit, setEdit] = useState()
  const [songs, setSongs] = useState([])
  const [result, setResult] = useState([])
  const [yourVote, setYourVote] = useState('')
  const [activeSong, setActiveSong] = useState({id: 0})

  useEffect(() => {
    axios.get(BE + '/api/webshop/widgets/music').then(res => {

      switch (res.data.type) {
        case 'songs':
          setSongs(res.data.songs)
          break;
        case 'result':
          setResult(res.data.result)
          setYourVote("'" + res.data.title + "' - " + res.data.artist)
          break;
        default:
        //
      }

      getUser().then(res => {
        setUser(res.data)
      });

    })
  }, [])

  const vote = () => {
    axios.post(BE + '/api/webshop/widgets/music', {song: activeSong}).then(res => {
      setSongs([])
      setResult(res.data.result)
      setYourVote("'" + res.data.title + "' - " + res.data.artist)
    })
  }

  const voteHtml = () => {
    return <>
      <TcmsH1 className="title_song">Kies uw favoriete kerstnummer</TcmsH1>
      <TcmsWYSIWYG>_widget_stemmen_op_muziek_boodschap_</TcmsWYSIWYG>

      <Card className="widget-berichten">
        {songs.map(song =>
          <div className="p-field-radiobutton">
            <RadioButton key={song.id}
                         inputId={'song_' + song.id}
                         name="song"
                         value={song.id}
                         checked={activeSong.id === song.id}
                         onChange={(e) => setActiveSong(songs.find(s => s.id === e.value))}/>
            <label htmlFor={'song_' + song.id}
                   dangerouslySetInnerHTML={{__html: `'${song.title}' - ${song.artist}`}}/>

          </div>
        )}
      </Card>
      <TcmsButton disabled={activeSong.id === 0}
                  label="_stemmen_op_muziek_bevestig_"
                  onClick={() => vote()}
      />
    </>
  }

  const resultHtml = () => {
    return <>
      <TcmsH1 className="title_song">Resultaat</TcmsH1>

      <div className="p-grid">
        <div className="p-col-12 text-center">
          <h3 className="result"><Tcms>Je hebt gestemd op</Tcms> <span dangerouslySetInnerHTML={{__html: yourVote}}/></h3>
        </div>
        <div className="p-col-12">
          <h3 className="result_low">Hier onder het resultaat:</h3>
        </div>
      </div>
      {result.map(el => (
        <div className="p-grid box">
          <div className="p-col-2" style={{textAlign:'center'}}>
            {el.total}
          </div>
          <div className="p-col-10">
            <span dangerouslySetInnerHTML={{__html: `'${el.title}' - ${el.artist}`}}></span>
          </div>
        </div>
      ))}
    </>
  }

  if (!user) return null

  const completeUser = !(user.firstname && user.lastname && user.private_email && !edit)

  return <>
    {completeUser
      // When not enough user data available ask user to complete
      ? <User userData={user} onUserChanged={usr => {
        setUser(usr)
        setEdit(false)
      }}/>
      // Below the voting or result part
      : (result.length !== 0 ? resultHtml() : voteHtml())}
  </>
}

export default StemmenOpMuziek
