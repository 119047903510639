import TcmsWYSIWYG from "../../../tcms/Tags/TcmsWYSIWYG";

const Mibo = () => {
    return <>
        <div>
            <div className="p-grid widget">
                <div className="p-col p-sm-12">
                    <TcmsWYSIWYG>mibo widget</TcmsWYSIWYG>
                </div>
            </div>
        </div>

    </>
}

export default Mibo
