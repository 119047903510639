import React, {useContext} from 'react'
import {TcmsContext} from "../contexts/TcmsContext"

const attributesInterestedIn = ['style','children', 'label', 'tooltip', 'placeholder', 'src', 'alt', 'title']

export default function Tcms(props) {

  const tcms = useContext(TcmsContext)

  let tagName
  let translateThis = {}
  let editable = true

  /**
   * Determine the type of object we're getting in to translate
   */
  if( typeof props === 'string') {
    /**
     * Here you translate raw text
     * Tcms('foo')
     */
    tagName = 'string'
    translateThis = props
    editable = false

  } else if (1 === Object.keys(props).length && props.children){
    /**
     * Here you translate raw text
     * <Tcms>foo</Tcms>
     */
    tagName = 'string'
    translateThis = props.children


  } else  {
    /**
     * Translate properties like <TcmsButton label="foo", title="bar"/>
     * Here you will be able to translate foo and bar
     */
    tagName = 'propsOnly'
    translateThis.props = props
  }
  // Get the translations from the context
  const trans = tcms.translate

  // Now alter the properies when we're an object
  let newProps = {}

  if (tagName === 'propsOnly') {

    /**
     * Translate attributes
     */
    for (const [key, val] of Object.entries(translateThis.props)) {

      if (attributesInterestedIn.includes(key) && trans[val]) {
        newProps[key] = (!tcms.editMode && trans[val] === '{{blank}}') ? '' : trans[val]
      } else {
        /**
         * Some deep JS thread React trouble... This works
         */
        if(typeof val === 'object'){
          newProps[key] = Object.assign({},val)
        } else {
          newProps[key] = val
        }
        delete newProps['data-tcms']

      }
    }

    // Have a style BG image
    if(props.style) {
      ['backgroundImage', 'backgroundColor', 'color'].forEach(item => {
        if(props.style[item]) {
          if(trans[props.style[item]]){
            newProps['style'][item] = (!tcms.editMode && trans[props.style[item]] === '{{blank}}') ? '' : trans[props.style[item]]
          } else {
            newProps['style'][item] = props.style[item]
          }
        }
      })
    }

    /**
     * Translate dropdown options (id,name)
     */
    if (props.options) {
      let newOptions = []
      props.options.forEach(item => {
        let newName = trans[item.name] ? trans[item.name] : item.name
        newOptions.push({
          id: item.id,
          name: (!tcms.editMode && newName === '{{blank}}') ? '' : newName
        })
      })
      newProps.options = newOptions
    }

    /**
     * Bind the Tcms Dialog
     */
    if (tcms.editMode && editable) {
      if (props.options) {
        // For options.. onClick does not work (for now)
        newProps.onChange = (e) => {
          if(!e.ctrlKey) {
            tcms.refTcmsDialog.current.open(props)
          } else {
            if(props.onChange){
              props.onChange(e)
            }
          }
        }
      } else {
        // The rest
        newProps.onClick = (e) => {

          if(!e.ctrlKey) {
            tcms.refTcmsDialog.current.open(props)
          } else {
            if(props.onClick) {
              props.onClick(e)
            }
          }
        }
      }
      // fancy classname
      newProps.className = (newProps.className ? newProps.className + ' ' : '') + 'tcms-edit'
    }

    if(props['data-wysiwyg']){
      newProps.dangerouslySetInnerHTML={'__html': newProps.children}
      delete newProps.children
      delete newProps['data-wysiwyg']
    }

    delete newProps['tcms-skip']
    return newProps

  }

  let editableData
  // This must be text //
  let transData = trans[translateThis] ? trans[translateThis] : translateThis

  if (tcms.isAdmin && tcms.editMode && editable) {
    editableData = <span className="tcms-edit"
                         onClick={(e) => {
                           if(!e.ctrlKey) {
                             e.stopPropagation()
                             tcms.refTcmsDialog.current.open(translateThis)
                           }
                         }}>{transData}</span>
  } else {
    editableData = (transData === '{{blank}}' ? '' : transData)
  }

  return editableData

}

