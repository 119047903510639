import React, { useContext, useEffect, useState, useRef } from 'react';
import { TcmsContext } from "../../contexts/TcmsContext";
import { Card } from "primereact/card";
import Tcms from "../../tcms/Tcms";
import { Button } from 'primereact/button';
import axios from "axios";
import DialogOrderMail from './DialogOrderMail';

const styles = {
  orderCard: {
    border: '2px solid #ececec',
    borderRadius: '8px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    marginBottom: '20px',
    backgroundColor: '#fff',
    padding: '15px',
  },
  orderInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 0',
    borderBottom: '1px solid #ececec',
  },
  orderDetails: {
    flex: 1,
    paddingLeft: '15px',
  },
  orderLink: {
    color: '#007BFF',
    textDecoration: 'none',
    fontWeight: 'bold',
    transition: 'color 0.3s',
    backgroundColor: '#e7f1ff',
    padding: '10px 15px',
    borderRadius: '5px',
    cursor: 'pointer',
    textAlign: 'center',
  },
  alert: {
    background: '#ffe7e6',
    border: 'solid #ff5757',
    borderWidth: '0 0 0 6px',
    color: '#ff5757',
    padding: '1.25rem 1.75rem',
    margin: '1rem 0',
    borderRadius: '6px',
    position: 'relative',
  },
  closeBtn: {
    position: 'absolute',
    right: '10px',
    top: '10px',
  },
};

const checkVisibility = (value) => {
  if (value.includes("hidden")) {
    return false;
  } else {
    return true;
  }
}

function MyOrders(props) {
  const context = useContext(TcmsContext);
  const errSrc = '/media/images/placeholders/85x85.png';
  const [activeOrder, setActiveOrder] = useState(99);
  const [userInfo, setUserInfo] = useState([]);
  const refDialogOrderMail = useRef();
  const [trackTraceError, setTrackTraceError] = useState(false);

  const [mailContent, setMailContent] = useState({
    keys: [],
    raw: ''
  });

  const [trackTrace, setTrackTrace] = useState('');
  const BE = process.env.REACT_APP_MTG_BACKEND;

  useEffect(() => {
    axios.get(BE + '/api/webshop/voucher').then(usr => {
      setUserInfo(usr.data);
    });
  }, []);

  const splitMail = (raw) => {
    // Get the keys like [[__this_is_a_key__]]
    let keys = raw.match(/\[\[(.*?)]]/g) // Works in Safari! But have to trim the [[...]] later

    if (keys) {
      // trim the [[...]] and make unique
      keys = keys.map(k => k.substr(2, k.length - 4)).filter((e, i, a) => i === a.indexOf(e))
      return {
        raw,
        keys
      }
    } else {
      return null
    }
  }

  const getEmail = (id) => {
    const endpoint = '/api/webshop/mail/get-customer-mail?wso_id=' + context.jwt.usr.wid + '&order_id=' + id;
    axios.get(BE + endpoint).then(res => {
      const splitted = splitMail(res.data);
      if (splitted) {
        setMailContent(splitted);
      } else {
        setMailContent({ raw: res.data, keys: [] });
      }
      refDialogOrderMail.current.open();
    });
  };

  const getTrackTrace = (id) => {
    const endpoint = '/api/webshop/track-trace?order_id=' + id;
    axios.get(BE + endpoint).then(res => {
      if (res.data.track_trace) {
        window.open(res.data.track_trace, "_blank");
      } else {
        setTrackTraceError('Track & Trace niet gevonden');
      }
    });
  };

  const getOrderLines = (lines) => {
    return lines.map((item, index) => {
      let src = `${process.env.REACT_APP_MTG_BACKEND}/storage/img/odoo-producten/85x85/0_${item.product_id}.jpg`;

      return (
        <div key={index}>
          <div className='p-grid order-line'>
            <div className='p-col-2'>
              <div className="image">
                <img src={src} alt={item.display_name} onError={e => e.target.src = errSrc} />
              </div>
            </div>
            <div className='p-col-10'>
              <div className="info p-grid">
                <div className='p-col-6'>
                  <h4>
                    {item.count} x {item.display_name}
                  </h4>
                  {context.meta.no_price === 0 &&
                    <p>{item.count * item.price_in_eco} <Tcms>{item.count * item.price_in_eco > 1 ? 'punten' : 'punt'}</Tcms></p>
                  }
                  <p>Order: {item.local_order_id}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  const getOrderDate = (orderDate) => {
    const date = new Date(orderDate);
    return <><strong>{date.toLocaleDateString('nl-NL')}</strong></>;
  };

  return !context.orders.length
    ? <Card>
      <Tcms>Er is nog geen order geplaatst</Tcms>
      {context.editMode &&
        <Tcms>track_trace</Tcms>
      }
    </Card>
    : <>
      {context.orders.map((item, index) => {
        return (
          <div key={index} style={styles.orderCard}>
            <div style={styles.orderInfo}>
              <div style={styles.orderDetails}>
                <Tcms>Besteld op</Tcms>: {getOrderDate(item.write_date)}
              </div>
            </div>
            <Card className="order">
              <div className='content'>
                {context.editMode &&
                  <div className='tcms-overlay'>
                    <div className='tcms-edit-wrapper'>
                      <div className='item'>
                        <div className="label">Links</div>
                        <div className="value">
                          <ul>
                            <li>
                              <Tcms>view_order</Tcms>
                            </li>
                            <li>
                              <Tcms>view_track_trace</Tcms>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className='item'>
                        <div className="label">Track trace visible</div>
                        <div className="value">
                          <Tcms>track_trace_visible</Tcms>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                <div className='order-lines'>
                  {getOrderLines(item.orderlines)}
                </div>
                <div className='p-grid p-px-5'>
                  <div className='p-col-12'>
                    <Button onClick={() => getEmail(item.local_id)} label={Tcms('view_order')} className='p-button-text' icon='pi pi-angle-right' />
                    {checkVisibility(Tcms('track_trace_visible')) &&
                      <div className={Tcms('track_trace_visible') + ' track_trace inline-block'}>
                        <Button onClick={() => getTrackTrace(item.local_id)} label={Tcms('view_track_trace')} className={'p-button-text'} icon='pi pi-angle-right' />
                        {trackTraceError &&
                          <div className='alert' style={styles.alert}>
                            {trackTraceError}
                            <a className='close' style={styles.closeBtn} onClick={() => setTrackTraceError(false)}>
                              <i className="pi pi-times"></i>
                            </a>
                          </div>
                        }
                      </div>
                    }
                  </div>
                </div>
              </div>
            </Card>
          </div>
        );
      })}
      <DialogOrderMail ref={refDialogOrderMail}>
        <iframe
          id="mtg-email-iframe"
          title="Email"
          srcDoc={mailContent.raw}
          width="100%"
          height="2000px"
          frameBorder="0"
          allowFullScreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        ></iframe>
      </DialogOrderMail>
    </>;
}

export default MyOrders;
