import React, { useState, useEffect, useContext } from "react";
import VideoPlaceholder from "../video-placeholder/VideoPlaceholder";
import GameIntro from '../game-intro/GameIntro';
import GameResult from '../game-result/GameResult';
import { useHistory } from 'react-router-dom';
import "./GameScreenThree.scss";
import { canAccessGame, saveGameProgress, getGameAvailabilityById } from "../services/gameProgressService";
import { TcmsContext } from '../../../../contexts/TcmsContext';
import jwtDecode from 'jwt-decode';
import { toast } from 'react-hot-toast';
import InfoBox from '../game-infobox/InfoBox';
import gamesTransitionVideo from '../assets/games_transition.mp4';
import pingSound from '../../../../sounds/ping.mp3';
import buzzSound from '../../../../sounds/softbuzz.mp3';

const GameScreenThree = ({ startGame, setStartGame, setIsMusicMuted, setIsIntro }) => {
  const anagrams = [
    { scrambled: 'LATPN', correct: 'PLANT' },
    { scrambled: 'RIREIV', correct: 'RIVIER' },
    { scrambled: 'KWEUVELSON', correct: 'SNEEUWVLOK' },
    { scrambled: 'RGENO', correct: 'GROEN' },
    { scrambled: 'DEKENBIAL', correct: 'EIKENBLAD' },
    { scrambled: 'NADZ', correct: 'ZAND' },
    { scrambled: 'SEBOLME', correct: 'BLOESEM' },
    { scrambled: 'NESTALERNZA', correct: 'ZONNESTRAAL' },
    { scrambled: 'TAERW', correct: 'WATER' },
    { scrambled: 'RTHTEN', correct: 'HERTEN' },
    { scrambled: 'SARG', correct: 'GRAS' },
    { scrambled: 'LAREVWAT', correct: 'WATERVAL' },
    { scrambled: 'GELOV', correct: 'VOGEL' },
    { scrambled: 'GIRTJE', correct: 'TIJGER' },
    { scrambled: 'GEELSEPIZE', correct: 'ZEESPIEGEL' },
    { scrambled: 'SNIVES', correct: 'VISSEN' },
    { scrambled: 'STELGJER', correct: 'GLETSJER' },
    { scrambled: "NONRENDENSOGZ", correct: "ZONSONDERGANG" },
    { scrambled: 'ELMOB', correct: 'BLOEM' },
    { scrambled: 'RDELINV', correct: 'VLINDER' },
    { scrambled: 'BROCHEWATS', correct: 'BOSWACHTER' },
    { scrambled: 'WADU', correct: 'DAUW' },
    { scrambled: 'SAKGREWTUIS', correct: 'STRUIKGEWAS' },
    { scrambled: 'CHWASDU', correct: 'SCHADUW' },
    { scrambled: 'ROTIEFTSARMO', correct: 'ROTSFORMATIE' },
    { scrambled: 'SERAMO', correct: 'MOERAS' },
    { scrambled: "RALELIETWE", correct: "WATERLELIE" },
    { scrambled: 'DELENALV', correct: 'LAVENDEL' },
    { scrambled: 'ENVE', correct: 'VEEN' },
    { scrambled: 'NLGEOV', correct: 'GOLVEN' },
    { scrambled: 'JJWETONS', correct: 'WOESTIJN' },
    { scrambled: 'LEFKRENTAH', correct: 'HERTENKALF' },
    { scrambled: 'NOGEBONRE', correct: 'REGENBOOG' },
    { scrambled: 'MENODBENON', correct: 'DENNENBOOM' },
    { scrambled: 'ZERKTUINOSR', correct: 'ROZENSTRUIK' },
    { scrambled: 'DRANST', correct: 'STRAND' },
    { scrambled: 'ROLIFRAAK', correct: 'KORAALRIF' },
    { scrambled: 'EDWAPELRUTPR', correct: 'WATERDRUPPEL' },
    { scrambled: 'ELNDEARB', correct: 'BLADEREN' },
    { scrambled: 'WEEMU', correct: 'MEEUW' },
    { scrambled: 'DASCHILUNAPND', correct: 'DUINLANDSCHAP' },
    { scrambled: 'NMAA', correct: 'MAAN' },
    { scrambled: 'TEYEMCOSES', correct: 'ECOSYSTEEM' },
    { scrambled: 'IELGVURVU', correct: 'VUURVLIEG' },
    { scrambled: 'ZTELNEEISKE', correct: 'KIEZELSTEEN' },
    { scrambled: 'GIEELELTRIJ', correct: 'TIJGERLELIE' },
    { scrambled: 'RMEWZGESVLO-', correct: 'ZWERMVOGELS' },
    { scrambled: 'ELĲSBRG', correct: 'ĲSBERG' },
    { scrambled: 'ANECAO', correct: 'OCEAAN' },
    { scrambled: 'DAREA', correct: 'AARDE' },
    { scrambled: 'SENOMS', correct: 'MOSSEN' },
    { scrambled: 'INHONG', correct: 'HONING' },
    { scrambled: 'KANKET', correct: 'TAKKEN' },
    { scrambled: 'NEMELOB', correct: 'BLOEMEN' },
    { scrambled: 'VIREIR', correct: 'RIVIER' },
    { scrambled: 'JJVREV', correct: 'VIJVER' },
    { scrambled: 'GENBER', correct: 'BERGEN' },
    { scrambled: 'ZIEKEL', correct: 'KIEZEL' },
    { scrambled: 'UKTRIS', correct: 'STRUIK' }
  ];

  const gameIntroText = {
    title: 'Welkom bij Spel 3: Anagrammen 🔠',
    description: `Na een mooie wandeling rust je uit op een stevige tak🌳. Je gekreukelde routekaart heeft woorden over de natuur gehusseld! Ontcijfer deze anagrammen met de eerste letter als hint en verzamelpunten in je rugzak🎒. Wie weet kies je straks wel de Ecosysteem Erlenmeyer uit de webshop. Veel succes!`,
    instruction: 'Maak je klaar en los de anagrammen op! Klik op de letters in de juiste volgorde om het woord te vormen of skip als je vastzit! 🔄',
    showMedals: false
  };

  const infoBoxContent = {
    title: "OVER SPEL 3",
    goal: "Zet de gehusselde woorden in goede volgorde! Los de anagrammen op!",
    topics: "Natuur",
    inspiration: "Kies dan het Ecosystem Erlenmeyer!"
  };

  const { setJwtForGames } = useContext(TcmsContext);


  const getRandomAnagram = () => {
    return anagrams[Math.floor(Math.random() * anagrams.length)];
  };
  const [currentAnagramIndex, setCurrentAnagramIndex] = useState(0);
  const [skippedAnagrams, setSkippedAnagrams] = useState([]);
  const [currentAnagram, setCurrentAnagram] = useState(getRandomAnagram());
  const [scrambledLetters, setScrambledLetters] = useState([]);
  const [selectedLetters, setSelectedLetters] = useState([]);
  const [correctCount, setCorrectCount] = useState(0);
  const [progress, setProgress] = useState(100);
  const [timeLeft, setTimeLeft] = useState(180);
  const [gameStatus, setGameStatus] = useState('playing');
  const [wrongGuess, setWrongGuess] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [gameFinished, setGameFinished] = useState(false);
  const [showInfoBox, setShowInfoBox] = useState(true);
  const history = useHistory();
  const [usedLetters, setUsedLetters] = useState([]);

  const gameId = 3;
  const [userId, setUserId] = useState('');


  useEffect(() => {
    const storedJwtToken = sessionStorage.getItem('jwt_token');
    if (storedJwtToken) {
      setJwtForGames(storedJwtToken);
      const tokenData = jwtDecode(storedJwtToken);
      console.log('tokenData', tokenData);
      setUserId(tokenData?.usr?.uid);
    }
  }, [setJwtForGames]);

  useEffect(() => {
    const checkGameAccess = async () => {
      try {
        const availabilityData = await getGameAvailabilityById(gameId);
        const isGameEnabled = availabilityData.enabled;
        const userProgressData = await canAccessGame(userId, gameId);
      } catch (error) {
        console.error('Error checking game access:', error.message);
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      checkGameAccess();
    }
  }, [userId]);

  const beginGame = () => {
    setGameStarted(true);
    setIsIntro(false);
  };

  useEffect(() => {
    if (timeLeft === 0) return;
    setScrambledLetters([...currentAnagram.scrambled.split('')]);
  }, [currentAnagram]);

  useEffect(() => {
    if (timeLeft > 0 && gameStatus === 'playing') {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
        setProgress((timeLeft / 180) * 100);
      }, 1000);
      return () => clearInterval(timer);
    } else if (timeLeft <= 0) {
      setTimeLeft(0);
      setGameStatus('lost');
      setTimeout(() => {
        setGameFinished(true);
      }, 2000);
    }
  }, [timeLeft, gameStatus]);

  const handleLetterClick = (letter, index) => {
    if (
      gameStatus === 'playing' &&
      selectedLetters.length < currentAnagram.correct.length &&
      letter !== '?' &&
      !usedLetters.some(item => item.index === index)
    ) {
      const updatedSelectedLetters = [...selectedLetters, letter];
      const updatedUsedLetters = [...usedLetters, { letter, index }];
      setSelectedLetters(updatedSelectedLetters);
      setUsedLetters(updatedUsedLetters);

      if (updatedSelectedLetters.length === currentAnagram.correct.length) {
        if (updatedSelectedLetters.join('') === currentAnagram.correct) {
          setCorrectCount(correctCount + 1);
          new Audio(pingSound).play();
          setCurrentAnagram(getRandomAnagram());
          setSelectedLetters([]);
          setUsedLetters([]);
        } else {
          setWrongGuess(true);
          new Audio(buzzSound).play();
          setTimeLeft((prevTime) => Math.max(prevTime - 60, 0));
          setCurrentAnagram(getRandomAnagram());
          setSelectedLetters([]);
          setUsedLetters([]);

          setTimeout(() => setWrongGuess(false), 1000);
        }
      }
    }
  };

  const moveToNextWord = () => {
    if (currentAnagramIndex < anagrams.length - 1) {
      setCurrentAnagramIndex(currentAnagramIndex + 1);
      setCurrentAnagram(anagrams[currentAnagramIndex + 1]);
    } else if (skippedAnagrams.length > 0) {
      setCurrentAnagram(skippedAnagrams[0]);
      setSkippedAnagrams(skippedAnagrams.slice(1));
    } else { }
  };

  const handleSkip = () => {
    setSkippedAnagrams([...skippedAnagrams, currentAnagram]);
    setSelectedLetters([]);
    setUsedLetters([]);
    moveToNextWord();
  };

  const handleUndo = () => {
    if (selectedLetters.length > 0) {
      const updatedUsedLetters = usedLetters.slice(0, -1);
      setUsedLetters(updatedUsedLetters);

      const updatedSelectedLetters = selectedLetters.slice(0, -1);
      setSelectedLetters(updatedSelectedLetters);
    }
  };

  const handleFinishGame = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setStartGame(false);
      history.push('/games?game=4');
    }, 7000);
  };

  useEffect(() => {
    if (gameStarted) {
      const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
        toast('Je kunt het spel niet opnieuw spelen als je de pagina verlaat!', {
          position: 'bottom-center',
          duration: 5000,
          style: {
            background: '#facc15',
            color: '#000',
          },
        });

        const saveProgressOnExit = async () => {
          try {
            await saveGameProgress(userId, gameId, correctCount);
          } catch (error) {
            console.error('Error saving progress on exit:', error.message);
          }
        };
        saveProgressOnExit();
      };
      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, [gameStarted, correctCount]);

  const handleCloseInfoBox = () => {
    setShowInfoBox(false);
  };

  if (!gameStarted && startGame) {
    return (
      <>
        <GameIntro {...gameIntroText} onStart={beginGame} setIsMusicMuted={setIsMusicMuted} />
        {showInfoBox && (
          <InfoBox
            {...infoBoxContent}
            onClose={handleCloseInfoBox}
          />
        )}
      </>
    );
  }

  if (loading) {
    return (
      <div className="game-loader">
        <video
          src={gamesTransitionVideo}
          autoPlay
          muted
          onEnded={() => setLoading(false)}
          className="transition-video"
        />
      </div>
    );
  }

  if (gameFinished) {
    const saveProgress = async () => {
      try {
        const response = await saveGameProgress(userId, gameId, correctCount);
      } catch (error) {
        console.error('Error saving progress:', error.message);
      }
    };
    saveProgress();
    return (
      <GameResult
        score={correctCount}
        isThirdGame={true}
        onNextGame={handleFinishGame}
      />
    );
  }

  return (
    <div className={`game-screen ${!startGame ? 'game-screen-video' : ''}`}>
      <div className="highlight-current-game">3</div>

      {!startGame ? (
        <VideoPlaceholder setIsMusicMuted={setIsMusicMuted} />
      ) : (
        <div className="routekaart-content">
          <div className="routekaart-description">
            <h2>Routekaart</h2>
            <p>
              In dit spel krijgt u gedurende 8 minuten gehusselde letters te zien in het centrale thema de natuur waar woorden mee moeten worden gemaakt.
            </p>
            <h3>Voorbeelden</h3>
            <p>
              Woord dat wordt getoond:<br />
              Rendabel &gt; oplossing: Bladeren<br />
              Woord dat wordt getoond:<br />
              Uithoeken &gt; oplossing: Eikenhout
            </p>
            <h3>Tips:</h3>
            <p>Denk aan dingen die je kunt tegenkomen uit de natuur</p>
          </div>
          <div className="routekaart-game">
            <div className="letters-grid">
              {scrambledLetters.map((letter, index) => (
                <div
                  className={`letter-box ${gameStatus === 'lost' || usedLetters.some(item => item.index === index) ? 'disabled' : ''}`}
                  key={index}
                  onClick={() => handleLetterClick(letter, index)}
                >
                  {letter}
                </div>
              ))}
            </div>
            <div className="options-grid">
              {Array.from({ length: currentAnagram.correct.length }).map((_, index) => (
                <div className="option-wrapper" key={index}>
                  <div className="option-box">
                    {selectedLetters[index] || ''}
                  </div>
                </div>
              ))}
            </div>

            <div className={`progress-bar ${wrongGuess ? 'wrong-guess' : ''}`}>
              <div className="progress" style={{ width: `${progress}%` }}></div>
            </div>

            <div className="correct-count">
              <p>
                <span className="checkmark-icon">✔️</span> Correct Guesses: {correctCount}
              </p>
            </div>

            <button className="undo-button" onClick={handleUndo}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="white"
                width="24px"
                height="24px"
              >
                <path d="M12 4V1L7 6l5 5V7c3.31 0 6 2.69 6 6 0 1.3-.42 2.5-1.12 3.46l1.46 1.46C19.46 16.57 20 14.86 20 13c0-4.42-3.58-8-8-8zm-2.88 7.12L7.76 8.76 4.88 5.88 3.46 7.3 7.76 11.6l1.46-1.48z" />
              </svg>
            </button>

            <button className="skip-button" onClick={handleSkip}>
              Skip
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24px" height="24px">
                <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.59 5.59L12 20l8-8-8-8z" />
              </svg>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default GameScreenThree;