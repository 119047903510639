import React, {forwardRef, useContext, useEffect, useImperativeHandle, useState} from "react";
import {Dialog} from "primereact/dialog";
import Tcms from "../../../tcms/Tcms";
import TcmsWYSIWYG from "../../../tcms/Tags/TcmsWYSIWYG";
import TcmsImg from "../../../tcms/Tags/TcmsImg";
import {TcmsContext} from "../../../contexts/TcmsContext";
import {Link, useHistory} from "react-router-dom";
import {FilterContext} from "../../../contexts/FilterContext";
import TcmsButton from "../../../tcms/Tags/TcmsButton";
import TcmsH2 from "../../../tcms/Tags/TcmsH2";


const VirtualDialogVMHelp = forwardRef(({defaultVisible}, ref) => {
  const context = useContext(TcmsContext)
  const history = useHistory()
  const filterContext = useContext(FilterContext)
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    setVisible(defaultVisible)
  }, [defaultVisible])

  useImperativeHandle(ref, () => ({
    open() {
      setVisible(true)
    }
  }));

  return <>
    <Dialog header={
      <div>
        <TcmsImg className="dialog-header-img img-left" src="/media/images/christmas_branch_l.png"></TcmsImg> 
        <Tcms>Virtuele markt help</Tcms>
        <TcmsImg className="dialog-header-img img-right" src="/media/images/christmas_branch_r.png"></TcmsImg> 
      </div>
    }
            className="vm-dialog-info"
            maximizable={false}
            maximized={false}
            style={{width: '800px', height: '80%'}}
            visible={visible}
            modal={true}
            onHide={() => {
              setVisible(false)
              context.setVmShowHelp(false) // Disable popup on page load
            }}>
      <div className="vm-help">
        <div className="p-grid">
          <div className="p-col-12">
            <TcmsImg src="/media/images/default/bag.jpg" className="product-item__img" alt="header"/>
          </div>
          <div className="p-col-12">
            <TcmsWYSIWYG>__virtual_market_dialog_help__</TcmsWYSIWYG>
          </div>
        </div>


        { (context.editMode || context.stringTranslate('_vm_help_sale_banner_butt_') !== '') &&
        <div className="p-grid vm-help-sale-banner">
          <div className="p-col-5">
            <TcmsImg src="/media/images/default/surprise.jpg" className="product-item__img" alt="surprise"/>
          </div>
          <div className="p-col-7">
            <TcmsH2>Bestel direct je surprisepakket</TcmsH2>
            {/*Ga voor de verrassing en bestel hier direct jouw pakket.*/}
            {/*Bestel voor 17.00 uur = morgen verstuurd.*/}
            <TcmsWYSIWYG>_vm_help_sale_banner_text_</TcmsWYSIWYG>
            {/*Bestel hier je pakket*/}
            <TcmsButton label="_vm_help_sale_banner_butt_"
                        style={{marginTop: '2rem'}}
                        onClick={() => {
                          filterContext.setActiveHoofdCatId(18)
                          history.push('/' + context.webshopSlug + '/webshop/verrassingspakketten')
                        }}/>
          </div>
        </div>}

        <div className="p-grid">
          <div className="p-col-3">
            <div className="box-info text-center">
              <div className="img-wrapper">
                <TcmsImg src="/media/images/default/hotspot.png" className="widget__img" alt="item1"/>
              </div>
              <TcmsWYSIWYG>Je kunt op ieder punt in de markt 360-graden rondkijken. Door op de knipperende “hotspots” te klikken open je een nieuw scherm met een kraam of een activiteit.</TcmsWYSIWYG>
            </div>
          </div>
          <div className="p-col-3">
            <div className="box-info">
              <div className="img-wrapper">
                <TcmsImg src="/media/images/default/webshop.png" className="widget__img" alt="item2"/>
              </div>
              <TcmsWYSIWYG>Wil je geen kijkje nemen op de Virtuele Wintermarkt, dan kan je hieronder ook direct naar de webshop.</TcmsWYSIWYG>
            </div>
          </div>
          <div className="p-col-3">
            <div className="box-info">
              <div className="img-wrapper">
                <TcmsImg src="/media/images/default/calendar.png" className="widget__img" alt="item3"/>
              </div>
              <TcmsWYSIWYG>Ps 1 De hele omgeving is ook na je bestelling nog te benaderen tot de einddatum welke op je voucher staat vermeld. Kom dus gerust nog eens terug!</TcmsWYSIWYG>
            </div>
          </div>
          <div className="p-col-3">
            <div className="box-info">
              <div className="img-wrapper">
                <TcmsImg src="/media/images/default/info.png" className="widget__img" alt="item4"/>
              </div>
              <TcmsWYSIWYG>PS 2 Heb je nog vragen over de Virtuele Wintermarkt of over je bestelling klik dan op de button ‘Extra Informatie’ aan de linkerzijde van je scherm.</TcmsWYSIWYG>
            </div>
          </div>
        </div>
        {!context.meta.buttons_off &&
          <div className="p-grid buttons">
            <div className="p-col-6">
              <Link className="p-button p-component btn butt" to={"./webshop"}>
                <img className="menu-icon" alt="" src="/media/icons/webshop.png"/>
                <Tcms>Ga naar de webshop</Tcms>
              </Link>

            </div>
            <div className="p-col-6">
              <button className="p-button p-component btn butt" onClick={(e) => {
                e.preventDefault()
                setVisible(false)
              }}>
                <Tcms>Ga naar de virtuele markt</Tcms>
                <img className="menu-icon ml" alt="" src="/media/images/default/market.png"/>
              </button>
            </div>
          </div>
        }

      </div>
    </Dialog>
  </>

})

export default VirtualDialogVMHelp
