import Tcms from "../Tcms";
import {Button} from "primereact/button";

export default function TcmsButton(props) {


  return (
    <Button {...Tcms(props)}/>
  )

}