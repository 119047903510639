import Tcms from "../Tcms";

export default function TcmsSpan(props) {

  let newProps
  if(Object.keys(props).length === 1){
    newProps = {...props,'data-tcms':true}
  }

  return (
    <span {...Tcms(newProps || props)}></span>
  )

}
