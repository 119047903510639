import {useRef, useEffect} from 'react'
import axios from "axios";
let intervalRescource
let sequence = 0
export default function VirtualMarket({vmName, onPanoClick}) {
  const virtualRef = useRef(null)

  useEffect(() => {

      if (sequence > 1) {
        // This one is run when we come back on this page with the react-router
        // The js files have been attached already so no fancy stuff
        init()
      }
    }, [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const parseParams = (paramsString, params) => {
    paramsString = paramsString.substring(1);
    let firstSeparatorPos = paramsString.indexOf(',');
    if (firstSeparatorPos !== -1) {
      params.startNode = paramsString.slice(0, firstSeparatorPos);
      let viewingParamsString = paramsString.slice(firstSeparatorPos + 1);
      let viewingParams = viewingParamsString.split(',');
      if (viewingParams.length >= 3) {
        let startView = {};
        startView['pan'] = viewingParams[0];
        startView['tilt'] = viewingParams[1];
        startView['fov'] = viewingParams[2];
        if (viewingParams.length >= 4) {
          startView['projection'] = viewingParams[3];
        }
        params.startView = startView;
      }
    } else {
      params.startNode = paramsString;
      params.startView = '';
    }
  }

  const init = () => {

    let params = {};
    parseParams(document.location.hash, params);

    // create the panorama player with the container
    let pano = new window.pano2vrPlayer('virtual_container');
    pano.startNode = params.startNode;
    pano.startView = params.startView;

    // add the skin object
    new window.pano2vrSkin(pano);

    // and now load the config
    axios.get(process.env.REACT_APP_MTG_BACKEND + '/api/webshop/get-virtual-market-xml?skin=' + vmName).then(res => {
      pano.readConfigString(res.data);
    })

    // bind clicks to parent
    pano.on("varchanged_react_action", function(e) {
      onPanoClick(e.value);
      pano.setVariableValue("react_action","noting_to_do");
    })

  }

  /**
   * Attach the javascripts (Run Once)
   */
  if (sequence === 0) {

    ['pano2vr_player', 'parsed'].forEach((id) => {
      sequence = 1
      if (document.getElementById(id) === null) {
        let script = document.createElement('script')
        script.setAttribute('src', process.env.REACT_APP_MTG_PANO+'/storage/pano/' +
          vmName + '/' +
          id + (id !== 'parsed' ? '.js' : '')
        )
        script.setAttribute('id', id)
        document.head.appendChild(script)
        script.onload = () => {
          sequence++
        }
      }
    })

    intervalRescource = setInterval(() => {
      waitForJavascriptLoaded()
    }, 100)

  }

  /**
   * Wait for things
   */
  const waitForJavascriptLoaded = () => {
    // Why 3? ---> 1 = runOnce. 2 and 3 are load pano2vr_player and load skin
    if (virtualRef.current && sequence === 3) {
      // sequence++; // Run once
      clearInterval(intervalRescource)
      init()
    }
  }

  return (
    <div id="virtual_container"
         style={{
           width: '100%',
           height: '100%',
           overflow: 'hidden'
         }} ref={virtualRef}/>
  )
}

