import {useState} from "react";

import TcmsWYSIWYG from "../../../tcms/Tags/TcmsWYSIWYG";
import Tcms from "../../../tcms/Tcms";
import TcmsImg from "../../../tcms/Tags/TcmsImg";
import classNames from "classnames";

const recipes = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'].map(key => {
  return {
    id: key,
    title: 'Recept title ' + key,
    text: 'Recept text ' + key,
    image: '/media/images/default/recept.jpg?key=' + key,
    time: 'tijd ' + key
  }
});

const Kerstrecepten = () => {

  const [receptActive, setReceptActive] = useState(false)
  const [receptTitle, setReceptTitle] = useState(recipes[0].title)
  const [receptText, setReceptText] = useState(recipes[0].text)

  return <>
    <div>
      <div className="p-grid widget">
        <div className="p-col p-sm-12">
          <div className={classNames('recept-window', {'active': receptActive})}>
            <h1><Tcms>{receptTitle}</Tcms></h1>
            <div><TcmsWYSIWYG>{receptText}</TcmsWYSIWYG></div>
          </div>
        </div>
      </div>
      <div className="p-grid recipes">
        {recipes && recipes.map(item =>
          <div key={item.id} className="p-col p-sm-3">
            <div className="recept-tile"
                 onClick={() => {
                   setReceptTitle(item.title)
                   setReceptText(item.text)
                   setReceptActive(true)
                 }}>
              <div className="recept-image">
                <TcmsImg src={item.image}/>
              </div>
              <div className="recept-attr">
                <span><i className="pi pi-clock"></i> <Tcms>{item.time}</Tcms></span>
              </div>
              <div className="recept-title">
                <h3><Tcms>{item.title}</Tcms></h3>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>


  </>
}

export default Kerstrecepten
