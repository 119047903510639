import {Component} from 'react'
import axios from 'axios'

import {TcmsContext} from "../contexts/TcmsContext";

// Set backend path
axios.defaults.baseURL = process.env.REACT_APP_MTG_BACKEND
// Add a response interceptor
axios.interceptors.response.use(function (response) {
  // Do something with response data
  return response
}, function (error) {
  // Do something with response error 403 (No access)
  if (error.response.status === 403) {
    // Should end up here when we have an api token but its wrong
    // Got a 403 back from server
    // window.location.href = '/'
  }
  return Promise.reject(error);
});

class Auth extends Component {
  static contextType = TcmsContext

  pageType = 'public'
  componentDidMount() {

    let jwt_token = sessionStorage.getItem('jwt_token');
    let access_token = sessionStorage.getItem('access_token');
    let shop_modus = localStorage.getItem('shopModusToken');

    // Sgop modus
    if(shop_modus) {
      this.context.setShopModus(shop_modus)
    }

    // oAauth
    if(access_token){
      this.context.setIsAdmin(true)
    }

    // JWT
    if (jwt_token) {
      this.context.login(jwt_token)
    } else {
      // Get info from BE
      axios.get(process.env.REACT_APP_MTG_BACKEND + '/api/webshop/partner-data', {
        params: {
          slug: this.props.match.params.webshopslug
        }
      }).then(r => {

        this.context.setWebshopId(r.data.wso_id)
        this.context.setWebshopSlug(r.data.slug)
        this.context.setThemeId(r.data.theme_id)
        this.context.setFontFamilyLink(()=>{
          return r.data.font_family_link
        })

        this.context.setFontFamilyValue(()=>{
          return r.data.font_family
        })

      })
    }
  }

  constructor(props) {

    super(props);

    let jwt_token = sessionStorage.getItem('jwt_token');
    let access_token = sessionStorage.getItem('access_token');

    if(access_token){
      // Set oAuth header when we have one
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
    }

    if (!jwt_token) {
      // When no token redirect to login page
      let loginPath = '/' + document.location.pathname.split('/')[1];

      if (document.location.pathname !== loginPath) {
        if(!props){
            document.location.href = '/'
        }

        this.props.history.push(loginPath)
      }
    } else {
      // When OK we set the token in session storage
      axios.defaults.headers.common['MtgJwt'] = jwt_token;
    }

  }
}

export default Auth;
