import React from 'react';

const completeList = ["ACCEPTATIE", "GELIJKHEID", "RESPECT", "DIVERSITEIT", "INCLUSIE", "SAMENWERKING", "EMPATHIE"];

function getRandomWords() {
    let lst = [];
    for (var i = 0; i < 5; i++) {
        let currItem = completeList[Math.floor(Math.random() * completeList.length)];
        if (lst.indexOf(currItem) === -1) {
            lst.push(currItem)
        } else {
            i--;
        }
    }
    return lst.sort((a, b) => { return b.length - a.length });
}
function WSGenerator(wordList, directions) {
    directions = [-4, -3, -1, 3, 4];
    var clen = completeList.length;
    this.gridSize = 1;
    this.gridArr = [];
    this.directions = (directions && directions.length) ? directions : [-4, -3, -1, 3, 4];
    this.wordList = (wordList && wordList.length) ? wordList : getRandomWords();
    this.wordList = this.wordList.map((item, i) => {
        return { text: item, index: i, found: false };
    })
    this.alreadyFound = [];
    this.startBox = null;
    this.endBox = null;
}

WSGenerator.prototype.setGridSize = function () {
    // Fixed grid size is already set to 13; no dynamic calculation needed
    this.gridSize = 13;
};

WSGenerator.prototype.getRandomRow = function () {
    return Math.floor(Math.random() * this.gridSize);
}

WSGenerator.prototype.getRandomColumn = function () {
    return Math.floor(Math.random() * this.gridSize);
}

WSGenerator.prototype.getRandomDirection = function () {
    return this.directions[Math.floor(Math.random() * this.directions.length)];
}

WSGenerator.prototype.initGrid = function () {
    let grid = [];
    for (let i = 0; i < this.gridSize; i++) {
        grid[i] = [];
        for (let j = 0; j < this.gridSize; j++) {
            grid[i][j] = "$$";
        }
    }
    this.gridArr = grid.slice();
    for (var i = 0; i < this.wordList.length; i++) {
        this.populateWord(this.wordList[i].text);
    }
    this.populateUnusedBoxes();
};


WSGenerator.prototype.initGrid = function () {
    let grid = [];
    for (let i = 0; i < this.gridSize; i++) {
        grid[i] = [];
        for (let j = 0; j < this.gridSize; j++) {
            grid[i][j] = "$$";
        }
    }
    this.gridArr = grid.slice();
    for (var i = 0; i < this.wordList.length; i++) {
        this.populateWord(this.wordList[i].text);
    }
    this.populateUnusedBoxes();
}

WSGenerator.prototype.isPlacable = function (word, start, end, direction, increment) {
    let i = 0, wordLength = word.length;
    let currI = start.x, currJ = start.y;
    while (currI >= 0 && currI < this.gridSize && currJ >= 0 && currJ < this.gridSize && i < wordLength && (this.gridArr[currI][currJ] === word[i] || this.gridArr[currI][currJ] === "$$")) {
        i++;
        switch (direction) {
            case -1: {
                currJ = currJ - 1;
                break;
            }
            case 1: {
                currJ++;
                break;
            }
            case -2: {
                currI--;
                break;
            }
            case 2: {
                currI++;
                break;
            }
            case 3: {
                currI++;
                currJ++;
                break;
            }
            case -3: {
                currI--;
                currJ--;
                break;
            }
            case 4: {
                currI++;
                currJ--;
                break;
            }
            case -4: {
                currI--;
                currJ++;
                break;
            }
            default: {

            }
        }
    }
    return i === wordLength;
}

WSGenerator.prototype.placeWord = function (word, start, end, direction, increment) {
    let i = 0,
        wordLength = word.length;
    let currI = start.x, currJ = start.y;
    while (i < wordLength) {
        this.gridArr[currI][currJ] = { letter: word[i], id: (currI + 1) + "-cell-" + (currJ + 1), used: false, hilighted: false };
        i++;
        switch (direction) {
            case -1: {
                currJ = currJ - 1;
                break;
            }
            case 1: {
                currJ++;
                break;
            }
            case -2: {
                currI--;
                break;
            }
            case 2: {
                currI++;
                break;
            }
            case 3: {
                currI++;
                currJ++;
                break;
            }
            case -3: {
                currI--;
                currJ--;
                break;
            }
            case 4: {
                currI++;
                currJ--;
                break;
            }
            case -4: {
                currI--;
                currJ++;
                break;
            }
            default: {

            }
        }

    }
}

WSGenerator.prototype.populateWord = function (word, attempt = 0, index = 0) {
    // Limit the number of attempts to avoid infinite recursion
    const MAX_ATTEMPTS = 100;
    if (attempt >= MAX_ATTEMPTS) {
        console.warn(`Could not place word: ${word}`);
        return; // Exit if the maximum number of attempts is reached
    }
    let start = { x: this.getRandomRow(), y: this.getRandomColumn() };
    const diagonalDirections = [3, -3, 4, -4];
    let dir = index % 2 === 1 ?
        diagonalDirections[Math.floor(Math.random() * diagonalDirections.length)] :
        this.getRandomDirection();

    if (this.isPlacable(word, start, null, dir, null)) {
        this.placeWord(word.toUpperCase(), start, null, dir, null);
    } else {
        this.populateWord(word, attempt + 1, index);
    }
};

WSGenerator.prototype.populateUnusedBoxes = function () {
    let indexi;
    let indexj;
    for (indexi = 0; indexi < this.gridSize; indexi++) {
        for (indexj = 0; indexj < this.gridSize; indexj++) {
            if (this.gridArr[indexi][indexj] === "$$") {
                this.gridArr[indexi][indexj] = {
                    letter: WSGenerator.prototype.alphabets[Math.floor(Math.random() * 25)],
                    id: (indexi + 1) + "-cell-" + (indexj + 1),
                    used: false,
                    hilighted: false
                };
            }
        }
    }
}

WSGenerator.prototype.alphabets = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "R", "T", "U", "V", "W", "X", "Y", "Z"];



// Solver part
WSGenerator.prototype.getDirection = function (startObj, endObj) {
    var dir;
    let stRow = startObj.row,
        stCol = startObj.col,
        curRow = endObj.row,
        curCol = endObj.col;

    if (curRow === stRow && curCol !== stCol) {
        if (stCol < curCol) {
            dir = 1;
        } else {
            dir = -1;
        }
    } else if (curCol === stCol && curRow !== stRow) {
        if (stRow < curRow) {
            dir = 2;
        } else {
            dir = -2;
        }
    } else if (((curCol - stCol) === (curRow - stRow)) || ((stCol - curCol) === (stRow - curRow))) {
        if (stRow < curRow && stCol < curCol) {
            dir = 3;
        } else if (stRow > curRow && stCol > curCol) {
            dir = -3;
        }
    } else if (((stRow - curRow) === (curCol - stCol)) || ((curCol - stCol) === (curRow - curRow))) {
        if (stRow < curRow && stCol > curCol) {
            dir = 4;
        } else if (stRow > curRow && stCol < curCol) {
            dir = -4;
        }
    }
    return dir ? dir : 0;
}

WSGenerator.prototype.getStringBetweenPoints = function (startBox, endBox) {
    var dir;
    dir = this.getDirection(startBox, endBox);
    return this.getStringByRowCol(startBox, endBox, dir);
}

WSGenerator.prototype.getStringByRowCol = function (startBox, endBox, dir) {
    var returnedString = "";
    var cellIds = [];
    let str = startBox.row, stc = startBox.col,
        enr = endBox.row, enc = endBox.col;

    switch (dir) {
        case -1:
            {
                for (let k = stc; k >= enc; k -= 1) {
                    returnedString = returnedString + this.gridArr[str][k].letter;
                    cellIds.push([str, k]);
                }
                break;
            }
        case 1:
            {
                for (let k = stc; k <= enc; k += 1) {
                    returnedString = returnedString + this.gridArr[str][k].letter;
                    cellIds.push([str, k]);
                }
                break;
            }
        case -2:
            {
                for (let k = str; k >= enr; k -= 1) {
                    returnedString = returnedString + this.gridArr[k][stc].letter;
                    cellIds.push([k, stc]);
                }
                break;
            }
        case 2:
            {
                for (let k = str; k <= enr; k += 1) {
                    returnedString = returnedString + this.gridArr[k][stc].letter;
                    cellIds.push([k, stc]);
                }
                break;
            }
        case -3:
            {
                for (let k = str, j = stc; k >= enr, j >= enc; k -= 1, j -= 1) {
                    returnedString = returnedString + this.gridArr[k][j].letter;
                    cellIds.push([k, j]);
                }
                break;
            }
        case 3:
            {
                for (let k = str, j = stc; k <= enr, j <= enc; k += 1, j += 1) {
                    returnedString = returnedString + this.gridArr[k][j].letter;
                    cellIds.push([k, j]);
                }
                break;
            }
        case -4:
            {
                for (let k = str, j = stc; k >= enr, j <= enc; k -= 1, j += 1) {
                    returnedString = returnedString + this.gridArr[k][j].letter;
                    cellIds.push([k, j]);
                }
                break;
            }
        case 4:
            {
                for (let k = str, j = stc; k <= enr, j >= enc; k += 1, j -= 1) {
                    returnedString = returnedString + this.gridArr[k][j].letter;
                    cellIds.push([k, j]);
                }
                break;
            }
        default: {

        }
    }
    return { str: returnedString, ids: cellIds };
};

WSGenerator.prototype.TestString = function (testStr) { // match the found string with the elements of the words
    var str = testStr,
        reverseStr = "",
        matched = false,
        reverseMatched = false,
        matchFound = false,
        reverseMatchFound = false;

    for (let i = 0; i <= str.length; i += 1) {
        reverseStr = str.substring(i, i + 1) + reverseStr;
    }
    matched = this.matchString(str);
    reverseMatched = this.matchString(reverseStr);


    if (matched) {
        matchFound = this.isAlreadyFound(testStr);
    }
    if (reverseMatched) {
        reverseMatchFound = this.isAlreadyFound(reverseStr);
    }

    if (matched && !matchFound) {
        return { found: false, str: testStr, match: true };
    } else if (reverseMatched && !reverseMatchFound) {
        return { found: false, str: reverseStr, match: true };
    } else if (matchFound && reverseMatchFound) {
        return { found: true, match: false };
    } else {
        return { found: false, match: false };
    }
}

WSGenerator.prototype.isAlreadyFound = function (str) {
    var count, found = false;
    for (count = 0; count < this.alreadyFound.length; count++) {
        if (str === this.alreadyFound[count]) {
            found = true;
            break;
        }
    }
    return found;
};


WSGenerator.prototype.matchString = function (str) {
    var matched = false;
    for (let count = 0; count < this.wordList.length; count++) {
        if (str.toUpperCase() === this.wordList[count].text.toUpperCase()) {
            matched = true;
            break;
        }
    }
    return matched;
};

WSGenerator.prototype.getBoxById = function (id) {
    let [row, col] = id.split("-cell-");
    row -= 1; // subtract for 0 based index
    col -= 1; // subtract for 0 based index
    return Object.assign({}, this.gridArr[row][col], { row: row, col: col });
};


//cell.js
class Cell extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hilighted: false
        };

        this.mouseOver = this.mouseOver.bind(this);
        this.mouseOut = this.mouseOut.bind(this);
        this.handleTouchStart = this.handleTouchStart.bind(this);
        this.handleTouchEnd = this.handleTouchEnd.bind(this);
    }
    mouseOver(evt) {
        this.setState({
            hilighted: true
        })
    }


    static getDerivedStateFromProps(props, state) {
        if (state.hilighted && !props.hasSelectionStarted()) {
            return {
                hilighted: false
            }
        }
        return null;
    }


    mouseOut(evt) {
        let id = evt.target.id;
        let startBox = this.props.hasSelectionStarted();
        let hilighted = false;
        if (startBox && startBox.id === id) {
            hilighted = true;
        }
        this.setState({
            hilighted: hilighted
        });
    }

    handleTouchStart(evt) {
        evt.preventDefault();
        this.props.selectionStart(evt);
    }

    handleTouchEnd(evt) {
        evt.preventDefault();
        this.props.selectionEnd(evt);
    }

    render() {
        let cell = this.props.cell;
        let id = cell.id;
        let currClass = "cell" + ((cell.used || this.state.hilighted) ? " hilighted" : "");
        return (
            <div className={currClass}
                id={id}
                onMouseDown={this.props.selectionStart}
                onMouseUp={this.props.selectionEnd}
                onMouseOver={this.mouseOver}
                onMouseOut={this.mouseOut}
                onTouchStart={this.handleTouchStart}
                onTouchEnd={this.handleTouchEnd}
            >
                {cell.letter}
            </div>
        )
    }
}

//row.js
class Row extends React.Component {

    render() {
        let row = this.props.row;
        return (
            <div className="row">
                {row.map((item, i) => {
                    return (<Cell cell={item} key={i} selectionStart={this.props.selectionStart}
                        selectionEnd={this.props.selectionEnd} mouseOver={this.props.mouseOver}
                        hasSelectionStarted={this.props.hasSelectionStarted}
                    />);
                })}
            </div>
        )
    }
}

class Grid extends React.Component {
    constructor(props) {
        super(props);

        this.ws = props.ws;
        this.ws.startBox = null;
        this.ws.endBox = null;

        this.selectionStart = this.selectionStart.bind(this);
        this.selectionEnd = this.selectionEnd.bind(this);
        this.hasSelectionStarted = this.hasSelectionStarted.bind(this);
        this.state = {
            ws: this.ws
        }
    }

    selectionStart(evt) {
        let id = evt.target.id;
        this.ws.startBox = this.ws.getBoxById(id);
        return false;
    }

    selectionEnd(evt) {
        if (this.ws.startBox != null) {
            let id = evt.target.id;
            this.ws.endBox = this.ws.getBoxById(id);
        }
        if (this.ws.startBox && this.ws.endBox) {

            let strObj = this.ws.getStringBetweenPoints(this.ws.startBox, this.ws.endBox);
            let str = strObj.str;
            let obj = this.ws.TestString(str);
            if (obj.match && !obj.found) {
                this.ws.alreadyFound.push(str);
                this.ws.wordList.forEach((item) => {
                    if ((item.text.toLowerCase() === str.toLowerCase()) || (str.split("").reverse().join("").toLowerCase() === item.text.toLowerCase())) {
                        item.found = true;
                        if (this.props.onWordFound) {
                            this.props.onWordFound(item.text);  // Notify parent when a word is found
                        }
                    }
                });
                strObj.ids.forEach((item) => {
                    let [i, j] = item;
                    this.ws.gridArr[i][j].used = true;
                });
            }

            this.ws.startBox = null;
            this.ws.endBox = null;
            this.setState({
                ws: this.ws
            });
            return false;
        }
    }

    hasSelectionStarted() {
        return this.ws.startBox;
    }

    render() {
        // Determine the box size based on screen width
        let boxSize;
        if (window.innerWidth < 480) {
            boxSize = 30;
        } else if (window.innerWidth < 768) {
            // boxSize = 35;
        } else if (window.innerWidth < 968) {
            boxSize = 35;
        } else {
            boxSize = 40;
        }
        // Adjust grid size based on the box size
        let gridStyle = {
            width: (boxSize * this.props.ws.gridSize) + "px",
            borderRadius: "2px"
        };

        let gridArr = this.props.ws.gridArr.slice();
        let wordList = this.props.ws.wordList.slice();
        let toastVisible = this.props.ws.wordList.length === this.props.ws.alreadyFound.length;
        return (
            <div id="root">
                <div className="grid" style={gridStyle}>
                    {gridArr.map((row, i) => {
                        return (<Row row={row} rowIndex={i} key={i}
                            selectionStart={this.selectionStart}
                            selectionEnd={this.selectionEnd} hasSelectionStarted={this.hasSelectionStarted} />);
                    })}
                </div>
                <div id="word-list">
                    {/* <div style={{ display: toastVisible ? "block" : "block" }} className="ws-my-page-success-toast">Wohoooo, You did it</div> */}
                    <ul>
                        {wordList.map((item, i) => {
                            let styleObj = {
                                textDecoration: item.found ? "line-through" : "none"
                            };
                            return (<li key={i} style={styleObj}>{item.text}</li>)
                        })}
                    </ul>

                </div>

            </div>
        );
    }
}

class SuccessToast extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: props.visible,
            message: props.message
        };
        alert(props.visible)
    }

    render() {
        let styleObj = {
            display: this.state.visible ? "block" : "block",
            position: "absolute",
            right: 0,
            bottom: 0
        };
        return <div style={styleObj}>{this.state.message}</div>
    }
}

//wordsearch.js
class WordSearchGame extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.wsGenerator = new WSGenerator();
        this.wsGenerator.setGridSize();
        this.wsGenerator.initGrid();
        this.wsGenerator.populateUnusedBoxes();
    }

    onWordFound(word) {
        if (this.props.onGameEvent) {
            this.props.onGameEvent(`Word found: ${word}`);
        }
    }

    render() {
        return (
            <div id="root-container">
                <Grid ws={this.wsGenerator} onWordFound={this.onWordFound.bind(this)} />
            </div>)
    }
}

export default WordSearchGame;