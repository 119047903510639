import React from 'react';
import './SettingsModal.scss';

function SettingsModal({ show, onClose, onVolumeChange, isMuted, volume, onMuteToggle }) {
  if (!show) {
    return null;
  }

  const handleVolumeChange = (e) => {
    onVolumeChange(e.target.value);
  };

  return (
    <div className="settings-modal-overlay">
      <div className="settings-modal-content">
        <button className="settings-modal-close-button" onClick={onClose}>×</button> {/* Close button */}
        <h2>Settings</h2>
        <div className="settings-modal-item">
          <label>Volume:</label>
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={volume}
            onChange={handleVolumeChange}
          />
          <span>{Math.round(volume * 100)}%</span>
        </div>
        <div className="settings-modal-item">
          <label>Mute:</label>
          <img
            src={isMuted ? "https://img.icons8.com/ios-filled/50/ffffff/mute.png" : "https://img.icons8.com/ios-filled/50/ffffff/low-volume.png"}
            alt="sound icon"
            className="settings-modal-sound-icon"
            onClick={onMuteToggle}
            style={{ cursor: 'pointer' }}
          />
        </div>
        {/* Use close or X ?  */}
        {/* <div className="settings-modal-footer">
          <button onClick={onClose}>Close</button>
        </div> */}
      </div>
    </div>
  );
}

export default SettingsModal;
