import React, {Component} from 'react'

import {Dialog} from "primereact/dialog";
import {Editor} from "primereact/editor";
import {Button} from "primereact/button";
import {RadioButton} from "primereact/radiobutton";
import {InputTextarea} from 'primereact/inputtextarea';

const header = (
  [
    <span className="ql-formats">
    <select className="ql-size">
      <option value="small"></option>
      <option selected></option>
      <option value="large"></option>
      <option value="huge"></option>
    </select>
    </span>,
    <span className="ql-formats">
        <button className="ql-bold"/>
        <button className="ql-italic"/>
        <button className="ql-underline"/>
    </span>,
    <span className="ql-formats">
        <button className="ql-list" value="ordered"/>
        <button className="ql-list" value="bullet"/>
    </span>
  ]
);

class TcmsWysiwygEditor extends Component {


  render() {
    return <Dialog
      header={this.state.header}
      visible={this.state.visible}
      style={{width: '800px'}}
      position={this.state.position}
      modal={true}
      onHide={() => {
        if (this.state.visible) {
          this.close()
        }
      }}
    >
      <div className="p-grid">
        <div className="p-col-6 p-field-radiobutton">
          <RadioButton
            inputId="wysiwyg"
            value="wysiwyg"
            name="modus"
            onChange={(e) => {
              this.setState({
                newHtml: this.state.html,
                modus: e.value
              })
            }}
            checked={this.state.modus === 'wysiwyg'}/>
          <label htmlFor="wysiwyg"> WYSIWYG</label>
        </div>
        <div className="p-col-6 p-field-radiobutton">
          <RadioButton
            inputId="html"
            value="html"
            name="modus"
            onChange={(e) => {
              this.setState({
                html: this.state.newHtml,
                modus: e.value
              })
            }}
            checked={this.state.modus === 'html'}/>
          <label htmlFor="html"> &lt;&gt; HTML</label>
        </div>
      </div>
      <div className="p-grid">

        {this.state.modus === 'wysiwyg' && <div className="p-col-12">
          <Editor style={{height: '320px'}}
                  headerTemplate={header}
                  onLoad={quill => quill.focus()}
                  value={this.state.html}
                  onTextChange={(e) => {
                    this.setState({
                      newHtml: e.htmlValue,
                    })
                  }}/>
        </div>}

        {this.state.modus === 'html' && <div className="p-col-12">
          <InputTextarea
            style={{height: '362px', width: '100%'}}
            value={this.state.html}
            onChange={e => {
              this.setState({
                html: e.target.value,
                newHtml: e.target.value,
              })
            }}
          />
        </div>}


      </div>
      <div className="p-grid">
        <div className="p-col-6">
          <Button label="Cancel" className="p-button-warning" onClick={() => {
            this.close()
          }}/>
        </div>

        <div className="p-col-6">
          <Button label="OK" style={{float: 'right'}} onClick={() => {
            this.callback(this.state.newHtml)
            this.close()
          }}/>
        </div>

      </div>
    </Dialog>

  }

  state = {
    modus: 'wysiwyg',
    visible: false,
    header: '',
    position: 'top',

    html: '',
    newHtml: '',

  }

  callback = () => {
  }

  cancel() {
    this.setState({visible: false})
  }

  clear() {
    this.close()
  }

  close() {
    this.setState({
      visible: false,
      html: '',
      newHtml: '',
      modus: 'wysiwyg',
    })
  }

  open(key, html, callback) {

    this.callback = callback

    this.setState({
      html,
      newHtml:html,
      header: key,
      visible: true,
    })
  }


}

export default TcmsWysiwygEditor
