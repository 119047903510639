import Tcms from "../Tcms";

export default function TcmsDiv(props) {

  // Can not change props (and we have to for the backgroundImage)
  let clone = Object.assign({},props)

  // Get the url out of url('http://mtg.be.nl/storage/img/login-bg.jpg') => /storage/img/login-bg.jpg
  let bgImageMatch
  if(clone.style && props.style.backgroundImage) {
    bgImageMatch = clone.style.backgroundImage.split(/'|"/)

    if (bgImageMatch.length === 3) {
      clone.style.backgroundImage = bgImageMatch[1]
    }
  }

  // Translate the props like this.. backgroundimage shoul be lowecase
  const translatedProps = Tcms(clone)

  // When we had a bg image in the first place wrap it back
  if(bgImageMatch) {
    translatedProps.style.backgroundImage = `url('${translatedProps.style.backgroundImage}')`
  }

  return (
    <div {...translatedProps} />
  )

}