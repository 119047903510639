import React from 'react';
import './InfoBox.scss';

const InfoBox = ({ title, goal, topics, inspiration, onClose }) => {
  return (
    <div className="info-box-overlay">
      <div className="info-box">
        <button
          className="close-button-info-box"
          onClick={onClose}
        >
          ×
        </button>
        <h3>{title}</h3>
        <p><strong>Doel:</strong> {goal}</p>
        <p><strong>Onderwerpen:</strong> {topics}</p>
        <p><strong>Geïnspireerd:</strong> {inspiration}</p>
      </div>
    </div>
  );
};

export default InfoBox;