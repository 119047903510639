import React from 'react';
import './Loader.scss';

const Loader = () => (
  <div className="loader-wrapper">
    <span className="loader"><span className="loader-inner"></span></span>
  </div>
  
);

export default Loader;
