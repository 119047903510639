import React, {useContext} from 'react';
import TcmsSpan from "../../tcms/Tags/TcmsSpan";
import {TcmsContext} from "../../contexts/TcmsContext";
import {useHistory} from "react-router-dom";

const BuyCredit = () => {
    const context = useContext(TcmsContext)
    const {meta} = context
    const history = useHistory()

    return (
        (meta.local_buy_credits || meta.local_buy_credits)
            ?
        <div className="buycreditbutton" onClick={() => history.push(`/${context.webshopSlug}/credits-kopen`)}>
            <span className="icon"><img alt="" src="/media/icons/ideal-logo.svg"/></span><TcmsSpan className="text">buy_credit_teaser</TcmsSpan>
        </div>
            : null
    );
}

export default BuyCredit;