import React, {forwardRef, useImperativeHandle, useState} from "react";
import {Sidebar} from "primereact/sidebar";
import MenuCart from "../cart/MenuCart";

const SidebarCart = forwardRef((props, ref) => {
  const [visible, setVisible] = useState(false)

  useImperativeHandle(ref, () => ({
    open() {
      setVisible(true)
    }
  }));

  return <Sidebar
    style={{width: '30rem', top: '0px'}}
    position="right"
    modal={true}
    visible={visible}
    onHide={() => setVisible(false)}>
    <MenuCart closeCartSidebar={() => setVisible(false)}/>
  </Sidebar>


})

export default SidebarCart
