import axios from "axios";
const BE = process.env.REACT_APP_MTG_BACKEND

/**
 * This should be in the context
 */
const addToCart = (context, product_id, count) => {

  axios.get(BE + '/api/webshop/add-to-cart', {
    params: {id: product_id, count:count}
  }).then(res => {

    // Have to update the jwt_token and the cart
    context.setCart(res.data.cart)
    context.renewJwt(res.data.jwt_token)

  })

}

const removeFromCart = (context, product_id) => {
  axios.get(BE + '/api/webshop/remove-from-cart', {
    params: {id: product_id}
  }).then(res => {
    // Have to update the jwt_token and the cart
    context.setCart(res.data.cart)
    context.renewJwt(res.data.jwt_token)

  })

}

export {addToCart,removeFromCart}
