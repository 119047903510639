import React, { useState, useEffect } from 'react';
import { getGameScores } from '../webshop/games/services/gameProgressService';
import './Leaderboard.scss';

function Leaderboard() {
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [selectedGame, setSelectedGame] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchGameData(selectedGame);
  }, [selectedGame]);

  const fetchGameData = async (gameId) => {
    setIsLoading(true);
    try {
      const data = await getGameScores(gameId);
      setLeaderboardData(data);
    } catch (error) {
      console.error('Error fetching game scores:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getRankIcon = (position) => {
    if (position === 0) return '🏆';
    if (position === 1) return '🥈';
    if (position === 2) return '🥉';
    return <span className="rank-number">{position + 1}</span>;
  };

  const getScoreLabel = () => {
    return 'Score (correct answers)';
  };

  return (
    <div className="leaderboard-background">
      <div className="leaderboard-container">
        <h1 className="leaderboard-title">Leaderboard</h1>
        <div className="game-buttons">
          {[1, 2, 3, 4, 5, 6].map((gameId) => (
            <button
              key={gameId}
              onClick={() => setSelectedGame(gameId)}
              className={selectedGame === gameId ? 'active' : ''}
            >
              Game {gameId}
            </button>
          ))}
        </div>
        {isLoading ? (
          <div className="loader">
          </div>
        ) : (
          <div className="leaderboard-table">
            <div className="leaderboard-header">
              <span>Rank</span>
              <span>User ID</span>
              <span>{getScoreLabel()}</span>
            </div>
            <div className="leaderboard-body">
              {leaderboardData.map((user, index) => (
                <div key={index} className={`leaderboard-row ${index < 3 ? `top-${index + 1}` : ''}`}>
                  <span className="rank">{getRankIcon(index)}</span>
                  <span className="user-id">{user.userId}</span>
                  <span className="score">{user.score}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Leaderboard;