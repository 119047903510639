import React, {Component} from 'react'
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {TcmsContext} from '../contexts/TcmsContext'
import axios from "axios";

import TcmsContentChanger from "./TcmsContentChanger";

const attributesInterestedIn = ['tcms-skip', 'children', 'label', 'tooltip', 'placeholder', 'src', 'alt', 'title']

class TcmsDialog extends Component {
  static contextType = TcmsContext

  render() {

    if (!this.state.lines.length) return null;

    return <React.Fragment>

      <Dialog
        className="tcms-dialog"
        header={`${this.context.meta.wso_name} (${this.context.meta.theme_name})`}
        visible={this.state.visible}
        style={{width: '60rem'}}
        position={'top'}
        modal={true}
        onHide={() => {
          if (this.state.visible) {
            this.close()
          }
        }}>

        {/*Alle te vertalen properties*/}
        <div className="translatables" ref={this.translatables}>
          {this.state.lines.map((rst, i) => {
            return (
              <div key={i}
                   className={`p-grid${i ? '' : ' active'}`}
                   onClick={(e) => this.templateClicked(i, e, rst.type)}>
                <div className="p-col-3">
                  {rst.type}
                </div>
                <div className="p-col-9">
                  {/*Below the template (original) image or values*/}
                  {this.isMedia(rst.type)
                    ? <React.Fragment>
                      <div style={{float: 'left', width: '50%', height: '52px'}}>
                        <img src={rst.template}
                             alt=""
                             style={{width: '250px', height: '50px', objectFit: 'contain'}}
                             onLoad={(e) => this.setTemplateImageWidthAndHeight(e)}
                        />
                      </div>
                      <div style={{float: 'right', width: '50%', height: '52px'}}>
                        <div>Width: {this.state.naturalWidth}px</div>
                        <div>Height: {this.state.naturalHeight}px</div>
                      </div>
                    </React.Fragment>
                    : <textarea value={rst.template}
                                style={{width: '100%'}}
                                disabled={true}/>
                  }
                </div>
              </div>
            )
          })}
        </div>
        <hr/>

        {/*De werk 'area'*/}
        <div className="p-grid">
          <div className="p-col">
            {this.state.data.key}
          </div>
        </div>
        <div className="p-grid">
          <div className="p-col">
            <table className="tcms-translate-values">
              <tbody>
              <tr>
                <th className="legenda"></th>
                <th className="lang">NL</th>
                <th className="lang">EN</th>
              </tr>
              <tr>
                <td>Globaal</td>
                <td>
                  <TcmsContentChanger
                    lang="nl_nl"
                    type="standard"
                    isMedia={this.state.isMedia}
                    isWYSIWYG={this.state.isWYSIWYG}
                    data={this.state.data}
                    onChange={this.textChanged}
                  />
                </td>
                <td>
                  <TcmsContentChanger
                    lang="en_us"
                    type="standard"
                    isMedia={this.state.isMedia}
                    isWYSIWYG={this.state.isWYSIWYG}
                    data={this.state.data}
                    onChange={this.textChanged}
                  />
                </td>
              </tr>
              <tr>
                <td>{this.context.meta.theme_name}</td>
                <td>
                  <TcmsContentChanger
                    lang="nl_nl"
                    type="theme_value"
                    isMedia={this.state.isMedia}
                    isWYSIWYG={this.state.isWYSIWYG}
                    data={this.state.data}
                    onChange={this.textChanged}
                  />
                </td>
                <td>
                  <TcmsContentChanger
                    lang="en_us"
                    type="theme_value"
                    isMedia={this.state.isMedia}
                    isWYSIWYG={this.state.isWYSIWYG}
                    data={this.state.data}
                    onChange={this.textChanged}
                  />
                </td>
              </tr>
              <tr>
                <td className="legenda">{this.context.meta.wso_name}</td>
                <td>
                  <TcmsContentChanger
                    lang="nl_nl"
                    type="customer_value"
                    isMedia={this.state.isMedia}
                    isWYSIWYG={this.state.isWYSIWYG}
                    data={this.state.data}
                    onChange={this.textChanged}
                  />
                </td>
                <td>
                  <TcmsContentChanger
                    lang="en_us"
                    type="customer_value"
                    isMedia={this.state.isMedia}
                    isWYSIWYG={this.state.isWYSIWYG}
                    data={this.state.data}
                    onChange={this.textChanged}
                  />
                </td>
              </tr>

              </tbody>
            </table>
          </div>
        </div>

        <div className="p-grid">
          <div className="p-col-6">
            <Button style={{width: '10em', float: 'left'}}
                    label="OK"
                    onClick={() => {
                      this.save()
                    }}/>
          </div>
          <div className="p-col-6">
            <Button style={{width: '10em', float: 'right'}}
                    className="p-button-secondary p-button-outlined"
                    label="Cancel"
                    onClick={() => {
                      this.close()
                    }}/>

          </div>
        </div>
      </Dialog>
    </React.Fragment>
  }

  state = {
    lines: [],
    currentTemplate: 0,
    visible: false,
    partnerName: '',
    themeName: '',
    data: {},
    isMedia: false, // text, img, video (i guess)
    isWYSIWYG: false,
    naturalWidth: null,
    naturalHeight: null,
  }


  constructor(props) {
    super(props);

    this.textChanged = this.textChanged.bind(this)
    // The template texts at the top of the dialog
    this.translatables = React.createRef()

  }

  setTemplateImageWidthAndHeight(e) {
    this.setState({
      naturalWidth: e.target.naturalWidth,
      naturalHeight: e.target.naturalHeight
    })
  }

  /**
   * Can have the event OR can have some props
   */
  textChanged(eventOrProps) {


    let data = this.state.data;

    if (eventOrProps.props) {
      if(eventOrProps.props.isWYSIWYG){
        data[eventOrProps.props.lang][eventOrProps.props.type] = eventOrProps.newHtml
      } else {
        data[eventOrProps.props.lang][eventOrProps.props.type] = eventOrProps.url
      }
    } else {
      let ta = eventOrProps.target
      data[ta.dataset.lang][ta.dataset.type] = ta.value;
    }


    this.setState({data})
  }

  /**
   *
   * @param i is the index of the clicked translatable
   * @param e is the event itself, so we can manipulate the dom
   */
  templateClicked(i, e, type) {

    for (let item of this.translatables.current.getElementsByClassName('p-grid')) {
      item.className = 'p-grid'
    }
    e.target.closest('.p-grid').classList.add('active')
    this.getTranslatedDataByIndex(i, this.state.lines)

  }

  /**
   *
   * @param index
   * @param lines for speed. Just pass in the this.state.lines next time
   */
  getTranslatedDataByIndex(index, lines) {

    // Now by default the first is selected
    axios.get(process.env.REACT_APP_MTG_BACKEND + '/api/tcms/text-by-key',
      {
        params: {
          key: lines[index].template,
          webshopId: this.context.webshopId,
          themeId: this.context.themeId,
          pageType: this.context.pageType
        }
      }).then((res) => {
      let data = JSON.parse(JSON.stringify(res.data, (i, val) => val === null ? '' : val))

      this.setState({
        visible: true,
        // Remove all null values
        data,
        currentTemplate: index,
        lines,
        isMedia: this.isMedia(lines[index].type),

      })
    })

  }

  isMedia(type) {
    return type === 'src' || type === 'backgroundImage'
  }

  save() {

    axios.post(
      process.env.REACT_APP_MTG_BACKEND + '/api/tcms/text-by-key',
      {
        data: this.state.data,
        webshopId: this.context.webshopId,
        themeId: this.context.themeId,
        pageType: this.context.pageType
      }
    ).then((res) => {
      this.context.reloadWebshopContext()
      this.close()
    })
  }

  close() {
    this.setState({
      visible: false,
      lines: [],
      currentTemplate: 0,
      data: {}
    })

  }

  open(template) {

    this.setState({isWYSIWYG: !!template['data-wysiwyg']})

    // Fisrt make the template array
    let lines = []

    if (typeof template === 'string') {
      // When string we only have one
      lines.push({type: 'text', template})
    } else {

      // Now we're an object, like Button. Get the properties we want to be able to translate
      for (const [key, val] of Object.entries(template)) {
        if (attributesInterestedIn.includes(key) && val) {
          lines.push({type: key, template: val})
        }
      }

      // Select box here.. the options
      if (template.options) {
        for (const [key, val] of Object.entries(template.options)) { // eslint-disable-line no-unused-vars
          lines.push({type: 'option', template: val.name})
        }
      }
      // Style attributes (first so image will end at the top)
      if (template.style) {
        ['backgroundImage', 'backgroundColor', 'color'].forEach(item => {
          if (template.style[item]) {
            lines.push({type: item, template: template.style[item]})
          }
        })
      }

      /**
       * Handle the Tcms skip part
       */
      const tcmsSkip = lines.find(el => el.type === 'tcms-skip')
      if (tcmsSkip) {
        let arr = [...tcmsSkip.template]
        arr.push('tcms-skip') // Remove this one to begin with
        lines = lines.filter(el => arr.includes(el.type) ? false : true)
      }

    }
    this.getTranslatedDataByIndex(0, lines)
  }

}

export default TcmsDialog
