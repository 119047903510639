import React, { useState, useEffect, useContext } from "react";
import VideoPlaceholder from "../video-placeholder/VideoPlaceholder";
import GameIntro from '../game-intro/GameIntro';
import { canAccessGame, saveGameProgress, getGameAvailabilityById } from "../services/gameProgressService";
import "./GameScreenOne.scss";
import { useHistory } from 'react-router-dom';
import { TcmsContext } from '../../../../contexts/TcmsContext';
import jwtDecode from 'jwt-decode';
import { toast } from 'react-hot-toast';
import GameResult from '../game-result/GameResult';
import InfoBox from '../game-infobox/InfoBox';
import gamesTransitionVideo from '../assets/games_transition.mp4';
import animationGif from '../assets/animation.gif';
import pingSound from '../../../../sounds/ping.mp3';
import buzzSound from '../../../../sounds/softbuzz.mp3';


const GameScreenOne = ({ startGame, setStartGame, setIsMusicMuted, setIsIntro }) => {
  const { setJwtForGames } = useContext(TcmsContext);
  const [timeLeft, setTimeLeft] = useState(300);
  const [isQuizStarted, setIsQuizStarted] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [quizTime, setQuizTime] = useState(0);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [isQuizFinished, setIsQuizFinished] = useState(false);
  const [disableOptions, setDisableOptions] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [score, setScore] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showAnimation, setShowAnimation] = useState(false);
  const [showInfoBox, setShowInfoBox] = useState(true);

  const history = useHistory();

  const goToNextGame = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setStartGame(false);
      history.push('/games?game=2');
    }, 7000);
  };

  const beginGame = () => {
    setGameStarted(true);
    stopSpeech();
    setIsIntro(false);
  };

  const speakTextForGameScreenOne = () => {
    const text = `Verhaal over Bob Marley. Bob Marley werd geboren op 6 februari 1945 in het dorpje NineMile, dat ligt in de heuvels van Jamaica. Hij groeide op in een eenvoudig huis, en zijn liefde voor muziek begon al toen hij jong was. 

      Zijn moeder, Cedella, zorgde voor hem nadat zijn vader, een Britse man, het gezin had verlaten. Bob groeide op met de rijke mix van culturen die Jamaica kent, met invloeden uit Afrika en Europa. In de jaren 60 begon Marley zijn muzikale carrière met de groep The Wailers, samen met Peter Tosh en Bunny Wailer. Eerst maakten ze muziek in de stijlen ska en rocksteady, die toen populair waren op Jamaica. Maar Bob vond zijn eigen stijl in de reggae. Deze muziek staat bekend om het langzame ritme en de teksten die vaak gaan over sociale rechtvaardigheid, vrijheid en het verzet tegen onderdrukking. 

      Bekende nummers zoals "One Love" en "Redemption Song" brachten een krachtige boodschap van vrede en gelijkheid over. Bob Marley geloofde ook sterk in de natuur en spiritualiteit. Hij was een aanhanger van de Rastafari-beweging, die draait om liefde voor de natuur en harmonie tussen mensen. Zijn leven en muziek gingen altijd over het belang van eenheid en zorg voor de aarde. 

      Zelfs na zijn dood in 1981 blijft zijn boodschap van liefde, gelijkheid en respect voor de natuur nog steeds belangrijk. Marley’s muziek heeft miljoenen mensen wereldwijd geraakt, en zijn invloed is nog steeds voelbaar, zowel in de muziekwereld als in spirituele bewegingen. Hij wordt nog steeds gezien als een symbool van vrijheid, verzet en liefde. 

      House of Marley is een merk dat is verbonden aan Bob Marley's nalatenschap. Het merk staat voor kwaliteit, duurzaamheid en toewijding aan goede doelen. Ze gebruiken duurzame materialen voor hun producten, en een deel van hun omzet gaat naar projecten zoals OneTreePlanted.org, dat helpt bij het aanplanten van bomen voor een betere toekomst. 

      House of Marley blijft Bob Marley's idealen van duurzaamheid en verantwoordelijkheid in ere houden, met een focus op milieuvriendelijke technologie en bewust consumeren. Het merk biedt consumenten de kans om hun steentje bij te dragen aan een duurzamere wereld.
      `;
    const speech = new SpeechSynthesisUtterance(text);
    speech.lang = 'nl-NL';
    speech.rate = 1;
    speech.pitch = 1;
    window.speechSynthesis.speak(speech);
  };

  function stopSpeech() {
    window.speechSynthesis.cancel();
  }

  const [quizQuestions, setQuizQuestions] = useState([
    {
      question: "Waar ligt NineMile, waar Bob Marley werd geboren?",
      options: ["De kust van Jamaica", "De heuvels van Jamaica ", "De hoofdstad van Jamaica"],
      answer: "De heuvels van Jamaica ",
    },
    {
      question: "Waarom moest Bob Marley's moeder voor hem zorgen?",
      options: ["Zijn vader overleed", "Zijn moeder verliet het gezin", "Zijn vader verliet het gezin"],
      answer: "Zijn vader verliet het gezin",
    },
    {
      question: "Welke muziekstijlen speelde Bob Marley eerst met The Wailers?",
      options: ["Jazz en Reggae", "Ska en Rocksteady", "Mento en Blues"],
      answer: "Ska en Rocksteady",
    },
    {
      question: "Wat kenmerkt reggae volgens de tekst?",
      options: ["Langzaam ritme en rechtvaardigheidsthema’s", "Snel ritme en vrolijke teksten", "Politieke thema’s en zware beats"],
      answer: "Langzaam ritme en rechtvaardigheidsthema’s",
    },
    {
      question: "Welke nummers worden specifiek genoemd?",
      options: ["No Woman, No Cry en Exodus", "Buffalo Soldier en Could You Be Loved", "One Love en Redemption Song"],
      answer: "One Love en Redemption Song",
    },
    {
      question: "Hoe zag Bob Marley de natuur volgens de tekst?",
      options: ["Belangrijk voor zijn inspiratie", "Harmonie tussen mens en natuur", "Minder belangrijk in zijn leven"],
      answer: "Harmonie tussen mens en natuur",
    },
    {
      question: "Welke spirituele beweging beïnvloedde Marley sterk?",
      options: ["Rastafari", "Boeddhisme", "Christendom"],
      answer: "Rastafari",
    },
    {
      question: "Wat blijft belangrijk in Bob Marley's nalatenschap?",
      options: ["Zijn rol in de muziek", "Liefde en gelijkheid", "Zijn politieke invloed"],
      answer: "Liefde en gelijkheid",
    },
    {
      question: "Welke materialen gebruikt House of Marley?",
      options: ["Moderne technologie", "Duurzame materialen", "Lokale Jamaicaanse materialen"],
      answer: "Duurzame materialen",
    },
    {
      question: "Hoe draagt House of Marley bij aan duurzaamheid?",
      options: ["Duurzame landbouw", "Herbebossing via OneTreePlanted.org", "Organische producten"],
      answer: "Herbebossing via OneTreePlanted.org",
    },
  ]);

  const gameIntroText = {
    title: 'De Bob Marley geheugenquiz',
    description: `Na een korte wandeling strijk je neer op een boomstam en geniet je van de rust en stilte om je heen. 
    Met de duurzame koptelefoon van House of Marley 🎧 die je helpt focussen, neem je straks een tekst over Bob Marley 🎤 goed in je op. 
    Hier draait het om onthouden – elk detail telt, want na het lezen wachten vragen om je geheugen op de proef te stellen. 
    Succes!`,
    instruction: `Neem de tijd om de tekst aandachtig te lezen, want elk detail is belangrijk voor de aankomende vragen. 
    Hoe meer details je onthoudt, hoe meer kans je hebt op een hoge score! 🍀`,
    showMedals: false
  };

  const infoBoxContent = {
    title: "OVER SPEL 1",
    goal: "Doel: Onthoud de tekst over Bob Marley en beantwoord zoveel mogelijk vragen goed.",
    topics: "Bob Marley",
    inspiration: "Kies House of Marley in de webshop!"
  };

  const skipReading = () => {
    setIsQuizStarted(true);
    setIsTimerRunning(false);
    stopSpeech();
  };

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  useEffect(() => {
    setQuizQuestions(shuffleArray([...quizQuestions]));
  }, []);

  const [userId, setUserId] = useState('');

  const handleCloseInfoBox = () => {
    setShowInfoBox(false);
  };

  useEffect(() => {
    const storedJwtToken = sessionStorage.getItem('jwt_token');

    if (storedJwtToken) {
      setJwtForGames(storedJwtToken);
      const tokenData = jwtDecode(storedJwtToken);
      console.log('tokenData', tokenData);
      setUserId(tokenData?.usr?.uid);
    }
  }, [setJwtForGames]);
  const gameId = 1;

  // Fetch user details to see if they can access the game
  useEffect(() => {
    const checkGameAccess = async () => {
      try {
        // Fetch game availability (is game globally enabled?)
        const availabilityData = await getGameAvailabilityById(gameId);
        const isGameEnabled = availabilityData.enabled;

        // Fetch user progress (has the user completed this game?)
        const userProgressData = await canAccessGame(userId, gameId);
        // Determine if the game can be played
        // if (isGameEnabled && !isGameCompleted) {
        //   setGameEnabled(true);
        // } else {
        //   setGameEnabled(false);
        // }
      } catch (error) {
        console.error('Error checking game access:', error.message);
      } finally {
        setLoading(false);
      }
    };
    if (userId) {
      checkGameAccess();
    }
  }, [userId]);

  useEffect(() => {
    if (gameStarted && !isQuizStarted) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            setIsQuizStarted(true);
            setIsTimerRunning(true);
            clearInterval(timer);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [gameStarted, isQuizStarted]);

  useEffect(() => {
    let quizTimer;
    if (isTimerRunning) {
      quizTimer = setInterval(() => {
        setQuizTime((prevTime) => prevTime + 1);
      }, 1000);
    }
    return () => clearInterval(quizTimer);
  }, [isTimerRunning]);

  useEffect(() => {
    if (isQuizFinished) {
      const saveProgress = async () => {
        try {
          const response = await saveGameProgress(userId, gameId, score);
        } catch (error) {
          console.error('Error saving progress:', error.message);
        }
      };
      saveProgress();
    }
  }, [isQuizFinished, quizTime]);

  useEffect(() => {
    if (isQuizStarted) {
      const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
        toast('Je kunt het spel niet opnieuw spelen als je de pagina verlaat!', {
          position: 'bottom-center',
          duration: 5000,
          style: {
            background: '#facc15',
            color: '#000',
          },
        });

        const saveProgress = async () => {
          try {
            const response = await saveGameProgress(userId, gameId, score);
          } catch (error) {
            console.error('Error saving progress on exit:', error.message);
          }
        };
        saveProgress();
      };

      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, [isQuizStarted, quizTime]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const handleAnswerClick = (selectedOption) => {
    if (disableOptions) return;

    setSelectedOption(selectedOption);
    setDisableOptions(true);

    const currentQuizQuestion = quizQuestions[currentQuestion];

    if (selectedOption !== currentQuizQuestion.answer) {
      new Audio(buzzSound).play();
      setQuizTime((prevTime) => prevTime + 15);

      const correctButton = document.querySelector(
        `button[data-option="${currentQuizQuestion.answer}"]`
      );
      correctButton.classList.add("correct-answer-hover");

      setTimeout(() => {
        goToNextQuestion();
        correctButton.classList.remove("correct-answer-hover");
      }, 1000);
    } else {
      setScore((prevScore) => prevScore + 1);
      new Audio(pingSound).play();

      setShowAnimation(true);
      setTimeout(() => {
        setShowAnimation(false);
        goToNextQuestion();
      }, 1500);
    }
  };

  const goToNextQuestion = () => {
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < quizQuestions.length) {
      setCurrentQuestion(nextQuestion);
      setDisableOptions(false);
    } else {
      setIsTimerRunning(false);
      setIsQuizFinished(true);
    }
  };

  if (loading) {
    return (
      <div className="game-loader">
        <video
          src={gamesTransitionVideo}
          autoPlay
          muted
          onEnded={() => setLoading(false)}
          className="transition-video"
        />
      </div>
    );
  }


  if (!gameStarted && startGame) {
    return (
      <>
        <GameIntro {...gameIntroText} onStart={beginGame} setIsMusicMuted={setIsMusicMuted}/>
        {showInfoBox && (
          <InfoBox
            {...infoBoxContent}
            onClose={handleCloseInfoBox}
          />
        )}
      </>
    );
  }

  if (isQuizFinished) {
    return <GameResult score={score} totalQuestions={quizQuestions.length} isFirstGame={true} onNextGame={goToNextGame} />;
  }

  return (
    <>
      {loading && (
        <div className="game-loader">
          <video
            src={gamesTransitionVideo}
            autoPlay
            muted
            onEnded={() => {
              setLoading(false);
              setStartGame(false);
              history.push('/games?game=2');
            }}
            className="transition-video"
          />
        </div>
      )}
      {!loading && (
        <div className={`game-screen ${!startGame ? 'game-screen-video' : ''}`} style={{ padding: '20px' }}>
          {!startGame ? (
            <VideoPlaceholder setIsMusicMuted={setIsMusicMuted} />
          ) : (
            <div className="game-content">
              {showAnimation && (
                <img src={animationGif} alt="Confetti Animation" className="confetti-animation" />
              )}
              <div className='highlight-current-game'> 1 </div>
              {!isQuizStarted ? (
                <div className="game-section">
                  <h2>Verhaal over Bob Marley</h2>
                  <div className="reminder">
                    <p>Lees onderstaande tekst goed door, want deze helpt je bij het beantwoorden van de volgende vragen! ⚠️</p>
                  </div>
                  <div className="game-text">
                    <p>
                      Bob Marley werd geboren op 6 februari 1945 in het dorpje NineMile, dat ligt in de heuvels van Jamaica. Hij groeide op in een eenvoudig huis, en zijn liefde voor muziek begon al toen hij jong was.
                    </p>
                    <p>
                      Zijn moeder, Cedella, zorgde voor hem nadat zijn vader, een Britse man, het gezin had verlaten. Bob groeide op met de rijke mix van culturen die Jamaica kent, met invloeden uit Afrika en Europa. In de jaren 60 begon Marley zijn muzikale carrière met de groep The Wailers, samen met Peter Tosh en Bunny Wailer. Eerst maakten ze muziek in de stijlen ska en rocksteady, die toen populair waren op Jamaica. Maar Bob vond zijn eigen stijl in de reggae. Deze muziek staat bekend om het langzame ritme en de teksten die vaak gaan over sociale rechtvaardigheid, vrijheid en het verzet tegen onderdrukking.
                    </p>
                    <p>
                      Bekende nummers zoals "One Love" en "Redemption Song" brachten een krachtige boodschap van vrede en gelijkheid over. Bob Marley geloofde ook sterk in de natuur en spiritualiteit. Hij was een aanhanger van de Rastafari-beweging, die draait om liefde voor de natuur en harmonie tussen mensen. Zijn leven en muziek gingen altijd over het belang van eenheid en zorg voor de aarde.
                    </p>
                    <p>
                      Zelfs na zijn dood in 1981 blijft zijn boodschap van liefde, gelijkheid en respect voor de natuur nog steeds belangrijk. Marley’s muziek heeft miljoenen mensen wereldwijd geraakt, en zijn invloed is nog steeds voelbaar, zowel in de muziekwereld als in spirituele bewegingen. Hij wordt nog steeds gezien als een symbool van vrijheid, verzet en liefde.
                    </p>
                    <p>
                      House of Marley is een merk dat is verbonden aan Bob Marley's nalatenschap. Het merk staat voor kwaliteit, duurzaamheid en toewijding aan goede doelen. Ze gebruiken duurzame materialen voor hun producten, en een deel van hun omzet gaat naar projecten zoals OneTreePlanted.org, dat helpt bij het aanplanten van bomen voor een betere toekomst.
                    </p>
                    <p>
                      House of Marley blijft Bob Marley's idealen van duurzaamheid en verantwoordelijkheid in ere houden, met een focus op milieuvriendelijke technologie en bewust consumeren. Het merk biedt consumenten de kans om hun steentje bij te dragen aan een duurzamere wereld.
                    </p>
                  </div>
                  <div className="timer">
                    <span className="timer-text">Resterende tijd: {formatTime(timeLeft)}</span>
                  </div>
                  <div className="buttons">
                    <button className="skip-text-button" onClick={skipReading}>
                      OVERSLAAN
                    </button>
                    <div className="listen">
                      <button className="tts-button" onClick={speakTextForGameScreenOne}>
                        <img src="/media/icons/listen.svg" alt="Listen Icon" className="tts-icon" />
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="quiz-content">
                  <h1>Quiz: test je kennis</h1>
                  <div className="quiz-question">
                    <div className="question-heading-container">
                      <h2>{quizQuestions[currentQuestion].question}</h2>
                    </div>
                    <div className="quiz-options">
                      {quizQuestions[currentQuestion].options.map((option, index) => (
                        <button
                          key={index}
                          data-option={option}
                          className={`quiz-option 
                      ${selectedOption === option && option === quizQuestions[currentQuestion].answer ? "correct-answer" : ""}
                      ${selectedOption === option && option !== quizQuestions[currentQuestion].answer ? "wrong-answer" : ""}`}
                          onClick={() => handleAnswerClick(option)}
                          disabled={disableOptions}
                        >
                          {option}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              <div className="image-section">
                <img
                  src="https://th.bing.com/th/id/R.fff1cb20acbc28ba2023f7257ba06ce8?rik=b4pIBZBvvlsCaw&pid=ImgRaw&r=0"
                  alt="Bob Marley"
                  className="game-image"
                />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default GameScreenOne;
