import React from "react";
import Menu from "../../../components/webshop/menu/Menu";
import ThankYou from '../../../components/webshop/ThankYou'
import Background from "../../../components/webshop/background/Background";


export default function Thanks(props) {

  return (

    <React.Fragment>

      <Menu/>
      <div className='page-thank-you'>
      <Background/> 
        <div className="container__large content-page main-view p-mt-6">
          <div className="p-mt-6">
            <ThankYou/>
          </div>
        </div>
      </div>
    </React.Fragment>
  )

}



