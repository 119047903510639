import React, { useState, useEffect, useRef, useContext } from "react";
import "./GameScreenFour.scss";
import VideoPlaceholder from "../video-placeholder/VideoPlaceholder";
import GameIntro from '../game-intro/GameIntro';
import GameResult from '../game-result/GameResult';
import { useHistory } from 'react-router-dom';
import { canAccessGame, saveGameProgress, getGameAvailabilityById } from "../services/gameProgressService";
import { TcmsContext } from '../../../../contexts/TcmsContext';
import jwtDecode from 'jwt-decode';
import { toast } from 'react-hot-toast';
import InfoBox from '../game-infobox/InfoBox';
import gamesTransitionVideo from '../assets/games_transition.mp4'; 
import pingSound from '../../../../sounds/ping.mp3';
import buzzSound from '../../../../sounds/softbuzz.mp3';

const GameScreenFour = ({ startGame, setStartGame, setIsMusicMuted, setIsIntro }) => {
  const { setJwtForGames } = useContext(TcmsContext);
  const [progress, setProgress] = useState(100);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [timeLeft, setTimeLeft] = useState(20);
  const [feedback, setFeedback] = useState(false);
  const [beePosition, setBeePosition] = useState(100);
  const [incorrectCount, setIncorrectCount] = useState(0);
  const [correctCount, setCorrectCount] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [shake, setShake] = useState(false);
  const [isGameOver, setIsGameOver] = useState(false);
  const [gameFinished, setGameFinished] = useState(false);
  const [loading, setLoading] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [showInfoBox, setShowInfoBox] = useState(true);
  const history = useHistory();

  const [userId, setUserId] = useState('');


  useEffect(() => {
    const storedJwtToken = sessionStorage.getItem('jwt_token');
    if (storedJwtToken) {
      setJwtForGames(storedJwtToken);
      const tokenData = jwtDecode(storedJwtToken);
      console.log('tokenData', tokenData);
      setUserId(tokenData?.usr?.uid);
    }
  }, [setJwtForGames]);

  const gameId = 4;
  // Check if the user can access the game and if game is available to play
  useEffect(() => {
    const checkGameAccess = async () => {
      try {
        // Fetch game availability (is game globally enabled?)
        const availabilityData = await getGameAvailabilityById(gameId);
        const isGameEnabled = availabilityData.enabled;
        // if (!isGameEnabled) {
        //   alert('Game is disabled');
        // }

        // Fetch user progress (has the user completed this game?)
        const userProgressData = await canAccessGame(userId, gameId);
        // Determine if the game can be played
        // if (isGameEnabled && !isGameCompleted) {
        //   setGameEnabled(true);
        // } else {
        //   setGameEnabled(false);
        // }
      } catch (error) {
        console.error('Error checking game access:', error.message);
      } finally {
        setLoading(false);
      }
    };


    if (userId) {
      checkGameAccess();
    }
  }, [userId]);


  const [isClickable, setIsClickable] = useState(true);
  const timerRef = useRef(null);

  const gameIntroText = {
    title: 'Welkom bij Spel 4: De Bijenachtervolging 🐝',
    description: `Na een avontuurlijke wandeling bereik je de vierde rustplaats,
      maar een groep bijen zoemt nieuwsgierig om je heen🐝. Had je nu maar een bijenhotel bij je om ze rustig binnen te houden!
      Beantwoord vragen over bijen, biodiversiteit en atletiek/sprinten om de bijen op afstand te houden en punten te verzamelen voor je rugzak.
      Veel succes, en laat zien wat je weet!`,
    instruction: `Beantwoord elke vraag correct om de bijen voor te blijven. Een fout antwoord brengt ze dichterbij. Voorkom fouten, houd je score hoog en probeer een speciaal item voor je rugzak te verdienen!`,
    showMedals: false
  };

  const infoBoxContent = {
    title: "OVER SPEL 4",
    goal: "Beantwoord zoveel mogelijk goede antwoorden en blijf de bijen voor.",
    topics: "Biodiversiteit, Bijen en Atletiek",
    inspiration: " Kies het Bijenhotel in de webshop!"
  };

  const questions = [
    {
      question: 'Wat is de levensduur van een werkbij?',
      answer: 'A',
      options: ['Enkele weken', 'Enkele maanden', 'Enkele jaren']
    },
    {
      question: 'Hoeveel nectar heeft een bij nodig om 1 kilo honing te maken?',
      answer: 'C',
      options: ['1 kilo', '10 kilo', '20 kilo']
    },
    {
      question: 'Hoeveel procent van de dieren en planten is bedreigd met uitsterven?',
      answer: 'C',
      options: ['5%', '10%', '25%']
    },
    {
      question: 'Wat is de functie van de startblokken in sprintwedstrijden?',
      answer: 'A',
      options: ['Ze bieden stabiliteit tijdens de start', 'Ze verbeteren de aerodynamica', 'Ze verhogen de snelheid']
    },
    {
      question: 'Hoeveel soorten bijen zijn er wereldwijd?',
      answer: 'A',
      options: ['20.000', '50.000', '100.000']
    },
    {
      question: 'Welke techniek gebruiken sprinters om de eerste seconden van de race te maximaliseren?',
      answer: 'B',
      options: ['De vluchtstart', 'De explosieve start', 'De hurkende start']
    },
    {
      question: 'Wat wordt bedoeld met biodiversiteit?',
      answer: 'A',
      options: ['De verscheidenheid aan planten en dieren in een ecosysteem', 'Het aantal mensen in een gebied', 'De hoeveelheid water in een rivier']
    },
    {
      question: 'Hoeveel atleten nemen meestal deel aan de finale van de 100 meter sprint op de Olympische Spelen?',
      answer: 'B',
      options: ['6', '8', '10']
    },
    {
      question: 'Wat is een voorbeeld van een invasieve soort?',
      answer: 'C',
      options: ['Dodo', 'Hond', 'Japanse duizendknoop']
    },
    {
      question: 'Welke atleet heeft de meeste gouden medailles gewonnen in de Olympische geschiedenis van de 100 meter sprint?',
      answer: 'A',
      options: ['Usain Bolt', 'Carl Lewis', 'Jesse Owens']
    },
    {
      question: 'Hoeveel ogen heeft een bij?',
      answer: 'C',
      options: ['2', '4', '5']
    },
    {
      question: 'Wat is de belangrijkste techniek bij sprinten?',
      answer: 'C',
      options: ['Lange stappen', 'Hoge hakken', 'Korte, krachtige stappen']
    },
    {
      question: 'Wat produceren bijen als ze nectar omzetten?',
      answer: 'B',
      options: ['Suiker', 'Honing', 'Melk']
    },
    {
      question: 'Welke van de volgende diersoorten is een voorbeeld van een symbiotische relatie?',
      answer: 'B',
      options: ['Vos en konijn', 'Anemoon en clownvis', 'Leeuw en zebra']
    },
    {
      question: 'Wat is het grootste bedreigde ecosysteem ter wereld?',
      answer: 'B',
      options: ['Regenwoud', 'Koraalrif', 'Toendra']
    },
    {
      question: 'Wie was de eerste sprinter die de 9 seconden grens op de 100 meter brak?',
      answer: 'A',
      options: ['Jim Hines', 'Bob Hayes', 'Carl Lewis']
    },
    {
      question: 'Wat is de belangrijkste fysiologische aanpassing die sprinters nastreven in hun training?',
      answer: 'C',
      options: ['Verbeterde aerobe capaciteit', 'Verhoogde anaerobe drempel', 'Verbeterde spierkracht en explosiviteit']
    },
    {
      question: 'Wat is de afstand van een typische sprintwedstrijd op de Olympische Spelen?',
      answer: 'A',
      options: ['100 meter', '200 meter', '400 meter']
    },
    {
      question: 'Welk ecosysteem heeft de meeste biodiversiteit?',
      answer: 'B',
      options: ['Woestijn', 'Regenwoud', 'Tundra']
    },
    {
      question: 'Wat is de belangrijkste taak van een werkbij?',
      answer: 'C',
      options: ['Eten verzamelen', 'Honing maken', 'Bestuiven van bloemen']
    },
    {
      question: 'Hoe communiceren bijen met elkaar?',
      answer: 'B',
      options: ['Door geluiden', 'Door dansen', 'Door kleuren']
    }
  ];

  const startTimer = () => {
    timerRef.current = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime > 1) {
          return prevTime - 1;
        } else {
          stopTimer();
          handleTimeout();
          return 0;
        }
      });
      setProgress((prevProgress) => prevProgress - 5);
    }, 1000);
  };

  const stopTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
  };

  useEffect(() => {
    const progressElement = document.querySelector('.progress');

    if (progressElement && timeLeft <= 5) {
      progressElement.classList.add('warning');
    } else if (progressElement) {
      progressElement.classList.remove('warning');
    }
  }, [timeLeft]);


  const resetTimer = () => {
    stopTimer();
    setTimeLeft(20);
    setProgress(100);
    startTimer();
  };

  const handleTimeout = () => {
    setFeedback(true);
    setShake(true);
    handleBeeMovement();
    setTimeout(() => {
      setShake(false);
      moveToNextQuestion();
    }, 1000);
  };

  useEffect(() => {
    if (gameStarted && !isGameOver) {
      startTimer();
    }

    return () => stopTimer();
  }, [gameStarted, isGameOver]);

  // Handle saving progress if the user tries to leave the page
  useEffect(() => {
    if (gameStarted) {
      const handleBeforeUnload = (event) => {
        toast('Je kunt het spel niet opnieuw spelen als je de pagina verlaat!', {
          position: 'bottom-center',
          duration: 5000,
          style: {
            background: '#facc15',
            color: '#000',
          },
        });
        event.preventDefault();
        event.returnValue = '';
        const saveProgressOnExit = async () => {
          try {
            await saveGameProgress(userId, gameId, correctCount);
          } catch (error) {
            console.error('Error saving progress on exit:', error.message);
          }
        };
        saveProgressOnExit();
      };
      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, [gameStarted, correctCount]);

  useEffect(() => {
    if (gameFinished) {
      const saveProgress = async () => {
        try {
          const response = await saveGameProgress(userId, gameId, correctCount);
        } catch (error) {
          console.error('Error saving progress:', error.message);
        }
      };
      saveProgress();
    }
  }, [gameFinished, correctCount, userId]);


  const handleBeeMovement = () => {
    setIncorrectCount((prevCount) => {
      const newCount = prevCount + 1;

      if (newCount >= 5) {
        setBeePosition(0);
        setTimeout(() => {
          setIsGameOver(true);
          setGameFinished(true);
        }, 1000);
        return newCount;
      }

      setBeePosition((prevPosition) => Math.max(prevPosition - 16, 0));
      setTimeout(() => {
        document.querySelector('.progress-bar').classList.remove('expired');
        setIsClickable(true);
        moveToNextQuestion();
      }, 1000);

      return newCount;
    });
  };

  const handleAnswerSubmit = (selectedOption) => {
    if (!isClickable) return;

    setIsClickable(false);
    setSelectedOption(selectedOption);
    const correctAnswer = questions[currentQuestionIndex].answer;

    if (selectedOption === correctAnswer) {
      setFeedback(true);
      new Audio(pingSound).play();
      setCorrectCount(correctCount + 1);
      stopTimer();
    } else {
      stopTimer();
      new Audio(buzzSound).play();
      setShake(true);
      handleBeeMovement();
      setFeedback(true);
    }

    setTimeout(() => {
      setShake(false);
      moveToNextQuestion();
      setIsClickable(true);
    }, 1000);
  };

  const moveToNextQuestion = () => {
    if (isGameOver || incorrectCount >= 10) {
      return;
    }

    setFeedback(false);
    setSelectedOption(null);

    if (currentQuestionIndex + 1 < questions.length) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      resetTimer();
    } else {
      setIsGameOver(true);
      setTimeout(() => {
        setGameFinished(true);
      }, 1500);
    }
  };

  const handleFinishGame = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setStartGame(false);
      history.push('/games?game=5');
    }, 7000);
  };

  const beginGame = () => {
    setGameStarted(true);
    setIsIntro(false);
  };

  const handleCloseInfoBox = () => {
    setShowInfoBox(false);
  };

  if (!gameStarted && startGame) {
    return (
      <>
        <GameIntro {...gameIntroText} onStart={beginGame} setIsMusicMuted={setIsMusicMuted} />
        {showInfoBox && (
          <InfoBox
            {...infoBoxContent}
            onClose={handleCloseInfoBox}
          />
        )}
      </>
    );
  }

  if (loading) {
    return (
      <div className="game-loader">
        <video
          src={gamesTransitionVideo}
          autoPlay
          muted
          onEnded={() => setLoading(false)}
          className="transition-video"
        />
      </div>
    );
  }

  if (gameFinished) {
    return (
      <>
        <GameResult
          score={correctCount}
          isFourthGame={true}
          onNextGame={handleFinishGame}
        />
      </>
    );
  }

  return (
    <div className={`game-screen ${!startGame ? 'game-screen-video' : ''}`}>
      <div className='highlight-current-game'> 4 </div>
      {isGameOver ? (
        <div className="game-over">
        </div>
      ) : !startGame ? (
        <VideoPlaceholder setIsMusicMuted={setIsMusicMuted} />
      ) : (
        <div className="game-content">
          <div className="left-section-bees-game">
            <h2>De Achtervolging</h2>
            <p>
              Beantwoord zoveel mogelijk vragen goed om de zwerm bijen voor te
              blijven en je route te vervolgen. Heb je teveel vragen fout? Dan
              moet je helaas je bijensteken behandelen. Dit kost tijd en gaat van
              je puntentotaal af.
            </p>
            <div className="bee-progress">
              <img
                src="/media/images/bees-picture.png"
                alt="Progress bar"
                className="progress-bar-image"
              />
              <img
                src="/media/images/increasingbees.png"
                alt="Bees"
                className={`bees-image ${shake ? 'shake' : ''}`}
                style={{ top: `${100 - beePosition}%` }}
              />
            </div>
          </div>

          <div className="right-section-bees-game">
            <div className="question-box-bees-game">
              <p>{questions[currentQuestionIndex].question}</p>
              <div className="progress-bar">
                <div className="progress" style={{ width: `${progress}%` }}></div>
              </div>
            </div>
            <div className="options">
              {['A', 'B', 'C'].map((option, index) => (
                <button
                  key={option}
                  className={`option ${feedback && selectedOption === option && option === questions[currentQuestionIndex].answer
                    ? 'correct'
                    : feedback && selectedOption === option && option !== questions[currentQuestionIndex].answer
                      ? 'incorrect'
                      : ''
                    }`}
                  data-label={option}
                  onClick={() => handleAnswerSubmit(option)}
                  disabled={feedback || isGameOver || gameFinished || !isClickable}
                >
                  <div className="label-circle">{option}</div>
                  {questions[currentQuestionIndex].options[index]}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GameScreenFour;
