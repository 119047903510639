import React, {Suspense} from 'react'
import Auth from '../auth/Auth';


import {TcmsContext} from '../contexts/TcmsContext'
import PageLoading from "../components/webshop/PageLoading";
// Need all them skins definded
// const SkinDefault = React.lazy(() => import('./skins/default/Info'));
import SkinDefault from './skins/default/BuyCredit'
class BuyCredit extends Auth {

  static contextType = TcmsContext

  /*********************************************************************************************************************
   *                        The skin stuff. Do not change. Copy this part to create a new Skin page                    *
   *                        Yes, it's duplicated code. But I think Webpack needs it to load dynamic                    *
   *                        !! Only thing you might do is set pageType (public/private) !!                             *
   ********************************************************************************************************************/

  pageType = 'private'

  componentDidMount() {
    this.context.setLoadedComponent(this.constructor.name);
    super.componentDidMount()
    if (this.mtgComponentDidMount) this.mtgComponentDidMount()
  }

  render() {

    if (!this.context.products) return null;

    return (
      <Suspense fallback={<PageLoading/>}>{this.getSkin()}</Suspense>
    )
  }

  getSkin(html) {
    // Check if skin exists or theme exists
    const skinExists = eval('typeof Skin' + this.context.webshopId) === 'undefined' ? false : true // eslint-disable-line no-eval
    const themeExists = eval('typeof Theme' + this.context.themeId) === 'undefined' ? false : true // eslint-disable-line no-eval

    if (skinExists) {
      html = React.createElement(eval('Skin' + this.context.webshopId), {rootComponent: this}) // eslint-disable-line no-eval
    } else if (themeExists) {
      html = React.createElement(eval('Theme' + this.context.themeId), {rootComponent: this}) // eslint-disable-line no-eval
    } else {
      html = <SkinDefault rootComponent={this}/>
    }

    return html
  }

}

export default BuyCredit
