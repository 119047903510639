import React, { useState, useEffect, useRef, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import GameScreenOne from '../first-game/GameScreenOne';
import GameScreenTwo from '../second-game/GameScreenTwo';
import GameScreenThree from '../third-game/GameScreenThree';
import GameScreenFour from '../fourth-game/GameScreenFour';
import GameScreenFive from '../fifth-game/GameScreenFive';
import GameScreenSix from '../sixth-game/GameScreenSix';
import PrizeModal from '../prize-modal/PrizeModal';
import SettingsModal from '../settings-modal/SettingsModal';
import queryString from 'query-string';
import './Games.scss';
import { getUserProgress } from '../services/gameProgressService';
import game1Sound from '../../../../sounds/GAME-1.mp3';
import game2Sound from '../../../../sounds/GAME-2.wav';
import game3Sound from '../../../../sounds/GAME-3.wav';
import game4Sound from '../../../../sounds/GAME-4.mp3';
import game5Sound from '../../../../sounds/GAME-5.mp3';
import game6Sound from '../../../../sounds/GAME-6.wav';
import introSound from '../../../../sounds/GAME-1.mp3';
import { TcmsContext } from '../../../../contexts/TcmsContext';
import jwtDecode from 'jwt-decode';
import { toast } from 'react-hot-toast';
import InfoBox from '../game-infobox/InfoBox';
import { getUserFinalResults } from '../services/gameProgressService';

const quizSounds = [
  game1Sound,
  game2Sound,
  game3Sound,
  game4Sound,
  game5Sound,
  game6Sound,
  introSound
];

const games = [
  { id: 0, name: "Welcome page", prize: "House of Marley headphones", backgroundImage: "https://i.imgur.com/P62E2BW.jpeg" },
  { id: 1, name: "Game 1", prize: "House of Marley headphones", backgroundImage: "https://i.postimg.cc/Bbf1pw6D/beautiful-shot-river-middle-trees-with-mountain-1.jpg", image: "https://static.bax-shop.es/image/product/788022/2900678/cc6cb02e/450x450/1622112199EM-JH151-SB_Product_01_JPG.jpg" },
  { id: 2, name: "Game 2", prize: "Amazon Echo Dot", backgroundImage: "https://i.postimg.cc/dV6m1v0f/dirt-road-middle-forest-trees-sunny-day.jpg", image: "https://images-na.ssl-images-amazon.com/images/I/6182S7MYC2L._AC_SL1000_.jpg" },
  { id: 3, name: "Game 3", prize: "Google Nest Hub", backgroundImage: "https://i.postimg.cc/02gCXc3V/blue-sky-reflected-lake-with-plants-with-forest-trees.jpg", image: "https://duet-cdn.vox-cdn.com/thumbor/0x0:2040x1360/2400x1600/filters:focal(1046x1005:1047x1006):format(webp)/cdn.vox-cdn.com/uploads/chorus_asset/file/19170899/dseifert_190905_3634_0001.jpg" },
  { id: 4, name: "Game 4", prize: "Fitbit Charge 4", backgroundImage: "https://i.postimg.cc/52sLJ37d/beautiful-nature-maksimir-park-zagreb-reflected-water.jpg", image: "https://i5.walmartimages.com/seo/Fitbit-Charge-4-NFC-Activity-Fitness-Tracker-Rosewood_5f119787-5974-4ee7-b0b4-44bcce9b71b6.f7930101fbfd60c065d80c40741510e8.jpeg" },
  { id: 5, name: "Game 5", prize: "PlayStation 5", backgroundImage: "https://i.postimg.cc/R08t60xg/beautiful-shot-forest-with-green-trees-yellow-leaves-ground-sunny-day.jpg", image: "https://3dbox.ba/wp-content/uploads/2024/02/PlayStation-5-Slim-Digital-Edition-D-chassis-1.jpg" },
  { id: 6, name: "Game 6", prize: "Apple Watch Series 6", backgroundImage: "https://i.postimg.cc/PfDWD1xY/pine-tree-road-forest.jpg", image: "https://jupiter.planet.ba/2507-large_default/apple-watch-series-6-44mm-aluminium-case-pink.jpg" },
];

const gameScreens = {
  1: GameScreenOne,
  2: GameScreenTwo,
  3: GameScreenThree,
  4: GameScreenFour,
  5: GameScreenFive,
  6: GameScreenSix,
};

function getIconName(gameId) {
  switch (gameId) {
    case 1:
      return 'tree1.svg';
    case 2:
      return 'bench.svg';
    case 3:
      return 'branch.svg';
    case 4:
      return 'bee.svg';
    case 5:
      return 'bench2.svg';
    case 6:
      return 'tree2.svg';
    default:
      return 'cabin.svg';
  }
}

const speakText = () => {
  const text = `Welkom bij jouw avontuurlijke wandeltocht, geïnspireerd door Cabiner! Onderweg richting jouw Cabin midden in de natuur, ontdek je zes prachtige uitkijkplekken, elk met een unieke uitdaging die je kennis test over natuur, duurzaamheid en meer. Vul je rugzak met punten door vragen te beantwoorden en maak kans op een geweldige prijs: voor tien gelukkige deelnemers wacht een gratis overnachting midden in de natuur bij Cabiner! En geen zorgen, iedereen ontvangt sowieso 10% korting op zo’n onvergetelijke ervaring. Bij elke rustplaats maak je kennis met duurzame producten die later te vinden zijn in de webshop. Bereid je voor op een tocht vol verrassingen, natuur en plezier!`;

  if ('speechSynthesis' in window) {
    const speech = new SpeechSynthesisUtterance(text);
    speech.lang = 'nl-NL';
    speech.rate = 1;
    speech.pitch = 1;
    window.speechSynthesis.speak(speech);
  } else {
    alert("error");
  }
};

const infoBoxContent = {
  title: "Informatiekader beginscherm",
  summary: "Wat ga je doen? Een virtuele wandeltocht door de natuur naar een Cabin.",
  goal: "Hoe kom je bij de cabin? Door tijdens 6 tussenstops, leuke spelletjes te spelen.",
  topics: "Wat is het doel? Speel alle spellen zo goed mogelijk, verzamel punten.",
  inspiration: "Wat kun je winnen? IEDEREEN wint 10% korting bij Cabiner + de 10 met de meeste punten winnen: Een nacht voor 2 personen in de Nederlandse wildernis (t.w.v. € 242.00)."
};

function stopSpeech() {
  window.speechSynthesis.cancel();
}

const LOADING_DELAY = 3000;

function Games() {
  const [toastShown, setToastShown] = useState(false);
  const { setJwtForGames } = useContext(TcmsContext);
  const [userId, setUserId] = useState('');
  const location = useLocation();
  const history = useHistory();
  const quizAudioRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);
  const [volume, setVolume] = useState(1);
  const [showWelcomeMsg, setshowWelcomeMsg] = useState(true);
  const [isPrizeModalOpen, setIsPrizeModalOpen] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const parsedQuery = queryString.parse(location.search);
  const gameFromURL = parsedQuery.game ? parseInt(parsedQuery.game) : 0;
  const [startGame, setStartGame] = useState(parsedQuery.start === 'true');
  const [activeGame, setActiveGame] = useState(gameFromURL);
  const currentGame = games.find(game => game.id === activeGame);
  const [loading, setLoading] = useState(true);
  const [totalScore, setTotalScore] = useState(0);
  const [backpackSize, setBackpackSize] = useState(1);
  const [isGrowing, setIsGrowing] = useState(false);
  const [previousBackpackSize, setPreviousBackpackSize] = useState(1);
  const [showInfoBox, setShowInfoBox] = useState(true);
  const [isMusicMuted, setIsMusicMuted] = useState(false);
  const [isIntro, setIsIntro] = useState(true);

  useEffect(() => {
    const storedUserId = localStorage.getItem('userId');
    if (storedUserId) {
      setUserId(storedUserId);
    } else {
      console.error("User ID not found in localStorage.");
    }
  }, []);

  const maxBackpackSize = 1.6;


  const handleCloseInfoBox = () => {
    setShowInfoBox(false);
  };

  const fetchScore = async () => {
    try {
      if (!userId) return;

      const response = await getUserFinalResults(userId);
      const newScore = response.totalScore;
      setTotalScore(newScore);

      const newBackpackSize = Math.min(1 + Math.floor(newScore / 5) * 0.2, maxBackpackSize);

      if (newBackpackSize !== previousBackpackSize) {
        setBackpackSize(newBackpackSize);
        setPreviousBackpackSize(newBackpackSize);
      } else {
        setBackpackSize(newBackpackSize);
      }
    } catch (error) {
      console.error("Error fetching score:", error);
    }
  };

  useEffect(() => {
    if (backpackSize > previousBackpackSize) {
      setIsGrowing(true);
      setTimeout(() => setIsGrowing(false), 1000);
    }
  }, [backpackSize]);

  useEffect(() => {
    if (userId) {
      fetchScore();
      const intervalId = setInterval(fetchScore, 1000);

      return () => clearInterval(intervalId);
    }
  }, [userId]);

  const backpackStyle = {
    width: `${Math.min(backpackSize * 40, maxBackpackSize * 40)}px`,
    height: `${Math.min(backpackSize * 40, maxBackpackSize * 40)}px`,
    transition: 'width 0.5s ease, height 0.5s ease',
  };

  useEffect(() => {
    const storedJwtToken = sessionStorage.getItem('jwt_token');
    if (storedJwtToken) {
      setJwtForGames(storedJwtToken);
      const tokenData = jwtDecode(storedJwtToken);
      const userId = tokenData?.usr?.uid;
      if (userId) {
        setUserId(userId); // Set user ID if available
      } else {
        // Always redirect to '/games'
        const fullRedirectUrl = `/login?redirectUrl=${encodeURIComponent('/games')}`;
        window.location.replace(fullRedirectUrl);
      }
    } else {
      // Always redirect to '/games'
      const fullRedirectUrl = `/login?redirectUrl=${encodeURIComponent('/games')}`;
      window.location.replace(fullRedirectUrl);
    }
  }, [setJwtForGames, location]);
  // Fetch user progress when the component mounts and handle redirection
  useEffect(() => {
    const fetchProgress = async () => {
      try {
        const progress = await getUserProgress(userId);
        const uncompletedGame = Object.keys(progress).find((gameKey) => !progress[gameKey].completed);
        const currentGameNumber = parseInt(queryString.parse(location.search).game);
        const gameNumber = uncompletedGame ? parseInt(uncompletedGame.replace('game', '')) : null;
        // Check if all games are completed
        const allGamesCompleted = Object.values(progress).every((game) => game.completed);
        // Redirect to the final results page if all games are completed
        if (allGamesCompleted) {
          if (!toastShown) {
            toast(`Je hebt alle spellen voltooid.`, { duration: 3000 });
            setToastShown(true);
          }
          history.replace(`/final-result`);
          return;
        }
        // Only redirect if we are not on the introduction page (game=0)
        if (currentGameNumber !== 0 && !toastShown) {
          // 1. User has completed all games, redirect to the final result page
          if (allGamesCompleted && currentGameNumber !== 'final-result') {
            toast(`Je hebt alle spellen voltooid.`, {
              duration: 3000,
            });
            setToastShown(true);
            history.replace(`/final-result`);
          }
          // 2. User has completed the current game and should be redirected to the next uncompleted game
          else if (gameNumber && gameNumber !== currentGameNumber && progress[`game${currentGameNumber}`]?.completed) {
            toast(`Je hebt dit spel al voltooid. Je wordt doorgestuurd naar spel ${gameNumber}.`, {
              duration: 3000,
            });
            setToastShown(true);
            history.replace(`?game=${gameNumber}`);
          }
          // 3. User tries to access a game ahead of their progress
          else if (gameNumber && gameNumber < currentGameNumber) {
            toast(`Je moet eerst spel ${gameNumber} voltooien voordat je dit spel kunt spelen.`, {
              duration: 3000,
            });
            setToastShown(true);
            history.replace(`?game=${gameNumber}`);
          }
          // 4. User is not trying to access the current game, so redirect them to the uncompleted game
          else if (gameNumber && gameNumber !== currentGameNumber) {
            toast(`Je wordt doorgestuurd naar spel ${gameNumber}, omdat dit spel nog niet is voltooid.`, {
              duration: 3000,
            });
            setToastShown(true);
            history.replace(`?game=${gameNumber}`);
          }
        }
      } catch (error) {
        console.error('Failed to load user progress', error);
      }
    };
    if (userId && !toastShown) {
      fetchProgress();
    }
  }, [userId, activeGame, history, location.search, toastShown]);

  useEffect(() => {
    setActiveGame(gameFromURL);
    if (gameFromURL !== 0) {
      setshowWelcomeMsg(false);
    }
  }, [gameFromURL]);


  useEffect(() => {
    setLoading(true);
    const query = queryString.stringify({ game: activeGame });
    history.replace({ search: `?${query}` });

    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, LOADING_DELAY);

    return () => clearTimeout(timeoutId);

  }, [activeGame, history]);

  useEffect(() => {
    if (quizAudioRef.current) {
      quizAudioRef.current.pause();
    }

    if (activeGame === 0) {
      const introAudio = new Audio(introSound);
      introAudio.muted = isMuted || isMusicMuted;
      introAudio.volume = volume;
      introAudio.loop = true;
      quizAudioRef.current = introAudio;
      if (!isMuted && !isMusicMuted) {
        quizAudioRef.current.play();
      }
    } else {
      const newAudio = new Audio(quizSounds[activeGame - 1]);
      newAudio.muted = isMuted || isMusicMuted;
      newAudio.volume = volume;
      newAudio.loop = true;
      quizAudioRef.current = newAudio;
      if (!isMuted && !isMusicMuted) {
        quizAudioRef.current.play();
      }
    }

    return () => {
      if (quizAudioRef.current) {
        quizAudioRef.current.pause();
      }
    };
  }, [activeGame, isMuted, isMusicMuted, volume]);

  const handleStartGame = () => {
    setActiveGame(1);
    setshowWelcomeMsg(false);
    stopSpeech();
    setPreviousBackpackSize(1);
  };

  const handleVolumeChange = (newVolume) => setVolume(newVolume);
  const openPrizeModal = () => setIsPrizeModalOpen(true);
  const closePrizeModal = () => setIsPrizeModalOpen(false);
  // const openSettingsModal = () => setIsSettingsModalOpen(true);
  const closeSettingsModal = () => setIsSettingsModalOpen(false);

  const renderGameScreen = () => {
    const GameScreenComponent = gameScreens[activeGame];
    if (GameScreenComponent) {
      return (
        <GameScreenComponent
          startGame={startGame}
          setStartGame={setStartGame}
          setIsMusicMuted={setIsMusicMuted}
          setIsIntro={setIsIntro}
        />
      );
    }
    return null;
  };

  const handleToggleMute = () => {
    setIsMuted(!isMuted);
    if (isMuted) {
      quizAudioRef.current.play();
    } else {
      quizAudioRef.current.pause();
    }
  };

  const backgroundStyle = {
    backgroundImage: activeGame === 0
      ? `url(${games[0].backgroundImage})`
      : `url(${games[activeGame]?.backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  return (
    <>
      <div className="welcome-screen" style={backgroundStyle}>
        <header className="header">
          <div className="user-info">
            <img
              src="https://img.icons8.com/ios/50/ffffff/user--v1.png"
              alt="user icon"
              className="user-icon"
            />
            <span>Welkom Jasper</span>
          </div>
          <div className="logo">
          </div>
          <div className="settings">
            {((startGame && !isIntro) || activeGame === 0) && (
              <img
                src={isMuted ? "https://img.icons8.com/ios-filled/50/ffffff/mute.png" : "https://img.icons8.com/ios-filled/50/ffffff/low-volume.png"}
                alt="sound icon"
                className="icon"
                onClick={handleToggleMute}
                style={{ cursor: 'pointer' }}
              />
            )}
            {/* <img
              src="https://img.icons8.com/ios-filled/50/ffffff/settings.png"
              alt="settings icon"
              className="icon"
              onClick={openSettingsModal}
              style={{ cursor: 'pointer' }}
            /> */}
          </div>
        </header>

        {showWelcomeMsg ? (
          <div className="content content-bg">
            <div className="text-box">
              <h1>Welkom bij jouw avontuurlijke wandeltocht, geïnspireerd door Cabiner!</h1>
              <p>
                Onderweg richting jouw Cabin midden in de natuur, ontdek je zes prachtige uitkijkplekken, elk met een unieke uitdaging die je kennis test over natuur, duurzaamheid en meer. Vul je rugzak met punten door vragen te beantwoorden en maak kans op een geweldige prijs: voor tien gelukkige deelnemers wacht een gratis overnachting midden in de natuur bij Cabiner! En geen zorgen, iedereen ontvangt sowieso 10% korting op zo’n onvergetelijke ervaring. Bij elke rustplaats maak je kennis met duurzame producten die later te vinden zijn in de webshop. Bereid je voor op een tocht vol verrassingen, natuur en plezier!
              </p>
              <div className='welcome-buttons'>
                <button aria-label="Start the game" className="button primary" onClick={handleStartGame}>
                  Start het spel
                </button>
                <button
                  className="button secondary"
                  onClick={() => (window.location.href = '/webshop')}
                >
                  Naar de webshop
                </button>
              </div>
              <div className='listen'>
                <button className="tts-button" onClick={() => speakText()}>
                  <img src="/media/icons/listen.svg" alt="Listen Icon" className="tts-icon" />
                </button>
              </div>
            </div>
            <div className="image-box">
              <img
                src="https://suburbanmen.com/wp-content/uploads/2020/07/need-little-rustic-cabin-woods-20200706-1006.jpg"
                alt="Forest"
              />
              <div className="overlay">
                <p>Start jouw wandeling in de Nederlandse natuur</p>
              </div>
              <button aria-label="Close overlay" onClick={handleStartGame} className="close-button">X</button>
            </div>
            {showInfoBox && (
              <InfoBox
                {...infoBoxContent}
                onClose={handleCloseInfoBox}
              />
            )}
          </div>
        ) : (
          <div className="content">
            {renderGameScreen()}
          </div>
        )}
        <footer className="footer">
          <div className="footer-button-container">
            <a href='/webshop' className="footer-button">
              <img
                src="https://img.icons8.com/ios-filled/50/ffffff/gift.png"
                alt="gift icon"
                className="footer-icon" />
              Naar de webshop
              <span className="arrow-right">{'>'}</span>
            </a>
          </div>
          {activeGame !== 0 && (
            <div className="steps">
              {games
                .filter(game => game.id !== 0)
                .map(game => (
                  <div
                    key={game.id}
                    className={`step ${game.id === activeGame ? 'active' : ''}`}
                  >
                    <img
                      src={`/media/icons/${getIconName(game.id)}`}
                      alt={`${game.name} icon`}
                      className="step-icon"
                    />
                  </div>
                ))}
            </div>
          )}
          {activeGame !== 0 && (
            <div className="footer-icons">
              <img
                src="https://img.icons8.com/ios-filled/50/ffffff/backpack.png"
                alt="backpack icon"
                className={`footer-icon prize backpack ${isGrowing ? 'shake' : ''}`}
                style={backpackStyle}
                onClick={openPrizeModal}
              />
            </div>
          )}
        </footer>
      </div>

      <PrizeModal show={isPrizeModalOpen} onClose={closePrizeModal}>
        <h2>Prize for this game is:</h2>
        <p>{currentGame.prize}</p>
        <img src={currentGame.image} alt={currentGame.prize} style={{ width: '100%' }} />
      </PrizeModal>

      <SettingsModal
        show={isSettingsModalOpen}
        onClose={closeSettingsModal}
        onVolumeChange={handleVolumeChange}
        isMuted={isMuted}
        volume={volume}
        onMuteToggle={handleToggleMute}
      />
    </>
  );
}

export default Games;
