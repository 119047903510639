import React, {Component} from 'react'
import {TcmsContext} from "../contexts/TcmsContext"
import {Checkbox} from "primereact/checkbox";
import {Link} from "react-router-dom";
import {Sidebar} from "primereact/sidebar";
import {Button} from "primereact/button";

let mouseLeftCount = -1
let mouseLeftHandle

class TcmsTweakPanel extends Component {

  static contextType = TcmsContext

  state = {
    wsos: [],
    visible: false,

  }

  constructor(props) {
    super(props);
    this.handleMouseMove = this.handleMouseMove.bind(this)

    this.refSidebar = React.createRef()
  }

  handleMouseMove(e) {
    if (this.state.visible) return
    if (10 > e.clientX) {
      if (-1 === mouseLeftCount) {
        mouseLeftCount++
        mouseLeftHandle = setInterval(() => {
          mouseLeftCount++;
          if (mouseLeftCount > 1) {
            this.handleMouseMoveClear()
            this.setState({visible: true})
          }
        }, 100)
      }
    } else if (mouseLeftHandle) {
      this.handleMouseMoveClear()
    }
  }

  handleMouseMoveClear() {
    // Clear the stuff when we're not left
    clearInterval(mouseLeftHandle)
    mouseLeftHandle = null
    mouseLeftCount = -1
  }

  componentDidMount() {
    window.addEventListener('mousemove', this.handleMouseMove)
    // this.setState({visible: true})
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.handleMouseMove)
  }

  logoutMtg() {
    this.handleMouseMoveClear()
    document.querySelector('#root').style.borderLeftWidth = 0 // Cosmetic

    sessionStorage.removeItem('jwt_token');
    sessionStorage.removeItem('access_token');
    document.location.href = '/' + this.context.webshopSlug
  }

  runOnce = true

  render() {
    /**
     * Strange stuff but we're outside the router with this tweak pannel
     */
    if (!sessionStorage.getItem('access_token')) return null

    if (this.runOnce) {
      document.querySelector('#root').style.borderLeftWidth = '0.25rem' // Cosmetic
      this.runOnce = false;
    }

    return (
      <React.Fragment>
        <div className="tcms-tweak-panel-notifier"/>
        <Sidebar
          ref={this.refSidebar}
          modal={true}
          style={{width: '20rem'}}
          position="left"
          visible={this.state.visible}
          onHide={() => {
            this.setState({visible: false})
          }}>

          <div className="tcms-tweak-panel">

            <table>
              <tbody>
              <tr>
                <td>
                  <Checkbox id="toggle-edit"
                            checked={this.context.editMode}
                            onChange={() => {
                              this.handleMouseMoveClear()
                              this.setState({visible: false})
                              this.context.setEditMode(!this.context.editMode)
                            }}/>
                </td>
              </tr>
              <tr>
                <td>
                  <Link to={`/${this.context.webshopSlug}`}>
                    <Button label="Naar de login pagina"/>
                  </Link>
                </td>
              </tr>
              <tr>
                <td>
                  {/*Thema: {this.context.meta.theme_name}*/}
                </td>
              </tr>

              </tbody>
            </table>

            <table>
              <tbody>
              <tr>
                <td style={{width: '1%'}}>WsoId:</td>
                <td>{this.context.webshopId}</td>
              </tr>
              <tr>
                <td>Slug:</td>
                <td>{this.context.webshopSlug}</td>
              </tr>
              <tr>
                <td>Tid:</td>
                <td>{this.context.themeId}</td>
              </tr>
              <tr>
                <td>Edit:</td>
                <td>{this.context.editMode ? 1 : 0}</td>
              </tr>
              <tr>
                <td>Admin:</td>
                <td>{this.context.isAdmin ? 1 : 0}</td>
              </tr>
              <tr>
                <td></td>
                <td style={{cursor: 'pointer', backgroundColor: 'gainsboro'}}
                    onClick={() => this.logoutMtg()}>Logout!
                </td>
              </tr>
              </tbody>
            </table>


          </div>
        </Sidebar>

      </React.Fragment>
  )
  }
  }

  export default TcmsTweakPanel