import {User, getUser} from '../../components/User'
import React, {useEffect, useState} from "react";
import {Button} from "primereact/button";
import axios from "axios";
import Tcms from "../../../tcms/Tcms";
import TcmsWYSIWYG from "../../../tcms/Tags/TcmsWYSIWYG";


const BE = process.env.REACT_APP_MTG_BACKEND
const Borrelen = () => {

  // USER STUK CODE //
  const [user, setUser] = useState()
  const [edit, setEdit] = useState()
  const [meetings, setMeetings] = useState()
  const [iframeUrl, setIframeUrl] = useState()


  useEffect(() => {
    axios.get(BE + '/api/webshop/get-meetings').then(res => {
      setMeetings(res.data)
    });
    getUser().then(res => {
      setUser(res.data)
    });
  }, [])

  if (!user || !meetings) return null

  const startWeb = (id) => {
    setIframeUrl(`/api/webshop/meeting-iframe-web?meeting_id=${id}&mtgjwt=${sessionStorage.getItem('jwt_token')}`)
  }

  const startNative = (id) => {
    axios.get(`/api/webshop/meeting-iframe-native?meeting_id=${id}&mtgjwt=${sessionStorage.getItem('jwt_token')}`).then(res => {

      setIframeUrl(res.data)   // In the iframe

      // window.open(res.data) // New tab or window

    })

  }

  // ouch
  const completeUser = !(user.firstname && user.lastname && user.private_email && !edit)

  const htmlUser = () => {
    return <>

      {completeUser
        // When not enough user data available aks to complete
        ? <User userData={user} onUserChanged={usr => {
          setUser(usr)
          setEdit(false)
        }}/>
        // Have user data. Show the rooms
        : <div className="p-grid p-text-center bar">
          <div className={"p-col-12"}>
            <TcmsWYSIWYG>_widget_borrelen_intro_</TcmsWYSIWYG>
          </div>
          {meetings.map(meeting => {
            return <>
              <div className={"p-col-6 box"} key={meeting.id}>
                <p><Tcms>Ga naar de online bar:</Tcms></p>
                <div className="">
                  <Button label={meeting.topic} onClick={() => {
                    startWeb(meeting.id)
                  }}/>
                </div>
              </div>
              <div className={"p-col-6 box"} key={meeting.id}>
                <p><Tcms>Open in zoom:</Tcms></p>
                <div className="">
                  <Button label={meeting.topic} onClick={() => {
                    startNative(meeting.id)
                  }}/>
                </div>
              </div>
            </>
          })}
        </div>
      }
    </>

  }

  const htmlZoom = () => {

    return <div style={{height: '600px'}}>
      <iframe title="Zoom" src={iframeUrl}
              width="100%"
              height="595px"
              frameBorder="0"/>
    </div>


  }

  return <>
    {iframeUrl
      ? htmlZoom()
      : htmlUser()
    }

  </>
}

export default Borrelen
