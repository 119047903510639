import {Button} from "primereact/button";
import {useContext} from "react";
import {TcmsContext} from "../../contexts/TcmsContext";


function ShopModusWarning() {

  const context = useContext(TcmsContext)
  const isLogin = context.loadedComponent === 'Login'

  const getShopModusTopWarning = () => {
    return <div className="shopmodus-top-warning">
      <span>Winkel modus is aan!</span>
    </div>
  }

  const getShopmodusLogin = () => {
    return <div className="shopmodus-login-warning">
      <h1>Winkel modus is aan!</h1>
      <div className="some-text">Sluit deze pagina (klik deze weg) of...</div>

      <Button onClick={() => {
        localStorage.removeItem('shopModusToken')     // Just remove the localStorage item
        document.location.href = '/'                  // Jump to
      }} label="klik hier om de winkel modus te verlaten."/>
    </div>

  }

  /**
   * Depends on page
   */
  return isLogin ? getShopmodusLogin() : getShopModusTopWarning()

}

export default ShopModusWarning
