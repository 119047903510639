import React, {Component} from 'react'
import axios from 'axios'
import {TcmsContext} from '../../contexts/TcmsContext'

import PageLoading from "../../components/webshop/PageLoading";

const API = process.env.REACT_APP_MTG_BACKEND + '/api'
class MtgLogin extends Component {

  static contextType = TcmsContext

  render() {
    return <PageLoading/>
  }

  constructor(props) {
    super(props);
    // We can have an URL like so /mtg-login/#/slug/hash
    const hash=document.location.hash.split('/');

    if(hash.length > 1){
      const id = hash[1];
      const token = hash[2];
      sessionStorage.setItem('access_token', token);
      // Set oAuth
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      // Now we should get a fake JWT token
      axios.post(API + '/webshop/fake-login',{id}).then(res=>{
        // This is JWT
        // It also logs the user in so the webshop slug is set
        sessionStorage.setItem('jwt_token', res.data.jwt_token);
        axios.defaults.headers.common['MtgJwt'] = res.data.jwt_token;

        this.context.login(res.data.jwt_token)

        this.context.setIsAdmin(true)

        // Jump
        this.context.login(res.data.jwt_token)
        // Jump
        if(this.context.virtualMarketSlug){
          // To virtual market
          this.props.history.push('/' + this.context.webshopSlug + '/virtueel')
        } else {
          // To webshop
          this.props.history.push('/' + this.context.webshopSlug + '/webshop')
        }
      })
    }
  }
}

export default MtgLogin
