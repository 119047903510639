import React, {forwardRef, useImperativeHandle, useState} from 'react'
import {Button} from 'primereact/button';
import {Dialog} from "primereact/dialog";

const DialogOrderMail = forwardRef((props, ref) => {

  const [visible, setVisible] = useState()

  useImperativeHandle(ref, () => ({
    open() {
      setVisible(true)
    }
  }));

  return <Dialog
    visible={visible}
    style={{width: '60em'}}
    position="top"
    modal={true}
    onHide={() => {
      setVisible(false)
    }}>

    <div className="p-grid">
      <div className="p-col" style={{whiteSpace: 'pre'}}>
        {props.children}
      </div>
    </div>

    <div className="p-grid">
      <div className="p-col">
        <Button style={{width: '10em'}}
                label="Sluiten"
                onClick={() => {
                  setVisible(false)
                }}/>
      </div>
    </div>
  </Dialog>
})

export default DialogOrderMail
