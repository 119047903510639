import MenuItems from "../webshop/menu/MenuItems";
import Basket from "../webshop/menu/Basket";
import Favorites from "../webshop/menu/Favorites";
import VirtualDialogVMHelp from "./dialogs/VirtualDialogVMHelp";
import VirtualDialogPortal from "./dialogs/VirtualDialogPortal";
import {useContext, useRef} from "react";
import Tcms from "../../tcms/Tcms";
import {TcmsContext} from "../../contexts/TcmsContext";
import Helpdesk from "../webshop/helpdesk/Helpdesk";
import LangSwitch from "../webshop/menu/LangSwitch";

const VirtualMarketMenu = () => {
  const refVirtualDialogVMHelp = useRef()
  const refVirtualDialogPortal = useRef()
  
  const context = useContext(TcmsContext)
  const balance = context.getBalance()
  return (
    <>

      <VirtualDialogVMHelp
        ref={refVirtualDialogVMHelp}
        defaultVisible={parseInt(process.env.REACT_APP_VM_HELP_VISIBLE) && context.vmShowHelp}/>

      <VirtualDialogPortal
        ref={refVirtualDialogPortal}
        defaultVisible={parseInt(process.env.REACT_APP_VM_HELP_VISIBLE) && !context.vmShowHelp}/>

      <div className="vk-nav" style={{overflow: 'hidden'}}>



        <div className="basket basket-shop basket-wrapper">
          <Basket/>
        </div>
        <div className="basket basket-favorites basket-wrapper">
          <Favorites/>
        </div>
        <div className="balance">
          <ul>
            <li onClick={() => {
              }}><Tcms><span className="eco"><img alt="" src="/media/icons/coins.svg"/></span></Tcms> {balance}
              <Tcms> {balance === 1 ? 'punt' : 'punten'}</Tcms>
            </li>
          </ul>
        </div>

        <div className="menu">
          <MenuItems />
        </div>



        <div className="help hidden" onClick={()=>refVirtualDialogVMHelp.current.open()}>
          <Tcms>HELP!</Tcms>
        </div>
        <LangSwitch/>
      </div>
      <Helpdesk/>
    </>

  )


}

export default VirtualMarketMenu
