import React, {forwardRef, useImperativeHandle, useState} from "react";
import {Dialog} from "primereact/dialog";
import TcmsImg from "../../../tcms/Tags/TcmsImg";
import Tcms from "../../../tcms/Tcms";

import Favorites from "../../webshop/Favorites";
import Pdp from "../../webshop/pdp/Pdp";

const VirtualDialogFavorites = forwardRef((props, ref) => {
  const [visible, setVisible] = useState()
  const [productId, setProductId] = useState(false)

  useImperativeHandle(ref, () => ({
    open() {
      setProductId(false)
      setVisible(true)
    }
  }));
  const replaceWithPdp = (product_id) => {
    setProductId(product_id)
  }

  return <>
    <Dialog header={
      <div>
        <TcmsImg className="dialog-header-img img-left" src="/media/images/christmas_branch_l.png"></TcmsImg> 
        <Tcms>Favorieten</Tcms> 
        <TcmsImg className="dialog-header-img img-right" src="/media/images/christmas_branch_r.png"></TcmsImg> 
      </div>
    }
            className="vm-dialog-favorites"
            maximizable={false}
            maximized={false}
            style={{width: '800px', height: '80%'}}
            visible={visible}
            modal={true}
            onHide={() => setVisible(false)}>
      <div>
        {productId
          ?
          <Pdp product_id={productId}/>
          :
          <Favorites replaceWithPdp={replaceWithPdp}/>
        }
      </div>
    </Dialog>
  </>

})

export default VirtualDialogFavorites
