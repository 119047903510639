import Tcms from "../Tcms";

export default function TcmsImg(props) {

  // Translate the props like this
  const translatedProps = Tcms(props)

  // If an image comes from the upload....
  // This is probably a terrible hack but i dont know how to fix it quickly now
  if(translatedProps.src && translatedProps.src.startsWith('img/tinycms')){
    translatedProps.src = process.env.REACT_APP_MTG_BACKEND + '/storage/' + translatedProps.src
  }

  return (
      <img {...translatedProps} alt={translatedProps.alt}/>
  )

}
