import React from 'react';
import './PrizeModal.scss';

function PrizeModal({ show, onClose, children, description = ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras eu magna sed orci interdum interdum. Ut id nibh non  desc' }) {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <button className="modal-close-button" onClick={onClose}>X</button>
        {children}
        <p>{description}</p>
        <a className="claim-button">
          Claim Your Prize
        </a>
      </div>
    </div>
  );
}

export default PrizeModal;
