const getHousenumberOutOfStreet = (street) => {

  let res = street.match(/^(\d*[\p{L}\d '/\\\-.]+)[,\s]+(\d+)\s*([\p{L} \d\-/'"()]*)$/iu)

  return {
    street: res[1],
    no: res[2],
    add: res[3]
  }
}

const sendcloud = (carrier_slug, zipCode, callback) => {

  const ini = {
    'apiKey': process.env.REACT_APP_MTG_SENDCLOUD_API_KEY,
    'country': 'nl',
    'language': 'nl', // (available options en, fr, nl, de)
    'carriers': carrier_slug // comma separated string (e.g. "postnl,homerr,bpost,dhl")
  }

  if (!zipCode.length) {
    return
  }
  const config = {
    apiKey: ini.apiKey,
    country: ini.country,
    language: ini.language,
    carriers: ini.carriers,
    postalCode: zipCode
  }

  window.sendcloud.servicePoints.open(
    // first arg: config object
    config,
    // second arg: success callback function
    function (data) {
      if(data.carrier === 'homerr'){
        const addr = getHousenumberOutOfStreet(data.street)
        data.street = addr.street
        data.house_number = addr.no + ' ' + addr.add
      }
      callback(data)
    },
    // third arg: failure callback function
    // this is also called with ["Closed"] when the SPP window is closed.
    function (errors) {
      errors.forEach(function (error) {
        console.log('Failure callback, reason: ' + error);
      });
    }
  );
}
export {sendcloud, getHousenumberOutOfStreet}
