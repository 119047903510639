import React, {useContext} from 'react';
import TcmsImg from '../../tcms/Tags/TcmsImg';
import Tcms from '../../tcms/Tcms';

const PurchaseIndicator = () => {
    return (
        <div id="purchase-indicator" className="purchase-indicator">
            <div className='content-inside'>
            <TcmsImg src="/media/add_to_cart.gif"/>
            <span><Tcms>add_to_cart_label</Tcms></span>
            </div>
        </div>
    );
}

export default PurchaseIndicator;