import {Dialog} from "primereact/dialog";
import React, {useState, forwardRef, useImperativeHandle} from 'react'
import Tcms from "../tcms/Tcms";

import Berichten from "./widget/Berichten/Berichten";
import Borrelen from "./widget/Borrelen/Borrelen";
import EscapeRoom from "./widget/EscapeRoom/EscapeRoom";
import Kerstrecepten from "./widget/Kerstrecepten/Kerstrecepten";
import Kinderen from "./widget/Kinderen/Kinderen";
import Mibo from "./widget/Mibo/Mibo";
import PubQuiz from "./widget/PubQuiz/PubQuiz";
import Spotify from "./widget/Spotify/Spotify";
import Test from "./widget/Test/Test";
import StemmenOpMuziek from "./widget/StemmenOpMuziek/StemmenOpMuziek";
import Undefined from "./widget/Undefined/Undefined";
import Poll from "./widget/Poll/Poll";
import Top3Producten from "./widget/Top3Producten/Top3Producten";

const WidgetPopup = forwardRef((props, ref) => {

  const [visible, setVisible] = useState(false)
  const [slug, setSlug] = useState()
  const [dialogWidth, setDialogWidth] = useState('50vw')

  useImperativeHandle(ref, () => ({
    open(slug, width) {
      if(width){
        setDialogWidth(width)
      }
      setSlug(slug)
      setVisible(true)
    }
  }));

  const closePopup = () => {
    setVisible(false)
  }
  if(!slug) return null;

  const getWidget = (slug) => {

    switch (slug) {
      case 'berichten':
        return <Berichten/>
      case 'borrelen':
        return <Borrelen closePopup={closePopup}/>
      case 'escape-room':
        return <EscapeRoom/>
      case 'kerstrecepten':
        return <Kerstrecepten/>
      case 'kinderen':
        return <Kinderen/>
      case 'mibo':
        return <Mibo/>
      case 'poll':
        return <Poll/>
      case 'pub-quiz':
        return <PubQuiz/>
      case 'spotify':
        return <Spotify/>
      case 'stemmen-op-muziek':
        return <StemmenOpMuziek/>
      case 'top-3-producten':
        return <Top3Producten/>
      case 'test':
        return <Test/>
      default:
        return <Undefined/>
    }

  }

  const getDialogTitle = (slug) => {
    switch (slug) {
      case 'berichten':
        return "Berichten"
      case 'borrelen':
        return "Borrelruimte"
      case 'escape-room':
        return "Escape room"
      case 'kerstrecepten':
        return "Kerstrecepten"
      case 'kinderen':
        return "Kids Corner"
      case 'mibo':
        return "MIBO"
      case 'poll':
        return 'Stembus'
      case 'pub-quiz':
        return "Pubquiz"
      case 'spotify':
        return "Spotify"
      case 'stemmen-op-muziek':
        return "Stemmen op muziek"
      case 'top-3-producten':
        return 'Top 3 producten'
      case 'test':
        return "Test"
      default:
        return ""
    }
  }

  return <React.Fragment>
    <Dialog header={<Tcms>{getDialogTitle(slug)}</Tcms>}
            position="top"
            visible={visible}
            style={{width: dialogWidth}}
            onHide={() => setVisible(false)}>
      {getWidget(slug)}
    </Dialog>
  </React.Fragment>
})

export default WidgetPopup
