import React, {forwardRef, useImperativeHandle, useState} from "react";
import {Dialog} from "primereact/dialog";
import TcmsImg from "../../../tcms/Tags/TcmsImg";
import Tcms from "../../../tcms/Tcms";
import TcmsH1 from "../../../tcms/Tags/TcmsH1";

import TcmsWYSIWYG from "../../../tcms/Tags/TcmsWYSIWYG";



const VirtualDialogInfo = forwardRef((props, ref) => {

  const [visible, setVisible] = useState()

  useImperativeHandle(ref, () => ({
    open() {
        setVisible(true)
    }
  }));

  return <>
    <Dialog header={
      <div>
        <TcmsImg className="dialog-header-img img-left" src="/media/images/christmas_branch_l.png"></TcmsImg> 
        <Tcms>Extra Info</Tcms>
        <TcmsImg className="dialog-header-img img-right" src="/media/images/christmas_branch_r.png"></TcmsImg> 
      </div>
    }
            className="vm-dialog-info"
            maximizable={false}
            maximized={false}
            style={{width: '800px', height: '80%'}}
            visible={visible}
            modal={true}
            onHide={() => setVisible(false)}>
      <div>
        <div className="p-grid page-info">
          <div className="p-col-12">
            <TcmsImg src="/media/images/default/bag.jpg" className="product-item__img" alt="header"/>
          </div>
          <div className="p-col-12 text-center">
              <TcmsH1>Extra info</TcmsH1>
          </div>
          <div className="p-col-12 text-center">
            <div><TcmsWYSIWYG>Hier de info</TcmsWYSIWYG></div>
          </div>
        </div>
      </div>
    </Dialog>
  </>

})

export default VirtualDialogInfo
