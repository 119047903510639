import React, {useContext} from 'react'
import TcmsDiv from "../tcms/Tags/TcmsDiv";
import {TcmsContext} from "../contexts/TcmsContext";
import Tcms from "../tcms/Tcms";

export default function Tile({slug,onTileClicked}) {

  const context = useContext(TcmsContext)

  // Tile has a mobile and desktop version
  const tileImage = `/media/icons/portal/${context.mobile ? 'mobile' : 'desktop'}/widget.svg?${slug}`

  return (
    <div className="tile-wrapper">
      {/*Trick to be able to edit the background image mobile as well as desktop*/}
      <div className="tile"
           title={slug}
           onClick={() => onTileClicked ? onTileClicked(slug) : null}
           style={{
             cursor: context.editMode ? 'default' : 'pointer',
             backgroundImage: `url('${Tcms(tileImage)}')`
           }}>


        {/*Below the Tcms image editor*/}
        {context.editMode && <React.Fragment>
          <div>
            <TcmsDiv
              className="tcms-button"
              title="Mobile image"
              tcms-skip={['title']} // We dont want to edit this attr
              style={{
                right: '4rem',
                backgroundImage: `url('/media/icons/portal/mobile/widget.svg?${slug}')`,
              }}>
            </TcmsDiv>
          </div>
          <TcmsDiv
            className="tcms-button"
            title="Desktop image"
            tcms-skip={['title']} // We dont want to edit this attr
            style={{
              backgroundImage: `url('/media/icons/portal/desktop/widget.svg?${slug}')`,
            }}>
          </TcmsDiv>
        </React.Fragment>}

      </div>


    </div>

  )
}
