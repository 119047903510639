import React from 'react'
// import styles from './Filter.module.scss'
import Tcms from '../../../tcms/Tcms'
import {useState, useContext, useEffect} from 'react'
import {TcmsContext} from '../../../contexts/TcmsContext'
import {FilterContext} from '../../../contexts/FilterContext'
import {Tree} from 'primereact/tree'
import {Slider} from 'primereact/slider'
import {useHistory} from 'react-router-dom'
import Sort from '../sort/Sort'
import TcmsImg from '../../../tcms/Tags/TcmsImg';
import { Accordion, AccordionTab } from 'primereact/accordion';
import classNames from 'classnames';

const noSubcategoriesStyle = {
  color: 'gray',
  fontStyle: 'italic',
  padding: '10px',
  marginTop: '10px',
  textAlign: 'center'
};


const catMap = {
  19: 'pi-map',
  18: 'pi-star-o',
  17: 'pi-shopping-cart',
  16: 'pi-money-bill',
  13: 'pi-credit-card'
}
let timeoutRef = null

const Filter = (props) => {

  let context = useContext(TcmsContext)
  let filterContext = useContext(FilterContext)
  const [search, setSearch] = useState('')
  const [nodes, setNodes] = useState()
  const [sliderRange, setSliderRange] = useState({min: 0, max: 0})
  const [sliderValue, setSliderValue] = useState(0)
  const [selectedKeys, setSelectedKeys] = useState()
  const [expandedKeys, setExpandedKeys] = useState({})
  const history = useHistory()
  const {categories} = context.products
  const {products} = context.products
  const count = filterContext.filteredProductCount
  // mount
  useEffect(() => {

    // Calculate the sum of all 'Geschenken' category
    const sum = categories.reduce(function (a, b) {
      return a + (b.parent_id === 17 ? b.count : 0)
    }, 0)

    // Get hoofdcats (where parent_id = 4)
    const hoofdCats = categories.filter((el) => el.parent_id === 4)

    // Expand all hoofdcats (Tree config, i.e. {17:true,13:true})
    setExpandedKeys(Object.fromEntries(hoofdCats.map(e => [e.id, true])))


    const allTree = [{
      "key": 4,
      "label": "Alle",
      "icon": "pi pi-fw pi-list",
      "data": {
          "itemCount": products.length
      },
    },];

    // Make the tree object for the <Tree />
    // Its a simple tree. Only 1 deep
    const tree = hoofdCats.map(hc => {
      const children = categories.filter(el => el.parent_id === hc.id)

      return {
        key: hc.id,
        label: hc.name,
        icon: `pi pi-fw ${catMap[hc.id]}`,
        data: {
          itemCount: (hc.id === 17 ? sum : hc.count)
        },
        children: children.map(ch => {
          return {
            key: ch.id,
            label: ch.name,
            data: {
              parent_id: hc.id,
              itemCount: ch.count
            }  // easy later on
          }
        })
      }
    });

    // And set the tree
    if (hoofdCats.length > 1) {
      Array.prototype.push.apply(allTree,tree);
      setNodes(allTree);
    } else {
      setNodes(tree);
    }
  }, [context.products, categories])


  useEffect(() => {

    const hc = categories.find(el => el.id === filterContext.activeHoofdCatId)
    const trans = context.stringTranslate

    if (filterContext.activeSubCats.length) {

      const sc = categories.find(el => el.id === filterContext.activeSubCats[0])
      // Subcat
      setSelectedKeys(filterContext.activeSubCats[0])
      // if(!context.pageIsVM) {
      //   history.push(`/${context.webshopSlug}/webshop/${slug(trans(hc.name))}/${slug(trans(sc.name))}`)
      // }
    } else if (filterContext.activeHoofdCatId) {
      // Hoofdcat

      if (filterContext.defaultHoofdCatId !== 4) {
        setSelectedKeys(filterContext.defaultHoofdCatId)
        filterContext.setActiveHoofdCatId(filterContext.defaultHoofdCatId);
        filterContext.setDefaultHoofdCatId(4);
      } else {
        setSelectedKeys(filterContext.activeHoofdCatId)
      }


      // if(!context.pageIsVM){
      //   // Detects if the url we should be on is not the same we'r on (but not in the VM)
      //   const path = `/${context.webshopSlug}/webshop/${slug(trans(hc.name))}`
      //   // Hack because somewhere something aint good
      //   if (history.location.pathname !== path) {
      //     history.push(path)
      //   }
      // }
    }

  }, [
    filterContext.activeHoofdCatId,
    filterContext.activeSubCats,
    history,
    categories,
    context.webshopSlug,
    context.pageIsVM,
    context.stringTranslate
  ])

  /**
   * Get Slider from price range
   * For cosmetic purposes * 10... so the slider slides and not has just some steps
   */
  useEffect(() => {
    setSliderRange({min: filterContext.priceRange.min * 10, max: filterContext.priceRange.max * 10})
    setSliderValue(filterContext.priceRange.max * 10)
  }, [filterContext.priceRange])

  const isSubCategoryActive = (child) => {
    if (filterContext.activeSubCats[0] === child.key) {
      return true;
    }
  }

  const handleCategoryClick = (node, isChild = false) => {
    if (node.key != 4) {
      if (node.children) {
        filterContext.setDefaultHoofdCatId(node.key);
        filterContext.setActiveHoofdCatId(node.key);
        filterContext.setActiveSubCats([]);
      } else {
        filterContext.setDefaultHoofdCatId(node.key);
        filterContext.setActiveHoofdCatId(node.data.parent_id);
        filterContext.setActiveSubCats([node.key]);
      }
    } else {
      filterContext.setDefaultHoofdCatId(node.key);
      filterContext.setActiveHoofdCatId(node.key);
      filterContext.setActiveSubCats([]);
    }
  };

  const checkElementVisibility = (value) => {
    return !!value;
  }


  return (
    <>
      {/* Categories and subcategories accordion */}
      {!context.pageIsVM && (
        <div>
          <div className='visible-xs'>
            <div className='header-title'>Filter</div>
            <div className='title'>Sorteren op</div>
            <Sort />
            <div className='title'>Filters</div>
          </div>
          {nodes && nodes.length > 1 ?
            <>
              {context.editMode &&
                <div className="tcms-edit-wrapper">
                  <div className="item-count-visible">
                    <div className="label">
                      Item count: 
                    </div>
                    <div className="value">
                      <Tcms>item-count-visible</Tcms>
                    </div>
                  </div>
                </div>
              }
              <div className="category-header category-main" onClick={() => handleCategoryClick(nodes[0])}>
                {checkElementVisibility(Tcms('item-count-visible')) &&
                  <>
                    <Tcms>{nodes[0].label}</Tcms>
                    <span className="item-count"> {nodes[0].data && nodes[0].data.itemCount} </span>
                  </>
                }
              </div>
            
              <div className='filter-accordion-container'>
                <Accordion activeIndex={0}>
                  {nodes && nodes.slice(1).map(node => (
                    <AccordionTab headerClassName={node.children.length == 0 ? 'no-subs' : 'has-subs'} key={node.key} header={
                      <div className="category-header" onClick={() => handleCategoryClick(node)}>
                        <TcmsImg src={'filter_img_' + node.key} alt={node.label} />
                        <h4><Tcms>{node.label}</Tcms></h4>
                        <span className="item-count">({node.data.itemCount})</span>
                      </div>
                    }>
                      {node.children && node.children.length > 0 &&
                        node.children.map(child => (
                          <div key={child.key} className={classNames('subcategory', {'active': isSubCategoryActive(child)})} onClick={() => handleCategoryClick(child)}>
                            <TcmsImg src={'filter_img_' + child.key} alt={child.label} />
                            <h5><Tcms>{child.label}</Tcms></h5>
                            <span className="item-count">({child.data.itemCount})</span>
                          </div>
                        ))
                      }
                    </AccordionTab>
                  ))}
                </Accordion>
              </div>
            </>
            :
            <>
              <div className='filter-accordion-container no-main-cats'>
                <Accordion activeIndex={0}>
                  {nodes && nodes.map(node => (
                    <AccordionTab headerClassName={node.children.length == 0 ? 'no-subs' : 'has-subs'} key={node.key} header={
                      <div className="category-header" onClick={() => handleCategoryClick(node)}>
                        <TcmsImg src={'filter_img_' + node.key} alt={node.label} />
                        <h4><Tcms>{node.label}</Tcms></h4>
                        <span className="item-count">({node.data.itemCount})</span>
                      </div>
                    }>
                      {node.children && node.children.length > 0 &&
                        node.children.map(child => (
                          <div key={child.key} className={classNames('subcategory', {'active': isSubCategoryActive(child)})} onClick={() => handleCategoryClick(child)}>
                            <TcmsImg src={'filter_img_' + child.key} alt={child.label} />
                            <h5><Tcms>{child.label}</Tcms></h5>
                            <span className="item-count">({child.data.itemCount})</span>
                          </div>
                        ))
                      }
                    </AccordionTab>
                  ))}
                </Accordion>
              </div>
            </>
          }
        </div>
      )}

      {/* Slider */}
      <div className='additional-filters-container'>
        {context.meta.no_price === 0 && context.meta.wso_id != 953 &&
          <div className='price-slider'>
            
            <h3><Tcms>Prijs</Tcms> {Math.round(sliderValue / 10)}</h3>
            <div className='content'>
            <Slider min={sliderRange.min}
                    max={sliderRange.max}
                    onSlideEnd={(e) => {
                      filterContext.setMaxPrice(Math.round(e.value / 10))
                    }}
                    value={sliderValue}
                    onChange={(e) => {
                      setSliderValue(e.value)
                    }}/>
            </div>
          </div>
        }
        <div className='visible-xs'>
          <button className="p-button p-togglebutton p-component p-button-filter footer-button" onClick={props.toggleSideBarFilter}>
            <Tcms>Toon  <span>{count}</span> producten</Tcms>
          </button>
        </div>
      </div>

    </>
  )


}

export default Filter
