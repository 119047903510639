import Tcms from "../Tcms";

export default function TcmsWYSIWYG(props) {

  const ps = {...props,'data-wysiwyg':1}

  return (
    <div {...Tcms(ps)}/>

  )

}
