import TcmsImg from "../../../tcms/Tags/TcmsImg";
import Tcms from "../../../tcms/Tcms";
import TcmsWYSIWYG from "../../../tcms/Tags/TcmsWYSIWYG";

const Kinderen = () => {
  return <>
    <div className="kids">
      <div className="p-grid box">
        <div className="p-col-6">
            <TcmsImg src="/media/images/default/kids.jpg" className="product-item__img" alt="kids"/>
        </div>
        <div className="p-col-6">
            <TcmsWYSIWYG>kinderen_intro</TcmsWYSIWYG>
            <p><TcmsImg src="/media/images/default/available-app-store.png" className="app" alt="app"/> <TcmsImg src="/media/images/default/available-google-play.png" className="app" alt="app2"/></p>
        </div>
      </div>
      <div className="p-grid mr">
        <div className="p-col-12">
            <TcmsWYSIWYG>kinderen_downloads</TcmsWYSIWYG>
        </div>
        <div className="p-col-4">
          <div className="art">
            <a href="/media/images/default/kleurplaat-7.pdf" target="_blank">
              <TcmsImg src="/media/images/default/kleurplaat-7.jpg" className="product-item__img" alt="kleurplaat 1"/>
              <div className="button"><Tcms>Download</Tcms></div> 
            </a>
          </div>
        </div>
        <div className="p-col-4">
          <div className="art">
            <a href="/media/images/default/kleurplaat-8.pdf" target="_blank">
              <TcmsImg src="/media/images/default/kleurplaat-8.jpg" className="product-item__img" alt="kleurplaat 2"/>
              <div className="button"><Tcms>Download</Tcms></div>
            </a>
          </div>
        </div>
        <div className="p-col-4">
          <div className="art">
            <a href="/media/images/default/kleurplaat-3.pdf" target="_blank">
              <TcmsImg src="/media/images/default/kleurplaat-3.jpg" className="product-item__img" alt="kleurplaat 3"/>
              <div className="button"><Tcms>Download</Tcms></div> 
            </a>
          </div>
        </div>
        <div className="p-col-4">
          <div className="art">
            <a href="/media/images/default/kleurplaat-4-new.pdf" target="_blank">
              <TcmsImg src="/media/images/default/kleurplaat-4.jpg" className="product-item__img" alt="kleurplaat 4"/>
              <div className="button"><Tcms>Download</Tcms></div>
            </a>
          </div>
        </div>
        <div className="p-col-4">
          <div className="art">
            <a href="/media/images/default/kleurplaat-9.pdf" target="_blank">
              <TcmsImg src="/media/images/default/kleurplaat-9.jpg" className="product-item__img" alt="kleurplaat 5"/>
              <div className="button"><Tcms>Download</Tcms></div> 
            </a> 
          </div>
        </div>
        <div className="p-col-4">
          <div className="art">
            <a href="/media/images/default/kleurplaat-6.pdf" download target="_blank">
              <TcmsImg src="/media/images/default/kleurplaat-6.jpg" className="product-item__img" alt="kleurplaat 6"/>
              <div className="button"><Tcms>Download</Tcms></div>
            </a>
          </div>
        </div>
        <div className="p-col-12 p-text-center my-30">
          <TcmsWYSIWYG>kinderen_meer_kleurplaten</TcmsWYSIWYG>
        </div>
      </div>
      <div className="p-grid box">
        <div className="p-col-8">
            <TcmsWYSIWYG>kinderen_verhaal_1</TcmsWYSIWYG>
        </div>
        <div className="p-col-4">
            <TcmsImg src="/media/images/default/kids2.png" className="product-item__img" alt="kids"/>
        </div>
      </div>
      <div className="p-grid box my-30">
        <div className="p-col-4">
            <TcmsImg src="/media/images/default/kids3.png" className="product-item__img" alt="kids"/>
        </div>
        <div className="p-col-8">
            <TcmsWYSIWYG>kinderen_verhaal_2</TcmsWYSIWYG>
        </div>
      </div>
    </div>
  </>

}

export default Kinderen
