import React, {useContext} from 'react'
import LazyLoad from 'react-lazyload';
import {TcmsContext} from "../../../contexts/TcmsContext";
import { Card } from 'primereact/card';
import { Img } from 'react-image';

const BE = process.env.REACT_APP_MTG_BACKEND

function Ambiance(props) {

  let {
    display_name,
    ambiance_images,
    originalIndex,
    productClicked,
  } = props

  const cardClicked = () => {
    productClicked(originalIndex)
  }
  
  let context = useContext(TcmsContext)
  const errSrc = '/media/images/placeholders/250x250.png'
  const imgPath = `${ambiance_images[0].id}_${ambiance_images[0].product_id}.jpg`
  const xlSrc = `${BE}/storage/img/odoo-producten/1170x1170/${imgPath}`
  const lgSrc = `${BE}/storage/img/odoo-producten/1170x555/${imgPath}`
  const mdSrc = `${BE}/storage/img/odoo-producten/835x835/${imgPath}`
  const smSrc = `${BE}/storage/img/odoo-producten/555x555/${imgPath}`
 
  return (
    <>
      <div className='product-col p-col-12 p-col-ambiance'>
        <Card className="product-item product-ambiance-item">
          <LazyLoad height={250} scrollContainer={(context.pageIsVM ? '.p-dialog-content' : undefined)}>
            <div className="ambiance-item__img-holder">
              <Img onClick={() => {cardClicked()}} className="ambiance-item__img"
                  alt={display_name}
                  src={[xlSrc,lgSrc, mdSrc, smSrc, errSrc]}
              />
            </div>
          </LazyLoad>
        </Card>

      </div>
    
    </>
  )
}

export default Ambiance




