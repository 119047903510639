import React, { useContext, useRef } from 'react'
import { Card } from 'primereact/card'
import LazyLoad from 'react-lazyload';
import classNames from 'classnames'
import axios from 'axios'
import { TcmsContext } from "../../../contexts/TcmsContext";
import getRibbons from "../../../services/getRibbons";
import TcmsImg from '../../../tcms/Tags/TcmsImg';
import SidebarCart from "../menu/SidebarCart";
import Ambiance from './Ambiance';
import Tcms from '../../../tcms/Tcms'


const BE = process.env.REACT_APP_MTG_BACKEND

function Product(props) {

  let {
    optionalRanking,
    id,
    originalIndex,
    display_name,
    price_in_eco,
    productClicked,
    image_name,
    ribbon_ids,
    ambiance_images,
    featured,
    col_xl = 4,
  } = props

  let context = useContext(TcmsContext)
  const ribbonIcons = context.ribbonIcons;
  const refSidebarCart = useRef()
  // TcmsImg paths
  let src = `${BE}/storage/img/odoo-producten/555x555/${image_name}.jpg`

  let errSrc = '/media/images/placeholders/250x250.png'

  const cardClicked = () => {
    productClicked(originalIndex)
  }
  const addToCartClicked = () => {
    if (context.getBalance() === 0) {
      return null
    }
    axios.get(BE + '/api/webshop/add-to-cart', {
      params: { id, count: 1 }
    }).then(res => {

      // Have to update the jwt_token and the cart
      context.setCart(res.data.cart)
      context.renewJwt(res.data.jwt_token)

      const purchaseIndicator = document.getElementById('purchase-indicator');
      purchaseIndicator.classList.add('show');

      setTimeout(() => {
        purchaseIndicator.classList.remove('show');
        refSidebarCart.current.open()
      }, 1200);
    })
  }

  const heartClicked = (event) => {
    // Add or remove
    const { uid } = context.jwt.usr

    axios.get(`${BE}/api/webshop/favorites`, { params: { uid, id } }).then(res => {
      context.setFavorites(res.data ? res.data.split(',').map(el => parseInt(el)) : '')
    })

    const heartElement = event.currentTarget.querySelector('.heart-icon');
    heartElement.classList.add('animate');

    setTimeout(() => {
      heartElement.classList.remove('animate');
    }, 1000);
  }

  return (
    <>
      <SidebarCart ref={refSidebarCart} />
      {/*When we're a VM we have less itmes on a row... dunno if its ok*/}
      <div id={`product-${id}`} className={'product-col p-col-12 p-lg-6 p-xl-'+col_xl}>
        <Card className="product-item">
          {/* Ribbon */}
          {ribbon_ids && ribbon_ids.length > 0 && (
            <div className="product-item__ribbons">
              {getRibbons(ribbon_ids, context.meta.ribbons).map(ribbon => (
                <div className="product-item__ribbon" key={ribbon}>
                  {ribbonIcons[ribbon] ? (
                    <TcmsImg alt={ribbon} title={ribbon} className="ribbon_icon" src={ribbonIcons[ribbon]} />
                  ) : (
                    <Tcms>{ribbon}</Tcms>
                  )}
                </div>
              ))}
            </div>
          )}

          <div className="product-item__favorite" onClick={heartClicked}>
            <svg
              className={classNames('heart-icon', {
                active: context.favorites.includes(id),
              })}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
            </svg>
          </div>
          
          <div className="product-item__content">
            <LazyLoad height={250} scrollContainer={context.pageIsVM ? '.p-dialog-content' : undefined}>
              <div className="product-item__img-holder" onClick={cardClicked}>
                <img className="product-item__img" alt={display_name} src={src} onError={e => (e.target.src = errSrc)} />
              </div>
            </LazyLoad>

            <div className="product-item__info" onClick={cardClicked}>
              <div className="product-item__name"><strong>{display_name}</strong></div>
            </div>
            <div className="product-item__bottom">
              <div className="product-item__price">
                {context.meta.no_price === 0 && (
                  <div className="price-container">
                    <span className="eco">
                      <TcmsImg
                        style={{
                          filter: 'invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%)',
                        }}
                        alt=""
                        src="/media/icons/coins-white.svg"
                      />
                    </span>
                    <span>{price_in_eco} &nbsp;</span>
                  </div>
                )}
              </div>
              <button className="product-item__btn" onClick={addToCartClicked}>
                <span>Voeg Toe <i className="pi pi-arrow-right"></i></span>
                <TcmsImg className="basket-icon" src="/media/icons/icon-add-new.svg" alt="Toevoegen aan winkelmand" />
              </button>
            </div>
          </div>
        </Card>

      </div>
      {ambiance_images.length > 0 && featured ?
        <Ambiance
          display_name={display_name}
          ambiance_images={ambiance_images}
          originalIndex={originalIndex}
          productClicked={productClicked}
        />
        :
        false
      }
    </>
  )
}

export default Product




