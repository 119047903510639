import {forwardRef, useContext, useImperativeHandle, useState} from "react";
import {Dialog} from "primereact/dialog";
import {TcmsContext} from "../../../contexts/TcmsContext";
import {FilterContext} from "../../../contexts/FilterContext";
import Pdp from "../../webshop/pdp/Pdp";
import WebshopProducts from "../../webshop/WebshopProducts";
import Filter from "../../webshop/filter/Filter";
import Tcms from "../../../tcms/Tcms";
import TcmsImg from "../../../tcms/Tags/TcmsImg";
// import RibbonFilter from "../../webshop/filter/RibbonFilter";
// import Sort from "../../webshop/sort/Sort";

const defaultCats = {
  hoofdcat: null,
  subcat: null
}

const VirtualDialogWebshopCategory = forwardRef((props, ref) => {
  const context = useContext(TcmsContext)
  const filterContext = useContext(FilterContext)

  const [visible, setVisible] = useState()
  const [cats, setCats] = useState(defaultCats)
  const [productId, setProductId] = useState()

  useImperativeHandle(ref, () => ({
    open(hoofdcat_id, subcat_id) {
      const {categories} = context.products

      let hoofdcat = categories.find(el => el.id === hoofdcat_id)
      const subcat = categories.find(el => el.id === subcat_id)

      /**
       * When hoofdcat_id === 4
       */
      filterContext.setActiveHoofdCatId(hoofdcat_id !== 4 ? hoofdcat_id : subcat_id)
      filterContext.setActiveSubCats(hoofdcat_id !== 4 ? [subcat_id] : [])

      if (!subcat) {
        alert('Subcat met id ' + subcat_id + ' niet gevonden in deze webshop!')
      } else {
        setCats({hoofdcat, subcat})
        setVisible(true)
      }
    }
  }));

  const replaceWithPdp = (product_id) => {
    setProductId(product_id)
  }
  const backClicked = () => {
    setProductId(null)
  }

  if (!visible) return null;

  const dialogHeader = cats.hoofdcat?.name + (cats.subcat?.name ? (' > ' + cats.subcat?.name) : '')

  return <>
    <Dialog header={
      <div>
        <TcmsImg className="dialog-header-img img-left" src="/media/images/christmas_branch_l.png"></TcmsImg> 
        <Tcms>{dialogHeader}</Tcms>
        <TcmsImg className="dialog-header-img img-right" src="/media/images/christmas_branch_r.png"></TcmsImg> 
      </div>
    }
            className="vm-dialog-category"
            maximizable={false}
            maximized={false}
            style={{width: '900px', height: '80%'}}
            visible={visible}
            modal={true}
            onHide={() => {
              setVisible(false)
              setProductId(false)
              filterContext.clear()

            }}>
      <div>
        {productId &&
        <Pdp product_id={productId}
             replaceWithPdp={replaceWithPdp}  // different variant, same product
             backClicked={backClicked}/>      // back to WebshopProduct
        }
        <div className="p-grid" style={{display: (productId ? 'none' : 'flex')}}>
          <div className="p-col-12 p-lg-4 p-xl-3">
            <div className="filter-wrapper">
              <Filter />
            </div>
            {/*<RibbonFilter />*/}
            {/*<Sort/>*/}
          </div>
          <div className="p-col-12 p-lg-8 p-xl-9">
            <WebshopProducts replaceWithPdp={replaceWithPdp}/>
          </div>

        </div>
      </div>
    </Dialog>
  </>

})

export default VirtualDialogWebshopCategory
