import React, {useEffect, useState} from "react"
import axios from "axios";
import TcmsH1 from "../../../tcms/Tags/TcmsH1";
import TcmsH3 from "../../../tcms/Tags/TcmsH3";
import { Splitter, SplitterPanel } from 'primereact/splitter';
import {getUser} from "../../components/User";

const BE = process.env.REACT_APP_MTG_BACKEND
const PubQuiz = () => {
    const [user, setUser] = useState()
    const [pubquizType, setPubquizType] = useState("")
    const [pubquiz, setPubquiz] = useState()
    const [pubquizzes, setPubquizzes] = useState()

    useEffect(() => {
        getUser().then(res => {
            setUser(res.data)
        });

        axios.get(BE + '/api/webshop/widgets/pubquiz').then(res => {
            let type = res.data.type

            switch (type) {
                case 'no_pubquiz':
                    break;
                case 'multiple':
                    setPubquizzes(res.data.data)
                    break;
                default:
                    setPubquiz(res.data.data)
                    break;
            }

            setPubquizType(res.data.type)
        })
    }, [pubquizType])

    switch (pubquizType) {
        case 'no_pubquiz':
            return <>
                <div className="p-grid box">
                    <div className="p-col-12 text-centre">
                        <div className="art">
                            <TcmsH1>Er is momenteel geen actieve pubquiz.</TcmsH1>
                        </div>
                    </div>
                </div>
            </>
        case 'coming_pubquiz':
            return <>
                <div className="p-grid box">
                    <div className="p-col-12 text-centre">
                        <div className="art">
                            <TcmsH1>De pubquiz begint binnenkort, nog even geduld.</TcmsH1>
                        </div>
                    </div>
                </div>
            </>
        case 'youtube_pubquiz':
            return <>
                <Splitter gutterSize={20}>
                    <SplitterPanel size={70}>
                        <iframe
    src={"//www.youtube.com/embed/" + pubquiz.youtube_id}
    frameBorder="0"
    allow="autoplay; fullscreen"
    allowFullScreen="allowfullscreen"
    title="Kahoot"
    style={{
        width: '100%',
        height: 'calc(90vh - 170px)'
    }}
    />
                    </SplitterPanel>
                    <SplitterPanel size={30}>
                        <iframe
    src={"//morethangifts.minnit.org/" + pubquiz.topic + "?embed&nickname=" + user?.firstname }
    frameBorder="0"
    allow="autoplay; fullscreen"
    allowFullScreen="allowfullscreen"
    title="Chat"
    style={{
        width: '100%',
        height: 'calc(90vh - 170px)'
    }}
    />
                    </SplitterPanel>
                </Splitter>
            </>
        case 'kahoot_pubquiz':
            return <>
                <div className="p-grid">
                    <div className="p-col-12 text-centre">
                        <div className="art">
                            <TcmsH3>De pubquiz wordt geladen.</TcmsH3>
                            <iframe
    src={"https://kahoot.it/challenge/" + pubquiz.kahoot_id}
    frameBorder="0"
    allow="autoplay; fullscreen"
    allowFullScreen="allowfullscreen"
    title="Kahoot"
    style={{
        position: 'absolute',
        top: '100px',
        left: 0,
        width: '100%',
        height: 'calc(80vh - 100px)'
    }}
    />
                        </div>
                    </div>
                </div>
            </>
        case 'multiple':
            return <>
                <div className="p-grid box">
                    <div className="p-col-12 text-centre">
                        <div className="art">
                            <TcmsH1>Er zijn meerdere pubquizzen actief.</TcmsH1>
                            {pubquizzes.map(el => (
                                <div><a href={el.id} onClick={() => setPubquiz(el) && setPubquizType('kahoot_pubquiz') }>{el.topic}</a></div>
                            ))}
                        </div>
                    </div>
                </div>
            </>
        // @todo : For now not developed
        case 'zoom_and_slido_pubquiz':
            return <>
                <div className="p-grid box">
                    <div className="p-col-12 text-centre">
                        <div className="art">
                            <TcmsH1>In ontwikkeling.</TcmsH1>
                        </div>
                    </div>
                </div>
            </>
        // @todo : For now not developed
        case 'zoom_pubquiz':
            return <>
                <div className="p-grid box">
                    <div className="p-col-12 text-centre">
                        <div className="art">
                            <TcmsH1>In ontwikkeling.</TcmsH1>
                        </div>
                    </div>
                </div>
            </>
        // @todo : For now not developed
        case 'vimeo_and_slido_pubquiz':
            return <>
                <div className="p-grid box">
                    <div className="p-col-12 text-centre">
                        <div className="art">
                            <TcmsH1>In ontwikkeling.</TcmsH1>
                        </div>
                    </div>
                </div>
            </>
        case 'closed_pubquiz':
            return <>
                <div className="p-grid box">
                    <div className="p-col-12 text-centre">
                        <div className="art">
                            <TcmsH1>De pubquiz wordt geladen.</TcmsH1>
                            <iframe
    src={"https://kahoot.it/challenge/" + pubquiz.kahoot_id}
    frameBorder="0"
    allow="autoplay; fullscreen"
    allowFullScreen="allowfullscreen"
    title="Kahoot"
    style={{
        position: 'absolute',
        top: '100px',
        left: 0,
        width: '100%',
        height: 'calc(80vh - 100px)'
    }}
    />
                        </div>
                    </div>
                </div>
            </>
        default:
            return <>
                Onbekende fout.
            </>
    }
    /*
  return <>
  <div className="p-grid box">
   <div className="p-col-12 text-centre">
          <div className="art">
          <h3>Klik <a href="https://kahoot.it/challenge/006452207" rel="noreferrer" target="_blank">hier</a> om de quiz te activeren</h3>

          </div>
        </div>
    </div>
  </>*/
}

export default PubQuiz
