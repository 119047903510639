import React, {Suspense, Component} from 'react'
import {TcmsContext} from '../contexts/TcmsContext'
import PageLoading from "../components/webshop/PageLoading";



// Need all them skins definded
// const SkinDefault = React.lazy(() => import('./skins/default/Login'));
import SkinDefault from './skins/default/ResetPassword'

// Themes (We have no default theme, that will be the default skin)
const Theme2 = React.lazy(() => import('./themes/2-women/Login')); // eslint-disable-line
const Theme3 = React.lazy(() => import('./themes/3-men/Login')); // eslint-disable-line

// Skins
// const Skin86 = React.lazy(() => import('./skins/86-gemeente-ede/Login')); // eslint-disable-line

/**********************************************************************************
 *              The login page does NOT extend the Auth Component!                *
 **********************************************************************************/
const API = process.env.REACT_APP_MTG_BACKEND + '/api'

class ResetPassword extends Component {

  static contextType = TcmsContext
  
  state = {
    locale: 'nl_nl',
    token: '',
  }

  constructor(props) {
    super(props);
  }

  /*********************************************************************************************************************
   *                        The skin stuff. Do not change. Copy this part to create a new Skin page                    *
   *                        Yes, it's duplicated code. But I think Webpack needs it to load dynamic                    *
   *                        !! Only thing you might do is set pageType (public/private) !!                             *
   ********************************************************************************************************************/

  pageType = 'public'

  componentDidMount() {
    this.context.setLoadedComponent(this.constructor.name);
    if (this.mtgComponentDidMount) this.mtgComponentDidMount()
  }

  render() {
    // if (!this.context.translate) return null;

    return (
      <Suspense fallback={<PageLoading/>}>
        <>
          {this.getSkin()}
        </>
      </Suspense>
    )
  }

  getSkin(html) {
    // Check if skin exists or theme exists
    const skinExists = eval('typeof Skin' + this.context.webshopId) === 'undefined' ? false : true // eslint-disable-line no-eval
    const themeExists = eval('typeof Theme' + this.context.themeId) === 'undefined' ? false : true // eslint-disable-line no-eval

    if (skinExists) {
      html = React.createElement(eval('Skin' + this.context.webshopId), {rootComponent: this}) // eslint-disable-line no-eval
    } else if (themeExists) {
      html = React.createElement(eval('Theme' + this.context.themeId), {rootComponent: this}) // eslint-disable-line no-eval
    } else {
      html = <SkinDefault rootComponent={this}/>
    }
    return html
  }

}

export default ResetPassword
